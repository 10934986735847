import { gql } from "@apollo/client";
import { EventFields } from "./types";

export const GET_EVENT = gql`
  query GetEvent($eventId: ID!, $organizationId: ID!) {
    event(eventId: $eventId, organizationId: $organizationId) {
      success
      message
      data {
        ${EventFields}
      }
    }
  }
`;
