import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { MongoSort, usePagination } from "src/hooks/usePagination";
import { GET_BILLS } from "../Billing/queries";
import {
  Activity,
  DefaultResponse,
  GetActivitiesInput,
  GetActivitiesResponse,
  GetActivityResponse,
  MutationCreateActivityArgs,
  MutationCreateGroupActivitiesArgs,
  MutationDeleteActivityArgs,
  MutationUpdateActivityArgs,
  CreateActivityResponse,
  QueryActivitiesArgs,
  GetActivityInput,
  QueryActivityArgs,
  MutationUpdateActivityTaskStatusArgs,
  GetUserCurrentActivityInput,
  MutationAddMemberToActivityArgs,
  MutationRemoveMemberFromActivityArgs,
} from "../schemaTypes";
import {
  ACTIVITY_PING,
  ADD_ACTIVITY_MEMBER,
  CREATE_ACTIVITY,
  CREATE_GROUP_ACTIVITY,
  DELETE_ACTIVITY,
  REMOVE_ACTIVITY_MEMBER,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_TASK_STATUS,
} from "./mutations";
import {
  GET_ACTIVITIES,
  GET_ACTIVITY,
  GET_USER_CURRENT_ACTIVITY,
} from "./queries";

export const useQueryActivity = (input: GetActivityInput) =>
  useQuery<{ activity: GetActivityResponse }, QueryActivityArgs>(GET_ACTIVITY, {
    variables: { input },
  });

export const useLazyQueryActivity = (input: GetActivityInput) =>
  useLazyQuery<{ activity: GetActivityResponse }, QueryActivityArgs>(
    GET_ACTIVITY,
    {
      variables: { input },
    },
  );

export const useQueryUserCurrentActivity = (
  input: GetUserCurrentActivityInput,
) =>
  useQuery<{ userCurrentActivity: GetActivityResponse }>(
    GET_USER_CURRENT_ACTIVITY,
    { variables: { input } },
  );

export const usePaginatedQueryActivities = (
  input: GetActivitiesInput,
  options?: {
    defaultSort?: MongoSort;
  },
) =>
  usePagination<
    QueryActivitiesArgs,
    { response: GetActivitiesResponse },
    Activity
  >({ document: GET_ACTIVITIES, variables: { input }, options });

export const useMutationUpdateActivity = (onboardingToken?: string) =>
  useMutation<
    { updateActivity: GetActivityResponse },
    MutationUpdateActivityArgs
  >(UPDATE_ACTIVITY, {
    ...(onboardingToken
      ? {
          context: {
            headers: {
              "Pear-Onboarding-Survey": onboardingToken,
            },
          },
        }
      : {
          refetchQueries: [
            GET_ACTIVITIES,
            GET_USER_CURRENT_ACTIVITY,
            GET_BILLS,
          ],
        }),
  });

export const useMutationUpdateActivityTaskStatus = (onboardingToken?: string) =>
  useMutation<
    { updateActivityTaskStatus: GetActivityResponse },
    MutationUpdateActivityTaskStatusArgs
  >(UPDATE_ACTIVITY_TASK_STATUS, {
    ...(onboardingToken
      ? {
          context: {
            headers: {
              "Pear-Onboarding-Survey": onboardingToken,
            },
          },
        }
      : {
          refetchQueries: [
            GET_ACTIVITIES,
            GET_ACTIVITY,
            GET_USER_CURRENT_ACTIVITY,
          ],
        }),
  });

export const useMutationCreateActivity = () =>
  useMutation<
    { createActivity: CreateActivityResponse },
    MutationCreateActivityArgs
  >(CREATE_ACTIVITY, {
    refetchQueries: [GET_ACTIVITIES, GET_USER_CURRENT_ACTIVITY],
  });

export const useMutationActivityPing = () =>
  useMutation<{ activityPing: CreateActivityResponse }>(ACTIVITY_PING);

export const useMutationCreateOnboardingActivity = (onboardingToken?: string) =>
  useMutation<
    { createActivity: CreateActivityResponse },
    MutationCreateActivityArgs
  >(CREATE_ACTIVITY, {
    ...(onboardingToken
      ? {
          context: {
            headers: {
              "Pear-Onboarding-Survey": onboardingToken,
            },
          },
        }
      : {}),
  });

export const useMutationDeleteActivity = () =>
  useMutation<{ deleteActivity: DefaultResponse }, MutationDeleteActivityArgs>(
    DELETE_ACTIVITY,
    { refetchQueries: [GET_ACTIVITIES, GET_USER_CURRENT_ACTIVITY] },
  );

export const useMutationCreateGroupActivity = () =>
  useMutation<
    { createGroupActivities: DefaultResponse },
    MutationCreateGroupActivitiesArgs
  >(CREATE_GROUP_ACTIVITY, {
    refetchQueries: [GET_ACTIVITIES, GET_USER_CURRENT_ACTIVITY],
  });

export const useMutationAddActivityMember = (activityInput: GetActivityInput) =>
  useMutation<
    { addMemberToActivity: GetActivityResponse },
    MutationAddMemberToActivityArgs
  >(ADD_ACTIVITY_MEMBER, {
    update: (cache, { data }) => {
      if (!data?.addMemberToActivity.success || !data.addMemberToActivity.data)
        return;

      const newActivity = data.addMemberToActivity.data;

      const activityQuery = cache.readQuery<
        { activity: GetActivityResponse },
        QueryActivityArgs
      >({
        query: GET_ACTIVITY,
        variables: { input: activityInput },
      });

      if (
        !activityQuery?.activity ||
        !activityQuery.activity.success ||
        !activityQuery.activity.data
      )
        return;

      cache.writeQuery<
        { activity: GetActivityResponse },
        { organizationId: string; activityId: string }
      >({
        query: GET_ACTIVITY,
        variables: activityInput,
        data: {
          activity: {
            ...activityQuery.activity,
            data: newActivity,
          },
        },
      });
    },
  });

export const useMutationRemoveActivityMember = (
  activityInput: GetActivityInput,
) =>
  useMutation<
    { removeMemberFromActivity: GetActivityResponse },
    MutationRemoveMemberFromActivityArgs
  >(REMOVE_ACTIVITY_MEMBER, {
    update: (cache, { data }) => {
      if (
        !data?.removeMemberFromActivity.success ||
        !data.removeMemberFromActivity.data
      )
        return;

      const newActivity = data.removeMemberFromActivity.data;

      const activityQuery = cache.readQuery<
        { activity: GetActivityResponse },
        QueryActivityArgs
      >({
        query: GET_ACTIVITY,
        variables: { input: activityInput },
      });

      if (
        !activityQuery?.activity ||
        !activityQuery.activity.success ||
        !activityQuery.activity.data
      )
        return;

      cache.writeQuery<
        { activity: GetActivityResponse },
        { organizationId: string; activityId: string }
      >({
        query: GET_ACTIVITY,
        variables: activityInput,
        data: {
          activity: {
            ...activityQuery.activity,
            data: newActivity,
          },
        },
      });
    },
  });
