import { gql } from "@apollo/client";
import { PSFileFields } from "./types";

export const UPLOAD_FILE = gql`
  mutation UploadPSFile($input: UploadPsFileInput!) {
    uploadPSFile(input: $input) {
      success
      message
      data {
        ${PSFileFields}
      }
    }
  }
`;

export const UPDATE_FILE = gql`
  mutation UpdatePSFile($input: UpdateFileInput!) {
    updatePSFile(input: $input) {
      success
      message
      data {
        ${PSFileFields}
      }
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeletePSFile($id: ID!,$organizationId: ID!) {
    deletePSFile(id: $id, organizationId: $organizationId) {
      success
      message
      data {
        ${PSFileFields}
      }
    }
  }
`;
