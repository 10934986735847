import { ActionIcon, Box, Card, Divider, Group, Text } from "@mantine/core";
import { Edit2, Trash } from "react-feather";
import { Draggable, DragType } from "src/components/drag-and-drop";
import { Question } from "src/graphql";
import styles from "./QuestionListEntry.module.css";

type QuestionListEntryProps = {
  question: Question;
  onRequestEdit: (question: Question) => void;
  onRequestDelete: (question: Question) => void;
};

export const QuestionListEntry = ({
  question,
  onRequestEdit,
  onRequestDelete,
}: QuestionListEntryProps) => {
  const locked = !!question.lockingReferences.length;

  // NOTE: The reason to use the key is to update the question data
  // The question data is used to figure the nodes dropped on the canvas
  return (
    <Draggable
      key={`${question.updatedAt}-${question._id}`}
      type={DragType.Question}
      dragData={question}
    >
      <Card w="100%" withBorder>
        <Card.Section
          bg="green"
          px="xs"
          py={4}
          className={styles.questionHeader}
        >
          <Box
            style={{
              gap: "8px",
              display: "grid",
              gridTemplateColumns: "auto 1fr auto",
            }}
          >
            <Group noWrap spacing={8}>
              <Text size="sm" component="strong" color="white">
                {question.dataId}
              </Text>

              <Divider orientation="vertical" size="sm" my={2} />
            </Group>

            <Text
              size="sm"
              color="white"
              truncate
              style={{ alignSelf: "start" }}
            >
              {question.questionTitle}
            </Text>

            <Group
              noWrap
              spacing={4}
              style={{ alignSelf: "end" }}
              className={styles.actionIcons}
            >
              <ActionIcon
                variant="filled"
                color="green"
                size="xs"
                onClick={() => onRequestEdit(question)}
              >
                <Edit2 size={14} />
              </ActionIcon>

              <ActionIcon
                variant={locked ? "transparent" : "light"}
                disabled={locked}
                color="red"
                size="xs"
                onClick={() => onRequestDelete(question)}
              >
                <Trash size={14} />
              </ActionIcon>
            </Group>
          </Box>
        </Card.Section>

        <Card.Section p={4}>
          <div
            dangerouslySetInnerHTML={{ __html: question.questionText }}
            className={styles.questionText}
          />
        </Card.Section>
      </Card>
    </Draggable>
  );
};
