import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import log from "loglevel";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./validation/yup-extensions";
import { ErrorBoundary, initInstrumentation } from "./libs/instrumentation";

initInstrumentation();

log.enableAll();

const auth0Domain =
  process.env.REACT_APP_AUTH0_DOMAIN ?? "dev-bvhqokrug8njvyji.us.auth0.com";
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? "";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
      cacheLocation="localstorage"
    >
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
