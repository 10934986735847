import { setIn } from "formik";
import { SetStateAction } from "react";

export const setNewNestedValues = <T>(
  setValues: (value: SetStateAction<T>) => void,
  newValues: Record<string, unknown> | Partial<T>,
) =>
  setValues((values) =>
    Object.entries(newValues).reduce(
      (object, [path, value]) => setIn(object, path, value),
      values,
    ),
  );
