import { Tabs, Title } from "@mantine/core";
import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Integration } from "src/graphql";
import { AuthContextType } from "src/providers";

export const BASE_CREATE_TEMPLATE_ID = "create";

export enum TemplateTabs {
  Activities = "activities",
  Flows = "flows",
  Goals = "goals",
  Emails = "emails",
  CarePathways = "carepathways",
  EHRNotes = "ehr-notes",
  Events = "events",
  NoteTemplates = "note-templates",
}

interface TemplateTabConfiguration {
  label: string;
  templateTab: TemplateTabs;
  component: React.LazyExoticComponent<React.ComponentType<unknown>>;
  omit?: (ctx: AuthContextType) => boolean;
}

const tabs: TemplateTabConfiguration[] = [
  {
    label: "Activities",
    templateTab: TemplateTabs.Activities,
    component: React.lazy(
      () => import("../templates-activity/TemplatesListView"),
    ),
  },
  {
    label: "Flows",
    templateTab: TemplateTabs.Flows,
    component: React.lazy(
      () => import("../templates-flow/FlowTemplatesListView"),
    ),
  },
  {
    label: "Goals",
    templateTab: TemplateTabs.Goals,
    component: React.lazy(() => import("../templates-goal")),
  },
  {
    label: "Emails",
    templateTab: TemplateTabs.Emails,
    component: React.lazy(
      () => import("../templates-email/EmailTemplateListView"),
    ),
  },
  {
    label: "Care Pathways",
    templateTab: TemplateTabs.CarePathways,
    component: React.lazy(
      () => import("../templates-carepathway/CarePathWayListView"),
    ),
  },
  {
    label: "EHR Notes",
    templateTab: TemplateTabs.EHRNotes,
    component: React.lazy(
      () => import("../templates-ehr-notes/EHRNoteTemplatesListView"),
    ),

    omit: (ctx) =>
      !ctx.selectedOrganization.activatedIntegrations?.includes(
        Integration.RedoxNoteSending,
      ),
  },
  {
    label: "Events",
    templateTab: TemplateTabs.Events,
    component: React.lazy(
      () => import("../templates-event/EventTemplateListView"),
    ),
  },
  {
    label: "Note Templates",
    templateTab: TemplateTabs.NoteTemplates,
    component: React.lazy(
      () => import("../templates-note/NoteTemplateListView"),
    ),
  },
];

const Templates = () => {
  const history = useHistory();
  const { templateTab } = useParams<{ templateTab: string }>();

  return (
    <div>
      <Title style={{ marginBottom: "8px" }}>Templates</Title>
      <Tabs
        value={templateTab}
        onTabChange={(t) => {
          history.push(`/templates/${t as TemplateTabs}`);
        }}
      >
        <Tabs.List style={{ marginBottom: "16px" }}>
          {tabs.map((tab) => (
            <Tabs.Tab key={tab.templateTab} value={tab.templateTab}>
              {tab.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {tabs.map((tab) => (
          <Tabs.Panel key={tab.templateTab} value={tab.templateTab}>
            <React.Suspense fallback={null}>{<tab.component />}</React.Suspense>
          </Tabs.Panel>
        ))}
      </Tabs>
    </div>
  );
};

export default Templates;
