import { useField } from "formik";
import { Button } from "@mantine/core";
import { RichTextEditor, Link, RichTextEditorProps } from "@mantine/tiptap";
import { useEditor, EditorEvents } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import DocumentExtension from "@tiptap/extension-document";
import TextExtension from "@tiptap/extension-text";
import HistoryExtension from "@tiptap/extension-history";
import ParagraphExtension from "@tiptap/extension-paragraph";
import { IconDots } from "@tabler/icons-react";
import {
  extractWrapperProps,
  FormikInputBaseProps,
  FormikInputWrapper,
} from "../FormikInputWrapper";
import { useEffect, useState } from "react";
import PlaceholderButton from "./placeholders/PlaceholdersSelectMenu";
import { merge } from "lodash";

type BaseEditorProps = Omit<RichTextEditorProps, "children" | "editor"> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styles?: Exclude<RichTextEditorProps["styles"], (...args: any) => unknown>;
  stickyToolbar?: boolean;
};

type FormikTextEditorProps = BaseEditorProps &
  Omit<FormikInputBaseProps<string>, "ref"> & {
    onChangeOverride?: (newContent: string) => void;
    // Note: TipTaps RTE won't reinitialize on a change in the content from an external source
    // Passing this optional value in allows the the text to be externally set from other places
    initialValue?: string;
    textOnly?: boolean; // no html allowed
    hidePlaceholderButton?: boolean; // hide the placeholders button}
  };
export const FormikTextEditor = ({
  textOnly,
  hidePlaceholderButton,
  styles,
  stickyToolbar = true,
  ...props
}: FormikTextEditorProps) => {
  const { className, style, disabled, onChangeOverride, ...rest } = props;
  const [field, , helpers] = useField(rest);
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const onUpdate = (props: EditorEvents["update"]) => {
    const content = textOnly
      ? props.editor.getText({ blockSeparator: "\n" })
      : props.editor.getHTML();

    if (onChangeOverride) onChangeOverride(content);
    else helpers.setValue(content);
  };

  const editor = useEditor({
    extensions: textOnly ? textExtensions() : htmlExtensions(),
    editable: !disabled,
    content: field.value,
    onUpdate,
  });

  useEffect(() => {
    if (props.initialValue !== field.value) {
      editor?.commands.setContent(props.initialValue ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValue]);

  return (
    <FormikInputWrapper {...extractWrapperProps(props)}>
      <RichTextEditor
        {...rest}
        editor={editor}
        styles={merge(
          {
            content: {
              minHeight: 200,
              maxHeight: 500,
              overflowY: "scroll",
              "& .ProseMirror": { minHeight: "100%" },
            },
          },
          styles,
        )}
      >
        {!disabled && (
          <RichTextEditor.Toolbar sticky={stickyToolbar} stickyOffset={60}>
            {textOnly ? null : (
              <HtmlToolbarControlGroups showMoreOptions={showMoreOptions} />
            )}
            {hidePlaceholderButton ? null : (
              <RichTextEditor.ControlsGroup style={{ maxHeight: "26px" }}>
                <PlaceholderButton editor={editor} />
              </RichTextEditor.ControlsGroup>
            )}
            <RichTextEditor.ControlsGroup>
              <Button
                onClick={() => setShowMoreOptions(!showMoreOptions)}
                size="sm"
                variant="subtle"
                compact
                leftIcon={<IconDots size={16} />}
              >
                {showMoreOptions ? "Less" : "More"}
              </Button>
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>
        )}
        <RichTextEditor.Content />
      </RichTextEditor>
    </FormikInputWrapper>
  );
};

const HtmlToolbarControlGroups = ({
  showMoreOptions,
}: {
  showMoreOptions: boolean;
}) => (
  <>
    <RichTextEditor.ControlsGroup>
      <RichTextEditor.Bold h={"30px"} w={"30px"} />
      <RichTextEditor.Italic h={"30px"} w={"30px"} />
      <RichTextEditor.Underline h={"30px"} w={"30px"} />
      <RichTextEditor.Strikethrough h={"30px"} w={"30px"} />
      <RichTextEditor.ClearFormatting h={"30px"} w={"30px"} />
      <RichTextEditor.Highlight h={"30px"} w={"30px"} />
      <RichTextEditor.Code h={"30px"} w={"30px"} />
    </RichTextEditor.ControlsGroup>

    {showMoreOptions && (
      <>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H3 h={"30px"} w={"30px"} />
          <RichTextEditor.H4 h={"30px"} w={"30px"} />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Blockquote h={"30px"} w={"30px"} />
          <RichTextEditor.Hr h={"30px"} w={"30px"} />
          <RichTextEditor.BulletList h={"30px"} w={"30px"} />
          <RichTextEditor.OrderedList h={"30px"} w={"30px"} />
          <RichTextEditor.Subscript h={"30px"} w={"30px"} />
          <RichTextEditor.Superscript h={"30px"} w={"30px"} />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link h={"30px"} w={"30px"} />
          <RichTextEditor.Unlink h={"30px"} w={"30px"} />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft h={"30px"} w={"30px"} />
          <RichTextEditor.AlignCenter h={"30px"} w={"30px"} />
          <RichTextEditor.AlignJustify h={"30px"} w={"30px"} />
          <RichTextEditor.AlignRight h={"30px"} w={"30px"} />
        </RichTextEditor.ControlsGroup>
      </>
    )}
  </>
);
const htmlExtensions = () => [
  StarterKit,
  Underline,
  Link,
  Superscript,
  SubScript,
  Highlight,
  TextAlign.configure({ types: ["heading", "paragraph"] }),
];

const textExtensions = () => [
  TextExtension,
  DocumentExtension,
  HistoryExtension,
  ParagraphExtension,
];
