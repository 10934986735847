import { Button, ButtonProps } from "@mantine/core";
import { PolymorphicComponentProps } from "@mantine/utils";
import { Plus } from "react-feather";

import { useAuthContext } from "src/hooks";
import { Permission } from "src/graphql";

type AddTemplateButtonProps = PolymorphicComponentProps<
  "button",
  ButtonProps
> & {
  text: string;
  permissions: Permission[];
};

export const AddTemplateButton = ({
  text,
  permissions,
  ...props
}: AddTemplateButtonProps) => {
  const { hasAnyPermission } = useAuthContext();
  if (!hasAnyPermission(...permissions)) return null;

  return (
    <Button {...props} leftIcon={<Plus size="1em" />}>
      {text}
    </Button>
  );
};
