import { Flex, Loader, Select, SelectItem, Text, Tooltip } from "@mantine/core";
import { useField } from "formik";
import { forwardRef, useMemo, useState } from "react";
import { Info } from "react-feather";
import {
  extractWrapperProps,
  FormikInputBaseProps,
  FormikInputWrapper,
} from "./FormikInputWrapper";

type FormikSelectProps<Option extends SelectItem> = FormikInputBaseProps<
  Option | string | null
> & {
  options: Option[];
  isLoading?: boolean;
  withinPortal?: boolean;
  itemComponent?: React.FC<unknown>;
  clearable?: boolean;
  searchable?: boolean;
  allowDeselect?: boolean;
  // value?: Option | string | number;

  onSearchChange?: (value: string) => void;
  // Overrides the default Formik onChange handler external control
  onChangeOverride?: (nextValue: Option | null) => void;
  disabledItemToolTipMessage?: string;

  getCreateLabel?: (query: string) => string;
} & (
    | { creatable: true; onCreate: (query: string) => Option | null }
    | { creatable?: false; onCreate?: never }
  );

export const FormikSelect = <Option extends SelectItem>(
  props: FormikSelectProps<Option>,
) => {
  const {
    options,
    placeholder = "Select one",
    disabled,
    onChangeOverride,
    itemComponent,
    withinPortal,
    creatable,
    getCreateLabel,
    clearable,
    allowDeselect,
    searchable,
    onSearchChange,
    isLoading,
    disabledItemToolTipMessage,
    style,
  } = props;

  const [field, , helpers] = useField({ ...props, type: "select" });
  const [createdOptions, setCreatedOptions] = useState<Option[]>([]);

  const resolvedOptions = useMemo(
    () => [...createdOptions, ...options],
    [createdOptions, options],
  );

  const handleChange = (value: string) => {
    // The fallback here is because handleCreate and handleChange
    // are executed in same render, due to which the createdOptions
    // would not include the created option
    const option =
      resolvedOptions.find((option) => option.value === value) ?? null;

    if (onChangeOverride) onChangeOverride(option);
    else helpers.setValue(option);
  };

  const handleCreate = !props.creatable
    ? undefined
    : (query: string) => {
        const option = props.onCreate(query);

        if (option) {
          setCreatedOptions((existent) => [option, ...existent]);
          if (onChangeOverride) onChangeOverride(option);
          else helpers.setValue(option);
        }

        // NOTE: This is intentional, returning null here
        // prevents onChange handler from being run
        return null;
      };

  const value = useMemo(() => {
    if (isStringOrNullOrUndefined(field.value)) return field.value ?? null;
    return field.value.value;
  }, [field.value]);

  return (
    <FormikInputWrapper {...extractWrapperProps(props)}>
      <Select
        {...field}
        style={style}
        allowDeselect={allowDeselect}
        clearable={clearable}
        placeholder={placeholder}
        nothingFound="No options"
        disabled={disabled || isLoading}
        data={resolvedOptions}
        value={value}
        onChange={handleChange}
        creatable={creatable}
        onCreate={handleCreate}
        getCreateLabel={getCreateLabel ?? ((query) => `+ Create ${query}`)}
        searchable={searchable}
        onSearchChange={onSearchChange}
        withinPortal={withinPortal}
        itemComponent={
          itemComponent ??
          (disabledItemToolTipMessage
            ? SelectWithDisabledReason(disabledItemToolTipMessage)
            : undefined)
        }
        rightSection={isLoading ? <Loader size="xs" /> : undefined}
      />
    </FormikInputWrapper>
  );
};

const SelectWithDisabledReason = (reason: string) =>
  forwardRef<HTMLDivElement, SelectItem>(
    ({ label, questions, ...others }: SelectItem, ref) => (
      <Flex justify="space-between" align="center" ref={ref} {...others}>
        <Text size="sm">{label}</Text>
        {others.disabled && (
          <Tooltip
            withArrow
            withinPortal
            position="right"
            label={<Text size="sm">{reason}</Text>}
          >
            <Info
              size={14}
              color={others["data-selected"] ? "white" : "var(--GREEN)"}
              cursor="pointer"
            />
          </Tooltip>
        )}
      </Flex>
    ),
  );

const isStringOrNullOrUndefined = (
  value: unknown,
): value is string | null | undefined =>
  typeof value === "string" || value === null || value === undefined;
