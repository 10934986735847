import { Button, ButtonProps } from "@mantine/core";
import {
  Organization,
  useLazyQueryAccountDashboardLink,
  useLazyQueryConnectOnboardingLink,
  useLazyQueryCustomerPortalLink,
} from "src/graphql";
import { useAuthContext } from "src/hooks";
import toast from "src/libs/toast";

type StripeButtonProps = { organization: Organization } & ButtonProps;

const onlyInProduction = (value: boolean) =>
  process.env.NODE_ENV === "production" && value;

export const CustomerPortalButton = ({
  organization,
  children,
  ...props
}: StripeButtonProps) => {
  const { currentUser } = useAuthContext();

  const [queryCustomerPortalLink, { loading }] = useLazyQueryCustomerPortalLink(
    organization._id,
  );

  const handleClick = async () => {
    try {
      const response = await queryCustomerPortalLink();

      if (
        !response.data?.customerPortalLink.success ||
        !response.data?.customerPortalLink.data
      )
        throw new Error(response.data?.customerPortalLink.message);

      window.location.href = response.data.customerPortalLink.data;
    } catch (error) {
      const defaultErrorMessage =
        "An error occurred while opening the customer portal. Please try again later.";

      toast.error(
        error instanceof Error
          ? (error.message ?? defaultErrorMessage)
          : defaultErrorMessage,
      );
    }
  };

  return (
    <Button
      {...props}
      loading={loading}
      onClick={handleClick}
      disabled={
        props.disabled ||
        !organization.stripeCustomerId ||
        onlyInProduction(currentUser.isSuperAdmin)
      }
    >
      {children}
    </Button>
  );
};

export const CustomerConnectAccountOnboardingButton = ({
  organization,
  children,
  ...props
}: StripeButtonProps) => {
  const { currentUser } = useAuthContext();

  const [queryConnectOnboardingLink, { loading }] =
    useLazyQueryConnectOnboardingLink(organization._id);

  const handleClick = async () => {
    try {
      const response = await queryConnectOnboardingLink();

      if (
        !response.data?.connectOnboardingLink.success ||
        !response.data?.connectOnboardingLink.data
      )
        throw new Error(response.data?.connectOnboardingLink.message);

      window.location.href = response.data.connectOnboardingLink.data;
    } catch (error) {
      const defaultErrorMessage =
        "An error occurred while opening the connect onboarding. Please try again later.";

      toast.error(
        error instanceof Error
          ? (error.message ?? defaultErrorMessage)
          : defaultErrorMessage,
      );
    }
  };

  return (
    <Button
      {...props}
      loading={loading}
      onClick={handleClick}
      disabled={
        props.disabled ||
        onlyInProduction(currentUser.isSuperAdmin) ||
        organization.billingEnabled !== true
      }
    >
      {children}
    </Button>
  );
};

export const CustomerConnectAccountDashboardButton = ({
  organization,
  children,
  ...props
}: StripeButtonProps) => {
  const { currentUser } = useAuthContext();

  const [queryDashboardLink, { loading }] = useLazyQueryAccountDashboardLink(
    organization._id,
  );

  const handleClick = async () => {
    try {
      const response = await queryDashboardLink();

      if (
        !response.data?.accountDashboardLink.success ||
        !response.data?.accountDashboardLink.data
      )
        throw new Error(response.data?.accountDashboardLink.message);

      window.open(response.data.accountDashboardLink.data, "_blank");
    } catch (error) {
      const defaultErrorMessage =
        "An error occurred while opening the account dashboard. Please try again later.";

      toast.error(
        error instanceof Error
          ? (error.message ?? defaultErrorMessage)
          : defaultErrorMessage,
      );
    }
  };

  return (
    <Button
      {...props}
      loading={loading}
      onClick={handleClick}
      disabled={
        props.disabled ||
        onlyInProduction(currentUser.isSuperAdmin) ||
        !organization.stripePayoutAccountId
      }
    >
      {children}
    </Button>
  );
};
