import { useEffect, useMemo, useState } from "react";
import { Node, Edge, useReactFlow } from "react-flow-renderer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import toast from "src/libs/toast";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  BUILDER_HEADER_HEIGHT,
  FlowTemplateBuilderHeader,
} from "./FlowTemplateBuilderHeader";
import { FlowEditor } from "./FlowEditor";
import {
  CreateFlowTemplateInput,
  FlowTemplate,
  JourneyStage,
  JourneyStagePositions,
  UpdateFlowTemplateInput,
  useMutationCreateFlowTemplate,
  useMutationUpdateFlowTemplate,
} from "src/graphql";
import {
  findNodeById,
  parseTemplateForFlow,
  PearNodesData,
  serializeFlowNodes,
  scrollToNode,
} from "../util";
import { FlowCodecError } from "./errors";
import { useAuthContext } from "src/hooks";
import {
  FlowNodeRenderContext,
  useNodeMeasuringContainer,
} from "../NodeMeasuringContainer";
import { FlowBuilderData } from "../hooks";
import { START_NODE_WIDTH } from "../flow-nodes/StartFlowNode";
import log from "loglevel";
import { WarnLockingReferencesModal } from "src/components/warn-locking-references-modal";
import { BASE_CREATE_TEMPLATE_ID } from "src/pages/templates";

const HEADER_HEIGHT_OFFSET = 141;

export const makeDefaultStagePositions = (
  startNodeX: number,
): JourneyStagePositions => {
  const startPos = Math.floor(startNodeX + START_NODE_WIDTH);

  return {
    [JourneyStage.Contemplation]: startPos + 200,
    [JourneyStage.Preparation]: startPos + 400,
    [JourneyStage.Action]: startPos + 600,
    [JourneyStage.Maintenance]: startPos + 800,
  };
};

export const ReadOnlyNotice = styled.div`
  width: 100%;
  background-color: var(--color-light-grey);
  padding: 8px 12px;
`;

type FlowTemplateBuilderContainerProps = {
  selectedTemplate: FlowTemplate;
  builderData: FlowBuilderData;
  /**
   * @default false
   */
  readOnly?: boolean;
};

export const FlowTemplateBuilderContainer = ({
  selectedTemplate,
  builderData,
  readOnly = false,
}: FlowTemplateBuilderContainerProps) => {
  const history = useHistory();
  const location = useLocation();
  const reactFlowInstance = useReactFlow();
  const { selectedOrganizationId } = useAuthContext();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [workingTitle, setWorkingTitle] = useState(selectedTemplate.title);
  const [nodes, setNodes] = useState<Node<PearNodesData>[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [stagePositions, setStagePositions] = useState(
    selectedTemplate.stagePositions ??
      makeDefaultStagePositions(selectedTemplate.startNodePosition.x),
  );
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [pendingFlowTemplate, setPendingFlowTemplate] =
    useState<UpdateFlowTemplateInput>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { nodesToMeasure, setNodesToMeasure, onNodesMeasured } =
    useNodeMeasuringContainer(nodes, setNodes);

  const [mutationCreateFlowTemplate] = useMutationCreateFlowTemplate(
    selectedOrganizationId,
  );
  const [mutationUpdateFlowTemplate] = useMutationUpdateFlowTemplate();

  useEffect(() => {
    const [startNode, parsedNodeTuples, parsedEdges] =
      parseTemplateForFlow(selectedTemplate);

    setNodes([startNode]);
    setNodesToMeasure({
      tuples: parsedNodeTuples,
      isDropPlacement: false,
      context: FlowNodeRenderContext.TemplateEditor,
    });
    setEdges(parsedEdges);
    // don't add questionsById to this effect dependency array pls!
    // this is only used for initial parsing and question updates are not relevant.
  }, [selectedTemplate]); // eslint-disable-line

  const references = useMemo(
    () =>
      selectedTemplate.lockingReferences.length
        ? selectedTemplate.lockingReferences.filter((ref) => ref.isActive)
        : [],
    [selectedTemplate],
  );

  const handleCreateTemplate = async (input: CreateFlowTemplateInput) => {
    const response = await mutationCreateFlowTemplate({
      variables: { input },
    });

    if (
      response.errors ||
      !response.data?.createFlowTemplate.success ||
      !response.data.createFlowTemplate.data
    ) {
      throw new Error("Graphql request failed; check logs");
    }

    toast.success("Flow Template Saved!");
    setIsSubmitting(false);
    setHasUnsavedChanges(false);

    if (location.pathname.endsWith(BASE_CREATE_TEMPLATE_ID)) {
      const nextPath = location.pathname.replaceAll(
        BASE_CREATE_TEMPLATE_ID,
        response.data.createFlowTemplate.data._id,
      );
      history.replace(nextPath);
    }
  };

  const handleUpdateTemplate = async (input: UpdateFlowTemplateInput) => {
    const response = await mutationUpdateFlowTemplate({
      variables: { input },
    });

    if (
      response.errors ||
      !response.data?.updateFlowTemplate.success ||
      !response.data.updateFlowTemplate.data
    ) {
      log.debug(response);
      throw new Error("Graphql request failed; check logs");
    }

    toast.success("Flow Template Saved!");
    setIsSubmitting(false);
    setHasUnsavedChanges(false);

    const nextId = response.data.updateFlowTemplate.data._id;

    // if we created a new template or created a new version, navigate to that new ID
    if (!location.pathname.endsWith(nextId)) {
      history.replace(location.pathname.replaceAll(input._id, nextId));
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const { entryNodeId, startNodePosition, flowStepInputs } =
        serializeFlowNodes(nodes, edges);
      const input: CreateFlowTemplateInput = {
        flowType: selectedTemplate.flowType,
        organizationId: selectedOrganizationId,
        title: workingTitle,
        steps: flowStepInputs,
        entryStepId: entryNodeId,
        startNodePosition,
        stagePositions,
      };

      if (selectedTemplate._id === BASE_CREATE_TEMPLATE_ID) {
        await handleCreateTemplate(input);
      } else {
        const { flowType, ...rest } = input;
        const updateInput = {
          ...rest,
          _id: selectedTemplate._id,
        };
        if (references.length > 0) {
          setPendingFlowTemplate(updateInput);
          setShowWarningModal(true);
        } else {
          await handleUpdateTemplate(updateInput);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      log.debug(err);
      log.error("Graphql request failed; check logs: ", err.message);
      if (err instanceof FlowCodecError) {
        toast.error(err.jsxMessage ?? err.message);
        if (err.focusNodeId) {
          const focusNode = findNodeById(nodes, err.focusNodeId);
          scrollToNode(focusNode, reactFlowInstance);
        }
      } else {
        toast.error(
          err.message ??
            "Oops, something went wrong saving template. Please try again.",
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onLockingReferenceConfirm = async () => {
    if (!pendingFlowTemplate) return;
    await handleUpdateTemplate(pendingFlowTemplate);
    setShowWarningModal(false);
  };

  const handleSetNodes = (nextNodes: Node<PearNodesData>[]) => {
    setHasUnsavedChanges(true);
    setNodes(nextNodes);
  };

  const handleSetEdges = (nextEdges: Edge[]) => {
    setHasUnsavedChanges(true);
    setEdges(nextEdges);
  };

  const handleSetStagePositions = (nextPositions: JourneyStagePositions) => {
    setHasUnsavedChanges(true);
    setStagePositions(nextPositions);
  };

  const handleSetTitle = (nextTitle: string) => {
    setHasUnsavedChanges(true);
    setWorkingTitle(nextTitle);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <>
        {readOnly && (
          <ReadOnlyNotice>
            You are unable to edit this template. Check with the Admin of the
            parent organization to grant you write access.
          </ReadOnlyNotice>
        )}

        <FlowTemplateBuilderHeader
          isSubmitting={isSubmitting}
          title={workingTitle}
          canSubmit={hasUnsavedChanges}
          onSubmit={handleSubmit}
          onChangeTitle={handleSetTitle}
          readOnly={readOnly}
        />

        <div
          style={{
            display: "flex",
            height: `calc((100vh - ${
              BUILDER_HEADER_HEIGHT + HEADER_HEIGHT_OFFSET
            }px))`,
          }}
        >
          <FlowEditor
            nodes={nodes}
            edges={edges}
            nodesToMeasure={nodesToMeasure}
            flowType={selectedTemplate.flowType}
            setNodes={handleSetNodes}
            setEdges={handleSetEdges}
            onChangeStagePositions={handleSetStagePositions}
            setNodesToMeasure={setNodesToMeasure}
            onNodesMeasured={onNodesMeasured}
            stagePositions={stagePositions}
            builderData={builderData}
            readOnly={readOnly}
          />
        </div>
        <WarnLockingReferencesModal
          isOpen={showWarningModal}
          lockedObject="Flow Template"
          activeReferences={references}
          onConfirm={onLockingReferenceConfirm}
          onRequestClose={() => setShowWarningModal(false)}
        />
      </>
    </DndProvider>
  );
};

export type FlowTemplateFormValues = FlowTemplate;
