import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_ACTIVITY_TEMPLATE,
  GET_ACTIVITY_TEMPLATES,
  GET_ACTIVITY_TEMPLATE_QUESTIONS,
} from "./queries";
import {
  GetActivityTemplateResponse,
  GetActivityTemplatesWithAccessResponse,
  GetActivityTemplateWithAccessResponse,
  MutationCreateActivityTemplateArgs,
  MutationRetireActivityTemplateArgs,
  MutationUpdateActivityTemplateArgs,
  QueryActivityTemplateArgs,
  QueryActivityTemplatesArgs,
} from "../schemaTypes";
import {
  CREATE_ACTIVITY_TEMPLATE,
  RETIRE_ACTIVITY_TEMPLATE,
  UPDATE_ACTIVITY_TEMPLATE,
} from "./mutations";
import { GET_BUILDER_GRAPH } from "../Builder/queries";
import {
  GET_FLOW_BUILDER_DATA,
  GET_FLOW_TEMPLATES,
} from "../FlowTemplate/queries";
import { GET_EVENT_TEMPLATES } from "../EventTemplate/queries";
import { GET_DATA_IDS_WITH_ANSWER_TYPE_BY_ORGANIZATION_ID } from "../DataPoint/queries";

export const useQueryActivityTemplates = (organizationId: string) =>
  useQuery<
    {
      activityTemplates: GetActivityTemplatesWithAccessResponse;
    },
    QueryActivityTemplatesArgs
  >(GET_ACTIVITY_TEMPLATES, { variables: { organizationId } });

export const useLazyQueryActivityTemplates = () =>
  useLazyQuery<
    {
      activityTemplates: GetActivityTemplatesWithAccessResponse;
    },
    QueryActivityTemplatesArgs
  >(GET_ACTIVITY_TEMPLATES, { fetchPolicy: "network-only" });

export const useQueryActivityTemplate = (
  activityTemplateId: string,
  organizationId: string,
  onboardingToken?: string,
  skip?: boolean,
) =>
  useQuery<
    {
      activityTemplate: GetActivityTemplateWithAccessResponse;
    },
    QueryActivityTemplateArgs
  >(GET_ACTIVITY_TEMPLATE, {
    variables: { activityTemplateId, organizationId },
    skip,
    ...(onboardingToken
      ? {
          context: {
            headers: {
              "Pear-Onboarding-Survey": onboardingToken,
            },
          },
        }
      : {}),
  });

export const useMutationCreateActivityTemplate = (organizationId: string) =>
  useMutation<
    { createActivityTemplate: GetActivityTemplateResponse },
    MutationCreateActivityTemplateArgs
  >(CREATE_ACTIVITY_TEMPLATE, {
    refetchQueries: [
      { query: GET_ACTIVITY_TEMPLATES, variables: { organizationId } },
      GET_FLOW_TEMPLATES,
      { query: GET_FLOW_BUILDER_DATA, variables: { organizationId } },
      {
        query: GET_DATA_IDS_WITH_ANSWER_TYPE_BY_ORGANIZATION_ID,
        variables: { organizationId },
      },
      GET_BUILDER_GRAPH,
      GET_EVENT_TEMPLATES,
    ],
  });

export const useMutationUpdateActivityTemplate = (organizationId: string) =>
  useMutation<
    { updateActivityTemplate: GetActivityTemplateResponse },
    MutationUpdateActivityTemplateArgs
  >(UPDATE_ACTIVITY_TEMPLATE, {
    refetchQueries: [
      { query: GET_ACTIVITY_TEMPLATES, variables: { organizationId } },
      GET_FLOW_TEMPLATES,
      { query: GET_FLOW_BUILDER_DATA, variables: { organizationId } },
      {
        query: GET_DATA_IDS_WITH_ANSWER_TYPE_BY_ORGANIZATION_ID,
        variables: { organizationId },
      },
      GET_BUILDER_GRAPH,
      GET_EVENT_TEMPLATES,
    ],
  });

export const useMutationRetireActivityTemplate = (organizationId: string) =>
  useMutation<
    { retireActivityTemplate: GetActivityTemplateResponse },
    MutationRetireActivityTemplateArgs
  >(RETIRE_ACTIVITY_TEMPLATE, {
    refetchQueries: [
      { query: GET_ACTIVITY_TEMPLATES, variables: { organizationId } },
      GET_BUILDER_GRAPH,
    ],
  });

export const useLazyQueryGetActivityTemplateQuestions = (
  organizationId: string,
  activityTemplateId: string,
) =>
  useLazyQuery(GET_ACTIVITY_TEMPLATE_QUESTIONS, {
    variables: {
      organizationId,
      activityTemplateId,
    },
    fetchPolicy: "network-only",
  });

export const useLazyQueryGetActivityTemplateQuestionsNoProps = () =>
  useLazyQuery(GET_ACTIVITY_TEMPLATE_QUESTIONS, {
    fetchPolicy: "network-only",
  });
