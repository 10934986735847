import { useCallback, useEffect, useState } from "react";
import { Node, Edge } from "react-flow-renderer";
import toast from "src/libs/toast";
import { ActionDataCollectionModal } from "src/components/flow-display/ActionDataCollectionModal";
import {
  Action,
  ActionInput,
  Answer,
  ExecuteActionInput,
  Flow,
  FlowTemplateWithAccess,
  useLazyQueryAssignableUserOptions,
} from "src/graphql";
import { useMutationEditFlowProgressFromSnapshot } from "src/graphql/Flow/useMutationEditFlowProgressFromSnapshot";
import { useAuthContext } from "src/hooks";
import styled from "styled-components";
import { FlowBuilderData } from "../hooks";
import {
  FlowNodeRenderContext,
  useNodeMeasuringContainer,
} from "../NodeMeasuringContainer";
import {
  augmentNodesWithFlowProgress,
  flattenActions,
  gatherActionsToPerform,
  getActionsNeedingInput,
  IndividualActionData,
  parseTemplateForFlow,
  PearNodesData,
} from "../util";
import { FlowSnapshotEditor } from "./FlowSnapshotEditor";
import { FlowSnapshotHeader } from "./FlowSnapshotHeader";

const StyledContainer = styled.div`
  margin: -20px;
  height: calc(100% + 40px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

type FlowSnapshotProps = {
  selectedTemplate: FlowTemplateWithAccess;
  selectedFlow: Flow;
  builderData: FlowBuilderData;
};

export const FlowSnapshotContainer = ({
  selectedTemplate,
  selectedFlow,
  builderData,
}: FlowSnapshotProps) => {
  const { selectedOrganizationId } = useAuthContext();
  const [actionsToPerform, setActionsToPerform] = useState<Action[] | null>(
    null,
  );
  const [actionsToCollectDataFor, setActionsToCollectDataFor] = useState<
    IndividualActionData[] | null
  >(null);
  const [originalWorkingAnswers, setOriginalWorkingAnswers] = useState<
    Answer[]
  >([]);
  const [workingAnswers, setWorkingAnswers] = useState<Answer[]>([]);
  const [workingCurrentStepId, setWorkingCurrentStepId] = useState("");
  const [isEditingNode, setIsEditingNode] = useState(false);
  const [nodes, setNodes] = useState<Node<PearNodesData>[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { nodesToMeasure, setNodesToMeasure, onNodesMeasured } =
    useNodeMeasuringContainer(nodes, setNodes);
  const [mutationEditFlow] = useMutationEditFlowProgressFromSnapshot(
    selectedFlow._id,
    selectedOrganizationId,
  );

  const {
    queryAssignableUsers,
    assignableUserOptions,
    assignableUsersLoading,
  } = useLazyQueryAssignableUserOptions();

  // setup initial editor state when dependencies change
  const setInitialState = useCallback(() => {
    // get our normal parsed nodes from template
    const [startNode, parsedNodeTuples, parsedEdges] = parseTemplateForFlow(
      selectedTemplate.flowTemplate,
    );

    // map flow progress onto parsed template nodes
    const [tuplesWithFlowStatus, edgesWithFlowStatus, mappedAnswers] =
      augmentNodesWithFlowProgress(
        selectedTemplate.flowTemplate,
        parsedNodeTuples,
        parsedEdges,
        selectedFlow.answers ?? [],
      );

    setActionsToPerform(null);
    setActionsToCollectDataFor(null);
    setIsEditingNode(false);
    setIsSubmitting(false);
    setHasUnsavedChanges(false);

    setOriginalWorkingAnswers(mappedAnswers);
    setWorkingAnswers(mappedAnswers);
    setWorkingCurrentStepId(selectedFlow.currentStep ?? "");
    setNodes([startNode]);
    setNodesToMeasure({
      tuples: tuplesWithFlowStatus,
      isDropPlacement: false,
      context: FlowNodeRenderContext.SnapshotEditor,
    });
    setEdges(edgesWithFlowStatus);
  }, [selectedTemplate, selectedFlow, setNodesToMeasure]);

  // runs initially, and then when external props are changed, e.g. on flow save + refetch
  useEffect(() => {
    setInitialState();
  }, [setInitialState]);

  // called when "save changes" clicked for flow; before committing results,
  // walks new answers + actions to determine if any actions require user input,
  // prompting for those if necessary before calling handleSubmit
  const handleRequestSubmit = () => {
    // before submitting, check if we need to collect more info for new actions
    // (e.g. scheduling activities)

    // perform any previously unperformed actions from the nextAnswers arr
    const previouslyUnperformedActions = gatherActionsToPerform(
      selectedTemplate.flowTemplate,
      originalWorkingAnswers,
      workingAnswers,
    );
    setActionsToPerform(previouslyUnperformedActions);

    // the way the modal is set up, we need to filter out for the inputs we have actions for -
    const filtered = getActionsNeedingInput(
      previouslyUnperformedActions.flatMap(flattenActions),
    );

    if (filtered.length) {
      queryAssignableUsers();
      setActionsToCollectDataFor(filtered);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async (extraActionInfo?: ExecuteActionInput[]) => {
    try {
      setIsSubmitting(true);

      const result = await mutationEditFlow({
        variables: {
          input: {
            organizationId: selectedOrganizationId,
            flowId: selectedFlow._id,
            nextAnswers: workingAnswers,
            nextCurrentStepId: workingCurrentStepId,
            nextActions: (actionsToPerform as ActionInput[]) ?? [],
            extraActionInfo,
          },
        },
      });

      if (result.data?.updateFlowFromSnapshotEditor.success) {
        toast.success("Success! Flow progress updated.");
      } else {
        throw new Error("Couldn't update flow.");
      }
    } catch {
      toast.error("Couldn't submit. Please try again");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancelActionCollection = () => {
    setActionsToCollectDataFor(null);
    setActionsToPerform(null);
  };

  return (
    <StyledContainer>
      <FlowSnapshotHeader
        title={selectedTemplate.flowTemplate.title}
        isSubmitting={isSubmitting}
        canSubmit={hasUnsavedChanges && !isEditingNode}
        onRequestSubmit={handleRequestSubmit}
      />

      <FlowSnapshotEditor
        nodes={nodes}
        edges={edges}
        nodesToMeasure={nodesToMeasure}
        workingAnswers={workingAnswers}
        currentFlowStepId={workingCurrentStepId}
        currentStepHasChanged={
          workingCurrentStepId !== selectedFlow.currentStep
        }
        setIsEditingNode={setIsEditingNode}
        setCurrentFlowStepId={setWorkingCurrentStepId}
        setWorkingAnswers={(next) => {
          setWorkingAnswers(next);
          setHasUnsavedChanges(true);
        }}
        setEdges={setEdges}
        setNodesToMeasure={setNodesToMeasure}
        onNodesMeasured={onNodesMeasured}
      />

      <ActionDataCollectionModal
        modalIsOpen={!!actionsToCollectDataFor}
        onRequestClose={handleCancelActionCollection}
        onSubmit={handleSubmit}
        actionsToCollectDataFor={actionsToCollectDataFor ?? []}
        assignableUserOptions={assignableUserOptions ?? []}
        assignableUsersLoading={assignableUsersLoading}
      />
    </StyledContainer>
  );
};
