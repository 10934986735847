import { useMutation } from "@apollo/client";
import {
  MutationUpdateFlowFromSnapshotEditorArgs,
  UpdateFlowFromSnapshotEditorResponse,
} from "../schemaTypes";
import { EDIT_FLOW_FROM_SNAPSHOT } from "./mutations";
import { GET_FLOW } from "./queries";

export const useMutationEditFlowProgressFromSnapshot = (
  flowId: string,
  organizationId: string,
) =>
  useMutation<
    {
      updateFlowFromSnapshotEditor: UpdateFlowFromSnapshotEditorResponse;
    },
    MutationUpdateFlowFromSnapshotEditorArgs
  >(EDIT_FLOW_FROM_SNAPSHOT, {
    refetchQueries: [
      {
        query: GET_FLOW,
        variables: {
          flowId,
          organizationId,
        },
      },
    ],
  });
