import { useMutation, useQuery } from "@apollo/client";
import {
  GetEventTemplateResponse,
  GetEventTemplatesResponse,
  MutationCreateEventTemplateArgs,
  MutationDeleteEventTemplateArgs,
  MutationUpdateEventTemplateArgs,
  QueryEventTemplateArgs,
  QueryEventTemplatesArgs,
} from "../schemaTypes";
import { GET_EVENT_TEMPLATE, GET_EVENT_TEMPLATES } from "./queries";
import {
  CREATE_EVENT_TEMPLATE,
  DELETE_EVENT_TEMPLATE,
  UPDATE_EVENT_TEMPLATE,
} from "./mutations";
import { GET_BUILDER_GRAPH } from "../Builder/queries";

export const useQueryEventTemplates = (organizationId: string) =>
  useQuery<
    {
      eventTemplates: GetEventTemplatesResponse;
    },
    QueryEventTemplatesArgs
  >(GET_EVENT_TEMPLATES, {
    variables: { organizationId },
  });

export const useQueryEventTemplate = (organizationId: string, id?: string) =>
  useQuery<
    {
      eventTemplate: GetEventTemplateResponse;
    },
    QueryEventTemplateArgs
  >(GET_EVENT_TEMPLATE, {
    skip: !id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: id!, organizationId: organizationId! },
  });

export const useMutationCreateEventTemplate = (organizationId: string) =>
  useMutation<
    {
      createEventTemplate: GetEventTemplateResponse;
    },
    MutationCreateEventTemplateArgs
  >(CREATE_EVENT_TEMPLATE, {
    update: (cache, { data }) => {
      if (
        !data ||
        !data.createEventTemplate.success ||
        !data.createEventTemplate.data
      )
        return;

      const newEventTemplate = data.createEventTemplate.data;

      const eventTemplatesQuery = cache.readQuery<
        { eventTemplates: GetEventTemplatesResponse },
        QueryEventTemplatesArgs
      >({
        query: GET_EVENT_TEMPLATES,
        variables: { organizationId },
      });

      if (
        !eventTemplatesQuery ||
        !eventTemplatesQuery.eventTemplates ||
        !eventTemplatesQuery.eventTemplates.success ||
        !eventTemplatesQuery.eventTemplates.data
      )
        return;

      const eventTemplates = [
        ...eventTemplatesQuery.eventTemplates.data,
        newEventTemplate,
      ];

      cache.writeQuery<
        { eventTemplates: GetEventTemplatesResponse },
        { organizationId: string }
      >({
        query: GET_EVENT_TEMPLATES,
        variables: { organizationId },
        data: {
          eventTemplates: {
            ...eventTemplatesQuery.eventTemplates,
            data: eventTemplates,
          },
        },
      });
    },
    refetchQueries: [GET_BUILDER_GRAPH],
  });

export const useMutationUpdateEventTemplate = () =>
  useMutation<
    {
      updateEventTemplate: GetEventTemplateResponse;
    },
    MutationUpdateEventTemplateArgs
  >(UPDATE_EVENT_TEMPLATE, {
    refetchQueries: [GET_EVENT_TEMPLATES, GET_BUILDER_GRAPH],
  });

export const useMutationDeleteEventTemplate = (
  id: string,
  organizationId: string,
) =>
  useMutation<
    {
      deleteEventTemplate: GetEventTemplateResponse;
    },
    MutationDeleteEventTemplateArgs
  >(DELETE_EVENT_TEMPLATE, {
    refetchQueries: [GET_EVENT_TEMPLATES],
  });
