import { gql } from "@apollo/client";
import { trainingFields } from "./types";

export const GET_TRAININGS = gql`
  query GetTrainingsByUserId($userId: ID!, $organizationId:ID!) {
    trainingsByUserId(userId: $userId, organizationId:$organizationId) {
      success
      message
      data {
        ${trainingFields}
      }
    }
  }
`;

export const GET_TRAINING = gql`
  query GetTraining($trainingId: ID!,$organizationId:ID!) {
    training(trainingId: $trainingId, organizationId:$organizationId) {
      success
      message
      data {
        ${trainingFields}
      }
    }
  }
`;
