import { useMutation, useQuery } from "@apollo/client";
import {
  ExternalContact,
  GetExternalContactsResponse,
  GetExternalResourceContactResponse,
  GetExternalResourceResponse,
  GetExternalResourcesResponse,
  MutationCreateExternalResourceArgs,
  MutationCreateExternalResourceContactArgs,
  MutationRetireExternalResourceArgs,
  MutationRetireExternalResourceContactArgs,
  MutationUpdateExternalResourceArgs,
  MutationUpdateExternalResourceContactArgs,
  QueryExternalResourceContactsArgs,
  QueryExternalResourcesArgs,
} from "../schemaTypes";
import {
  CREATE_EXTERNAL_RESOURCE,
  CREATE_EXTERNAL_RESOURCE_CONTACT,
  RETIRE_EXTERNAL_RESOURCE,
  RETIRE_EXTERNAL_RESOURCE_CONTACT,
  UPDATE_EXTERNAL_RESOURCE,
  UPDATE_EXTERNAL_RESOURCE_CONTACT,
} from "./mutations";
import {
  GET_EXTERNAL_RESOURCES,
  GET_EXTERNAL_RESOURCE_CONTACTS,
} from "./queries";
import { usePagination } from "src/hooks/usePagination";

export const useQueryExternalResources = (organizationId: string) =>
  useQuery<
    { externalResources: GetExternalResourcesResponse },
    QueryExternalResourcesArgs
  >(GET_EXTERNAL_RESOURCES, { variables: { organizationId } });

export const usePaginatedQueryExternalContacts = (
  organization: string,
  onlyPhone = false,
  pageSize = 20,
) =>
  usePagination<
    QueryExternalResourceContactsArgs,
    { response: GetExternalContactsResponse },
    ExternalContact
  >({
    document: GET_EXTERNAL_RESOURCE_CONTACTS,
    variables: { input: { organization, onlyPhone } },
    options: {
      size: pageSize,
      defaultSort: {
        field: "name",
        direction: 1,
      },
    },
  });

export const useMutationCreateExternalResource = (organizationId: string) =>
  useMutation<
    { createExternalResource: GetExternalResourceResponse },
    MutationCreateExternalResourceArgs
  >(CREATE_EXTERNAL_RESOURCE, {
    update: (cache, result) =>
      cache.updateQuery<{ externalResources: GetExternalResourcesResponse }>(
        {
          query: GET_EXTERNAL_RESOURCES,
          variables: { organizationId },
        },
        (cached) => {
          const newResource = result.data?.createExternalResource.data;
          if (!cached || !newResource) return;

          return {
            ...cached,
            externalResources: {
              ...cached.externalResources,
              data: [...(cached.externalResources.data ?? []), newResource],
            },
          };
        },
      ),
  });

export const useMutationUpdateExternalResource = () =>
  useMutation<
    { updateExternalResource: GetExternalResourceResponse },
    MutationUpdateExternalResourceArgs
  >(UPDATE_EXTERNAL_RESOURCE);

export const useMutationRetireExternalResource = () =>
  useMutation<
    { retireExternalResource: GetExternalResourceResponse },
    MutationRetireExternalResourceArgs
  >(RETIRE_EXTERNAL_RESOURCE);

export const useMutationCreateExternalResourceContact = () =>
  useMutation<
    { createExternalResourceContact: GetExternalResourceContactResponse },
    MutationCreateExternalResourceContactArgs
  >(CREATE_EXTERNAL_RESOURCE_CONTACT, {
    update: (cache, result) => {
      const newContact = result.data?.createExternalResourceContact.data;
      if (!newContact) return;

      const newContactCacheId = cache.identify(newContact);
      if (!newContactCacheId) return;

      const resourceCacheId = cache.identify({
        __typename: "ExternalResource",
        _id: newContact.externalResourceId,
      });

      cache.modify({
        id: resourceCacheId,
        fields: {
          contacts: (cachedContacts) => [
            ...cachedContacts,
            { __ref: newContactCacheId },
          ],
        },
      });
    },
  });

export const useMutationUpdateExternalResourceContact = () =>
  useMutation<
    { updateExternalResourceContact: GetExternalResourceContactResponse },
    MutationUpdateExternalResourceContactArgs
  >(UPDATE_EXTERNAL_RESOURCE_CONTACT);

export const useMutationRetireExternalResourceContact = () =>
  useMutation<
    { retireExternalResourceContact: GetExternalResourceContactResponse },
    MutationRetireExternalResourceContactArgs
  >(RETIRE_EXTERNAL_RESOURCE_CONTACT, {
    update: (cache, result) => {
      const retiredContact = result.data?.retireExternalResourceContact.data;
      if (!retiredContact) return;

      cache.evict({ id: cache.identify(retiredContact) });
      cache.gc();
    },
  });
