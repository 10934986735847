import { gql } from "@apollo/client";
import { CompleteActionDataFields } from "../Flow/types";
import { recommendationsFields } from "./types";

export const CREATE_RECOMMENDATION = gql`
  mutation CreateRecommendation($input: CreateRecommendationInput!) {
    createRecommendation(input: $input) {
      success
      message
      data {
        ${recommendationsFields}
      }
    }
  }
`;

export const UPDATE_RECOMMENDATION = gql`
  mutation UpdateRecommendation($input: UpdateRecommendationInput!) {
    updateRecommendation(input: $input) {
      success
      message
      data {
        ${recommendationsFields}
      }
    }
  }
`;

export const DELETE_RECOMMENDATION = gql`
  mutation DeleteRecommendation($recommendationId: ID!, $organizationId: ID!) {
    deleteRecommendation(recommendationId: $recommendationId, organizationId: $organizationId) {
      success
      message
      data {
        ${recommendationsFields}
      }
    }
  }
`;

export const PERFORM_RECOMMENDATION_ACTION = gql`
  mutation PerformRecommendationAction(
    $actionData: ActionDataInput!
    $userId: ID!
    $memberId: ID!
    $recommendationId: ID!
    $generatedForMemberAt: String!
    $scheduleActivityInput: ScheduleActivityInput
    $organizationId: ID!
  ) {
    performRecommendationAction(
      actionData: $actionData
      userId: $userId
      memberId: $memberId
      recommendationId: $recommendationId
      scheduleActivityInput: $scheduleActivityInput
      generatedForMemberAt: $generatedForMemberAt
      organizationId: $organizationId
    ) {
      success
      message
      data {
        ${CompleteActionDataFields}
      }
    }
  }
`;

export const HIDE_RECOMMENDATION_FOR_MEMBER = gql`
  mutation HideRecommendationForMember(
    $memberId: ID!
    $recommendationId: ID!
    $generatedForMemberAt: String!
    $organizationId: ID!
  ) {
    hideRecommendationForMember(
      memberId: $memberId
      recommendationId: $recommendationId
      generatedForMemberAt: $generatedForMemberAt
      organizationId: $organizationId
    ) {
      success
      message
    }
  }
`;
