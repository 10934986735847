import { useMutation, useQuery } from "@apollo/client";
import {
  GetReferralResponse,
  GetReferralsByMemberInput,
  GetReferralsByResourceInput,
  GetReferralsResponse,
  MutationCreateReferralArgs,
  MutationUpdateReferralStatusArgs,
  MutationUpdateReferralStatusPublicArgs,
  QueryReferralArgs,
  QueryReferralsByMemberArgs,
  QueryReferralsByResourceArgs,
  Referral,
} from "../schemaTypes";
import {
  CREATE_REFERRAL,
  UPDATE_REFERRAL_STATUS,
  UPDATE_REFERRAL_STATUS_PUBLIC,
} from "./mutations";
import {
  GET_REFERRAL,
  GET_REFERRALS_BY_MEMBER,
  GET_REFERRALS_BY_RESOURCE,
} from "./queries";
import { MongoSort, usePagination } from "src/hooks/usePagination";
import { GET_ACTIVITIES } from "../Activity/queries";

export const useQueryReferral = (
  organizationId: string,
  referralId: string,
  skip = false,
) =>
  useQuery<{ referral: GetReferralResponse }, QueryReferralArgs>(GET_REFERRAL, {
    variables: {
      input: {
        organizationId,
        referralId,
      },
    },
    skip,
  });

export const usePaginatedQueryReferralsByMember = (
  input: GetReferralsByMemberInput,
  options?: {
    defaultSort?: MongoSort;
  },
) =>
  usePagination<
    QueryReferralsByMemberArgs,
    { response: GetReferralsResponse },
    Referral
  >({ document: GET_REFERRALS_BY_MEMBER, variables: { input }, options });

export const usePaginatedQueryReferralsByResource = (
  input: GetReferralsByResourceInput,
  options?: {
    defaultSort?: MongoSort;
  },
) =>
  usePagination<
    QueryReferralsByResourceArgs,
    { response: GetReferralsResponse },
    Referral
  >({ document: GET_REFERRALS_BY_RESOURCE, variables: { input }, options });

export const useMutationCreateReferral = () =>
  useMutation<
    { createReferral: GetReferralResponse },
    MutationCreateReferralArgs
  >(CREATE_REFERRAL, { refetchQueries: [GET_ACTIVITIES] });

export const useMutationUpdateReferralStatus = () =>
  useMutation<
    { updateReferralStatus: GetReferralResponse },
    MutationUpdateReferralStatusArgs
  >(UPDATE_REFERRAL_STATUS);

export const useMutationUpdateReferralStatusPublic = () =>
  useMutation<
    { updateReferralStatusPublic: GetReferralResponse },
    MutationUpdateReferralStatusPublicArgs
  >(UPDATE_REFERRAL_STATUS_PUBLIC);
