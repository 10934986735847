import { Button, Stack, Text } from "@mantine/core";
import { Plus } from "react-feather";
import {
  EditableItem,
  makeEditableListItem,
  EditableListItem,
} from "src/components";

/**
 * A List Input Component for adding and editing ActionItems for GoalTemplate form
 */

type ActionItemListProps = {
  /**
   * Disables adding/removing and editing list items
   * @default false
   */
  readOnly?: boolean;
  itemsById: Record<string, EditableItem>;
  onChange: (nextItems: Record<string, EditableItem>) => void;
};

export const ActionItemList = ({
  itemsById,
  readOnly = false,
  onChange,
}: ActionItemListProps) => {
  const handleUpdateItem = (
    action: "create" | "update" | "delete" | "commit",
    item: EditableItem,
  ) => {
    const nextItems = { ...itemsById };
    switch (action) {
      case "create":
      case "update":
      case "commit":
        nextItems[item.id] = item;
        break;
      case "delete":
        delete nextItems[item.id];
        break;
    }

    onChange(nextItems);
  };

  const handleAddItem = () => {
    handleUpdateItem("create", makeEditableListItem());
  };

  const items = Object.values(itemsById);
  const hasItems = !!items.length;
  const isEditingItem = items.some((item) => item.state === "editing");

  return (
    <Stack spacing="xs">
      {!hasItems && (
        <Text size="xs" variant="caption">
          No Items Created
        </Text>
      )}

      <Stack spacing={0}>
        {Object.values(itemsById).map((item) =>
          readOnly ? (
            <EditableListItem key={item.id} item={item} editable={false} />
          ) : (
            <EditableListItem
              key={item.id}
              item={item}
              onCommitItemUpdate={handleUpdateItem}
              editable
            />
          ),
        )}
      </Stack>

      {!readOnly && (
        <Button
          size="xs"
          disabled={isEditingItem}
          onClick={handleAddItem}
          leftIcon={<Plus size="16" />}
          style={{ alignSelf: "flex-start" }}
        >
          Add Item
        </Button>
      )}
    </Stack>
  );
};
