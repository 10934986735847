import { SelectItem } from "@mantine/core";
import { CarePathwayStatus } from "src/graphql";

export const CarePathwayStatusMap: Record<CarePathwayStatus, string> = {
  [CarePathwayStatus.Enrolled]: "Enrolled",
  [CarePathwayStatus.CompletedSuccessful]: "Completed - Successful",
  [CarePathwayStatus.CompletedUnsuccessful]: "Completed - Unsuccessful",
};

export const CarePathwayStatusOptions: SelectItem[] = Object.keys(
  CarePathwayStatusMap,
).map((status) => ({
  value: status,
  label: CarePathwayStatusMap[status as CarePathwayStatus],
}));
