import { Stack, Tabs, Text } from "@mantine/core";
import { Goal, GoalStatus } from "src/graphql";

export type GoalStatusTab = "current" | "completed";

export const GoalStatusToTab: Record<GoalStatus, GoalStatusTab> = {
  [GoalStatus.Completed]: "completed",
  [GoalStatus.Resolved]: "completed",
  [GoalStatus.Unresolved]: "current",
  [GoalStatus.Active]: "current",
};

type GoalListProps = {
  goals: Goal[];
  selectedGoalId: string | null;
  selectedTab: GoalStatusTab;
  onSelectGoal: (nextGoalId: string) => void;
  onSelectTab: (nextTab: GoalStatusTab) => void;
  small?: boolean;
};

export const GoalList = ({
  goals,
  selectedGoalId,
  selectedTab,
  onSelectGoal,
  onSelectTab,
  small,
}: GoalListProps) => {
  const goalStatusFilter: Partial<GoalStatus>[] =
    selectedTab === "current"
      ? [GoalStatus.Active, GoalStatus.Unresolved]
      : [GoalStatus.Completed, GoalStatus.Resolved];

  const filteredGoals = goals.filter((goal) =>
    goalStatusFilter.includes(goal.status),
  );

  return (
    <Stack spacing={0} w={230} h="100%" style={{ flexShrink: 0 }}>
      {/* Upper Tabs (Current/Complete Goals Toggle) */}
      <Tabs
        value={selectedTab}
        onTabChange={onSelectTab}
        styles={{
          root: {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Tabs.List grow>
          <Tabs.Tab value="current">Current</Tabs.Tab>
          <Tabs.Tab value="completed">Completed</Tabs.Tab>
        </Tabs.List>
      </Tabs>

      {/* Lower Tabs (List of Goals) */}
      <Tabs
        value={selectedGoalId}
        onTabChange={onSelectGoal}
        orientation="vertical"
        variant="pills"
        color="gray"
        styles={{
          root: {
            minHeight: 0,
            flexGrow: 1,
          },
          tabsList: {
            width: "100%",
            flexWrap: "nowrap",
            gap: 0,
            overflowY: "auto",
            // Total height - (Border + Tab Heading Label)
            maxHeight: small ? "214px" : "314px",
          },
          tab: {
            width: "100%",
            padding: "0px 16px",
          },
          tabLabel: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "34px",
          },
        }}
      >
        <Tabs.List>
          {!filteredGoals.length && (
            <Text fz="sm" color="gray" align="center" py={20}>
              &lt;none configured&gt;
            </Text>
          )}
          {filteredGoals.map(
            (goal) =>
              goalStatusFilter.includes(goal.status) && (
                <Tabs.Tab value={goal._id} key={goal._id}>
                  {goal.name}
                </Tabs.Tab>
              ),
          )}
        </Tabs.List>
      </Tabs>
    </Stack>
  );
};
