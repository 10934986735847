import {
  ActionIcon,
  Badge,
  Group,
  Stack,
  Tooltip,
  TextInput,
  useMantineTheme,
  Checkbox,
} from "@mantine/core";
import * as React from "react";
import { TableColumn } from "react-data-table-component";
import { DownloadCloud, Edit, PhoneOff, Search } from "react-feather";
import { useHistory } from "react-router-dom";

import {
  Member,
  MemberStatus,
  ProviderRecommendationStatus,
  usePaginatedQueryMembers,
  useQueryUsers,
  ProviderRecommendation,
  useLazyQuerySignedDocument,
} from "src/graphql";
import useTwilio from "src/hooks/useTwilio";
import { getFullName } from "src/utils";
import { formatPhoneNumber } from "src/utils/phone";
import { SectionHeader, SectionSubHeader } from "../display";
import {
  DataTableComponent,
  OverflowTooltipCell,
  TableContainer,
  TableHeader,
} from "../table";
import { WidgetTab } from "../twilio/widget/tabs";
import { MemberCloseButton } from "../member-close-button";
import { DontTranslate } from "src/libs/localization/components/DontTranslate";
import {
  EnrollmentStatusToColor,
  EnrollmentStatusToLabel,
  RecommendationStatusToColor,
  RecommendationStatusToLabel,
} from "./util";
import toast from "src/libs/toast";
import { useAuthContext } from "src/hooks";
import { FileModal } from "./FileModal";

type MembersTableProps = {
  organizationIds: string[];
  dense?: boolean;
  fixedHeader?: boolean;
  className?: string;
  noSelect?: boolean;
  noFullName?: boolean;
  noFirstName?: boolean;
  noLastName?: boolean;
  noOrganization?: boolean;
  noDOB?: boolean;
  noMedicareStatus?: boolean;
  noMedicaidStatus?: boolean;
  noEHRType?: boolean;
  noPrimaryInsurance?: boolean;
  noSecondaryInsurance?: boolean;
  noRecommendationStatus?: boolean;
  noNotes?: boolean;
  noBatchID?: boolean;
  noFile?: boolean;
  noEmail?: boolean;
  noPhone?: boolean;
  noGroups?: boolean;
  showAssignedTo?: boolean;
  showEdit?: boolean;
  showDelete?: boolean;
  excludeInactive?: boolean;
  showOnlyRecommendationRequired?: boolean;
  checkedMembers?: Record<string, boolean>;
  setCheckedMembers?: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  onMemberSelect?: (member: Member) => void;
  actionButtons?: React.ReactNode[];
  additionalColumns?: TableColumn<Member>[];

  userId?: string;
  unassigned?: boolean;
  selectedGroups?: string[];
  refetchId?: string;
  persistTableHead?: boolean;
  paginationSize?: number;
  isPaginationDisabled?: boolean;
  excludeSigned?: boolean;
  recommendationStatuses?: ProviderRecommendationStatus[];
};

const MembersTable = ({
  organizationIds,

  dense = false,
  fixedHeader,
  className,
  noSelect,
  noFullName,
  noFirstName,
  noLastName,
  noOrganization,
  noDOB,
  noMedicareStatus,
  noMedicaidStatus,
  noEHRType,
  noPrimaryInsurance,
  noSecondaryInsurance,
  noRecommendationStatus,
  noNotes,
  noBatchID,
  noFile,
  noEmail,
  noPhone,
  noGroups,
  showAssignedTo,
  showDelete,
  showEdit,
  checkedMembers,
  setCheckedMembers,
  excludeInactive = true,
  showOnlyRecommendationRequired = false,
  onMemberSelect,
  actionButtons,
  additionalColumns = [],

  userId,
  unassigned,
  selectedGroups,
  refetchId,
  persistTableHead = false,
  paginationSize = 10,
  isPaginationDisabled = false,
  excludeSigned = false,
  recommendationStatuses = [],
}: MembersTableProps) => {
  const { organizations } = useAuthContext();
  const organizationIdNameMap = Object.fromEntries(
    organizations.map((o) => [o._id, o.title]),
  );

  const refetchIdRef = React.useRef<string | undefined>();
  // State to track loading status of each row
  const [loadingRow, setLoadingRow] = React.useState<string>("");
  const [isFileModalOpen, setIsFileModalOpen] = React.useState(false);
  const [selectedMemberRecommendations, setSelectedMemberRecommendations] =
    React.useState<ProviderRecommendation[] | null>(null);

  const history = useHistory();
  const theme = useMantineTheme();
  const [lazyQuerySignedDocument, { loading: signedDocumentLoading }] =
    useLazyQuerySignedDocument();
  // NOTE: Temporary solution to no assigned users in members object
  const organizationUsersQuery = useQueryUsers({
    organizationId: organizationIds[0],
  });
  const organizationUsers = organizationUsersQuery.data?.users.data || [];

  // Other member helpers
  const handleEditMember = (memberId: string) =>
    history.push(`/members/${memberId}/edit`);

  const openFileModal = (recommendations: ProviderRecommendation[]) => {
    setSelectedMemberRecommendations(recommendations);
    setIsFileModalOpen(true);
  };

  // Close modal
  const closeFileModal = () => {
    setIsFileModalOpen(false);
    setSelectedMemberRecommendations(null);
  };

  const onFileClick = async (
    memberId: string,
    organizationIds: string[],
    batchId: string,
    signatureRequestId?: string,
  ) => {
    try {
      setLoadingRow(memberId);
      const url = await lazyQuerySignedDocument({
        variables: {
          memberId: memberId,
          organizationIds: organizationIds,
          providerRecommendationBatchId: batchId,
          signatureRequestId: signatureRequestId,
        },
      }).then((res) => res.data?.signedDocument?.data?.url);
      setLoadingRow("");
      if (!url) {
        throw new Error(
          "Sorry, we could not get file URL from server. Try again and contact Pear Suite Support if problem persists.",
        );
      }
      window.open(url, "_blank");
    } catch (error) {
      setLoadingRow("");
      const defaultMessage =
        "Sorry, we could not get file URL from server. Try again and contact Pear Suite Support if problem persists.";

      toast.error(
        error instanceof Error
          ? (error.message ?? defaultMessage)
          : defaultMessage,
      );
    }
  };

  const handleSelectMember =
    onMemberSelect ||
    ((member: Member) => history.push(`/members/${member._id}`));

  // Member table view
  const { dataTableProperties, setSearchTerm, refetch } =
    usePaginatedQueryMembers({
      organizations: organizationIds,
      user: userId,
      pageSize: paginationSize,
      excludeInactive,
      showOnlyRecommendationRequired,
      unassigned,
      groups: selectedGroups,
      recommendationStatuses,
      isPaginationDisabled,
    });

  const { widget } = useTwilio();

  React.useEffect(() => {
    if (refetchIdRef.current === refetchId) {
      return;
    }

    refetchIdRef.current = refetchId;
    refetch();
  }, [refetch, refetchId, recommendationStatuses]);

  // Table columns
  const columns: TableColumn<Member>[] = [
    {
      omit: noSelect,
      grow: 0,
      minWidth: "60px",
      center: true,
      compact: true,
      name: (
        <Checkbox
          onChange={(e) => {
            if (setCheckedMembers) {
              setCheckedMembers(
                dataTableProperties.data.reduce(
                  (acc, member) => {
                    const recentRecommendation =
                      getMostRecentRecommendation(member);
                    if (
                      recentRecommendation &&
                      recentRecommendation.status !==
                        ProviderRecommendationStatus.Signed
                    ) {
                      return {
                        ...acc,
                        [member._id]: e.target.checked,
                      };
                    }
                    return acc;
                  },
                  {} as Record<string, boolean>,
                ),
              );
            }
          }}
        />
      ),
      cell: (member: Member) => (
        <Checkbox
          checked={checkedMembers?.[member._id] ?? false}
          disabled={
            member.providerRecommendations?.at(0)?.status ===
            ProviderRecommendationStatus.Signed
          }
          onChange={(e) => {
            if (setCheckedMembers) {
              setCheckedMembers({
                ...checkedMembers,
                [member._id]: e.target.checked,
              });
            }
          }}
        />
      ),
    },
    {
      omit: noFullName,
      name: "Name",
      sortField: "name",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>{getFullName(member)}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noFirstName,
      name: "First Name",
      sortField: "firstName",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>{member.firstName}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noLastName,
      name: "Last Name",
      sortField: "lastName",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>{member.lastName}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noOrganization,
      name: "Org",
      sortField: "organization",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <Stack spacing="5px" py="5px" w="100%">
            {member.contactInfo.organizations?.map((organization, idx) =>
              organizationIdNameMap[organization] ? (
                <Badge size="xs" key={organization + idx} fullWidth>
                  <DontTranslate>
                    {organizationIdNameMap[organization]}
                  </DontTranslate>
                </Badge>
              ) : null,
            )}
          </Stack>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noDOB,
      name: "DOB",
      sortField: "dob",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>{member?.dob ?? ""}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noMedicareStatus,
      name: "Medicare Status",
      sortField: "medicareStatus",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          {member.medicareStatus && (
            <Badge
              size="sm"
              color={EnrollmentStatusToColor[member.medicareStatus]}
              style={{ cursor: "default" }}
            >
              {EnrollmentStatusToLabel[member.medicareStatus]}
            </Badge>
          )}
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noMedicaidStatus,
      name: "Medicaid Status",
      sortField: "medicaidStatus",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          {member.medicaidStatus && (
            <Badge
              size="sm"
              color={EnrollmentStatusToColor[member.medicaidStatus]}
              style={{ cursor: "default" }}
            >
              {EnrollmentStatusToLabel[member.medicaidStatus]}
            </Badge>
          )}
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noEHRType,
      name: "EHR Type",
      sortField: "ehrIDType",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>{member?.ehrIDType ?? ""}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noPrimaryInsurance,
      name: "Primary Insurance",
      sortField: "primaryInsuranceCompany",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <Stack spacing="5px" py="5px" w="100%">
            {member.insuranceDetails?.map((detail) => (
              <Badge size="xs" key={detail._id} fullWidth>
                <DontTranslate>
                  {detail.primaryInsuranceCompany?.insurance ?? ""}
                </DontTranslate>
              </Badge>
            ))}
          </Stack>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noSecondaryInsurance,
      name: "Secondary Insurance",
      sortField: "secondaryInsuranceCompany",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <Stack spacing="5px" py="5px" w="100%">
            {member.insuranceDetails?.map((detail) => (
              <Badge size="xs" key={detail._id} fullWidth>
                <DontTranslate>
                  {detail.secondaryInsuranceCompany?.insurance ?? ""}
                </DontTranslate>
              </Badge>
            ))}
          </Stack>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noRecommendationStatus,
      name: "Recommendation Status",
      sortField: "providerRecommendation.status",
      sortable: true,
      cell: (member: Member) => {
        const recentRecommendation = getMostRecentRecommendation(member);
        return (
          <OverflowTooltipCell
            onClick={() => {
              handleSelectMember(member);
            }}
          >
            {recentRecommendation && recentRecommendation.status && (
              <Badge
                size="sm"
                color={RecommendationStatusToColor[recentRecommendation.status]}
                style={{ cursor: "default" }}
              >
                {RecommendationStatusToLabel[recentRecommendation.status]}
              </Badge>
            )}
          </OverflowTooltipCell>
        );
      },
    },
    {
      omit: noNotes,
      name: "Notes",
      cell: (member: Member) => {
        const recentRecommendation = getMostRecentRecommendation(member);
        return (
          <OverflowTooltipCell
            onClick={() => {
              handleSelectMember(member);
            }}
          >
            {recentRecommendation ? recentRecommendation.notes : ""}
          </OverflowTooltipCell>
        );
      },
    },
    {
      omit: noBatchID,
      name: "Batch ID",
      cell: (member: Member) => {
        const recentRecommendation = getMostRecentRecommendation(member);
        return (
          <OverflowTooltipCell
            onClick={() => {
              handleSelectMember(member);
            }}
          >
            <DontTranslate>
              {recentRecommendation?.batch?._id || ""}
            </DontTranslate>
          </OverflowTooltipCell>
        );
      },
    },
    {
      omit: noFile,
      name: "File",
      cell: (member: Member) => {
        const recommendations = member.providerRecommendations || [];

        // Check if there are multiple recommendations
        if (recommendations.length > 1) {
          return (
            <ActionIcon onClick={() => openFileModal(recommendations)}>
              <DownloadCloud size="16px" color="var(--color-pear-green)" />
            </ActionIcon>
          );
        }

        // If only one recommendation exists, proceed with single download logic
        if (recommendations.length === 1) {
          const { batch } = recommendations[0];
          const signatureRequestId = batch?.signatureRequestId;
          const providerRecommendationBatchId = batch?._id;
          const status = recommendations[0].status;

          return (
            <>
              {providerRecommendationBatchId &&
                status === ProviderRecommendationStatus.Signed && (
                  <ActionIcon
                    onClick={() =>
                      onFileClick(
                        member._id,
                        organizationIds,
                        providerRecommendationBatchId,
                        signatureRequestId,
                      )
                    }
                    loading={signedDocumentLoading && loadingRow === member._id}
                  >
                    <DownloadCloud
                      size="16px"
                      color="var(--color-pear-green)"
                    />
                  </ActionIcon>
                )}
            </>
          );
        }

        return null; // If no recommendations, display nothing
      },
    },
    {
      omit: noEmail,
      name: "Email",
      sortField: "contactInfo.email",
      sortable: true,
      cell: (member: Member) => (
        <OverflowTooltipCell
          onClick={() => {
            handleSelectMember(member);
          }}
        >
          <DontTranslate>{member?.contactInfo.email ?? ""}</DontTranslate>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noPhone,
      name: "Phone",
      sortField: "contactInfo.phone",
      sortable: true,
      minWidth: "120px",
      center: true,
      cell: (member) => (
        <OverflowTooltipCell
          onClick={() => {
            widget.setTab(WidgetTab.Phone);
            widget.setPhoneNumber(member.contactInfo.phone);
            widget.expand(true);
          }}
          disabled={member.status !== MemberStatus.Active}
        >
          <Group spacing={0}>
            {formatPhoneNumber(member.contactInfo.phone)}
            {member.contactInfo.doNotCall && (
              <Tooltip label="Do Not Call">
                <PhoneOff size={13} color="red" style={{ marginLeft: 3 }} />
              </Tooltip>
            )}
          </Group>
        </OverflowTooltipCell>
      ),
    },
    {
      omit: noGroups,
      name: "Groups",
      sortable: false,
      grow: 1,
      center: true,
      minWidth: "200px",
      cell: ({ _id, contactInfo: { groups } }) => (
        <Stack spacing="5px" py="5px" w="100%">
          {groups?.map(({ title, _id: groupId }) => (
            <Badge size="xs" key={_id + groupId} fullWidth>
              {title}
            </Badge>
          ))}
        </Stack>
      ),
    },
    {
      name: "Assigned to",
      sortable: false,
      omit: showAssignedTo !== true,
      cell: ({ assignedUserIds = [] }) => (
        <DontTranslate>
          {assignedUserIds
            ?.map((id) => organizationUsers.find((u) => u._id === id)?.name)
            .filter((name): name is string => !!name)
            .join(", \n")}
        </DontTranslate>
      ),
    },
    ...additionalColumns,

    {
      name: "Edit",
      grow: 0,
      minWidth: "60px",
      center: true,
      compact: true,
      omit: showEdit !== true,
      cell: (member) => (
        <ActionIcon
          onClick={() => handleEditMember(member._id)}
          disabled={member.status !== MemberStatus.Active}
        >
          <Edit
            size="16"
            color={
              member.status === MemberStatus.Active
                ? theme.colors.blue[5]
                : undefined
            }
          />
        </ActionIcon>
      ),
    },
    {
      name: "Close",
      grow: 0,
      minWidth: "80px",
      center: true,
      omit: showDelete !== true,
      cell: (member) => <MemberCloseButton member={member} onClose={refetch} />,
    },
  ];

  const getMostRecentRecommendation = (member: Member) => {
    if (
      !member.providerRecommendations ||
      member.providerRecommendations.length === 0
    ) {
      return null;
    }

    // Sort recommendations by createdAt in descending order to get the most recent
    const sortedRecommendations = [...member.providerRecommendations].sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    // Return the most recent recommendation
    return sortedRecommendations[0];
  };
  // NOTE: I don't think this is the best way to handle this
  // This creates two filter patterns, one on the backend and the other
  // on the frontend. This is not ideal for code maintainability.
  const filteredData = excludeSigned
    ? dataTableProperties.data.filter((row) => {
        const recentRecommendation = getMostRecentRecommendation(row);
        return recentRecommendation;
      })
    : dataTableProperties.data;

  return (
    <TableContainer>
      {dense === false && (
        <TableHeader
          placeholder="By Name, Email or Phone"
          setFilterText={(text) => setSearchTerm(text)}
          actionButtons={actionButtons}
          searchColumn={
            actionButtons === undefined || actionButtons.length === 0
              ? 12
              : undefined
          }
        />
      )}

      {dense === true && (
        <>
          <SectionHeader noGap>
            {unassigned && "Unassigned "}Members
          </SectionHeader>
          <SectionSubHeader noGap>
            <Group position="apart">
              <TextInput
                style={{ width: 200 }}
                icon={<Search height={16} />}
                size="xs"
                placeholder="By Name, Email or Phone..."
                onChange={(event) => setSearchTerm(event.target.value)}
              />

              {actionButtons && actionButtons.length >= 0 && (
                <Group>{actionButtons}</Group>
              )}
            </Group>
          </SectionSubHeader>
        </>
      )}

      <DataTableComponent
        persistTableHead={persistTableHead}
        pagination={!isPaginationDisabled}
        paginationPerPage={paginationSize}
        noCardWrapper
        dense={dense}
        noRowsPerPage
        fixedHeader={fixedHeader}
        className={className}
        columns={columns}
        highlightOnHover={true}
        pointerOnHover={true}
        onRowClicked={handleSelectMember}
        noDataText={"No assigned members found"}
        conditionalRowStyles={[
          {
            when: (row) => row.status !== MemberStatus.Active,
            style: {
              color: theme.colors.black[6],
              backgroundColor: theme.colors.gray[1],
            },
          },
        ]}
        {...dataTableProperties}
        data={filteredData}
      />
      {/* Render the modal conditionally when `isFileModalOpen` is true */}
      {isFileModalOpen && selectedMemberRecommendations && (
        <FileModal
          recommendations={selectedMemberRecommendations}
          organizationIds={organizationIds}
          memberId={selectedMemberRecommendations[0]?.memberId}
          onClose={closeFileModal}
          onFileClick={onFileClick}
        />
      )}
    </TableContainer>
  );
};

export default MembersTable;
