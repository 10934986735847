import { ApolloClient, ApolloLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { createClient } from "graphql-ws";
import log from "loglevel";
import { v4 as uuid } from "uuid";
import { createInfiniteQueryFieldPolicy } from "src/utils/infinite-query";
import { KEEPALIVE_PING } from "./ClientSession/mutations";
import { GetTokenSilentlyOptions } from "@auth0/auth0-react";

const httpURI = process.env.REACT_APP_GQL_HTTP_URI;
const socketURI = process.env.REACT_APP_GQL_SOCKET_URI;

export const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        textMessages: createInfiniteQueryFieldPolicy([
          "input",
          ["organizationId", "participantPhone"],
        ]),
      },
    },

    ActivityTask: {
      keyFields: false,
    },

    ActivityTaskTemplate: {
      keyFields: false,
    },
    Member: {
      fields: {
        overriddenProcedureCodeLimits: {
          merge(existing = [], incoming = []) {
            if (incoming.length === 0) {
              return existing;
            }
            return incoming;
          },
        },
        insuranceDetails: {
          merge(existing = [], incoming = []) {
            // If incoming is empty, keep existing data
            if (incoming.length === 0) {
              return existing;
            }
            // Otherwise use the incoming data
            return incoming;
          },
        },
      },
    },
  },
});

// Function to clean up nested type names
const cleanNestedTypeName = (
  obj: Record<string | number, unknown>,
): Record<string, unknown> => {
  let res: Record<string, unknown> = {};

  if (Array.isArray(obj) || (typeof obj === "object" && obj !== null)) {
    for (const key of Object.keys(obj)) {
      if (key === "__typename") {
        continue;
      }

      const value = obj[key];

      // files/blobs
      if (value instanceof File || value instanceof Blob) {
        res[key] = value;
        continue;
      }

      // array case
      if (Array.isArray(value)) {
        res[key] = value.map((v) =>
          cleanNestedTypeName(v as Record<string | number, unknown>),
        );
        continue;
      }

      // object case
      if (typeof value === "object" && value !== null) {
        res[key] = cleanNestedTypeName(
          value as Record<string | number, unknown>,
        );
        continue;
      }

      // scalar (base) case
      res[key] = value;
      continue;
    }
  } else {
    res = obj;
  }

  return res;
};

// Updated function to create Apollo Client and handle token authorization
export const clientWithAuth = (
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions,
  ) => Promise<string>,
  audience?: string,
) => {
  const httpAuthLink = setContext(async (_, { headers }) => {
    let token: string | undefined;
    try {
      token = await getAccessTokenSilently({
        authorizationParams: {
          audience: audience ?? process.env.REACT_APP_AUTH0_AUDIENCE,
        },
      });
      if (!token) throw new Error("Token not retrieved from Auth0.");
    } catch (error) {
      log.error("Error fetching Auth0 token:", error);
    }

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const cleanTypeNameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = cleanNestedTypeName(operation.variables);
    }
    return forward(operation);
  });

  const httpLink = createUploadLink({
    uri: httpURI,
    credentials: "include",
    fetchOptions: {
      mode: "cors",
    },
    headers: {
      "Apollo-Require-Preflight": "true",
    },
  });

  let clientId: string;

  const socketLink = new GraphQLWsLink(
    createClient({
      url: socketURI,
      lazy: true,
      connectionParams: async () => {
        clientId = uuid();
        let token: string | undefined;
        try {
          token = await getAccessTokenSilently({
            authorizationParams: {
              audience: audience ?? process.env.REACT_APP_AUTH0_AUDIENCE,
            },
          });
        } catch (error) {
          log.error("Error fetching Auth0 token:", error);
        }
        return { token, clientId };
      },
      keepAlive: 1000 * 15,
      on: {
        pong: async () => {
          client.mutate({
            mutation: KEEPALIVE_PING,
            variables: { clientId },
          });
        },
      },
    }),
  );

  const retryLink = new RetryLink();

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors || networkError) {
    }
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        log.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    if (networkError) log.error(`[Network error]: ${networkError}`);
  });

  const splitOperationLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    socketLink,
    httpLink as unknown as ApolloLink,
  );

  const client = new ApolloClient({
    link: ApolloLink.from([
      retryLink,
      httpAuthLink,
      cleanTypeNameLink,
      errorLink,
      splitOperationLink,
    ]),
    cache: apolloCache,
  });

  const cleanupSocketConnection = () => socketLink.client.terminate();

  return { client, cleanupSocketConnection };
};
