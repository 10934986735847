import styled from "@emotion/styled";

export * from "./DataTableComponent";
export * from "./Header";
export * from "./TemplatesTableHeader";
export * from "./withOmittedColumns";

export const TableContainer = styled("div")`
  border: 1px solid ${({ theme }) => theme.colors.gray[2]};
`;
