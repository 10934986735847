import { useQuery } from "@apollo/client";

import { GetScheduleResponse, QueryScheduleArgs } from "../schemaTypes";
import {
  GET_SCHEDULE,
  GET_SCHEDULE_ONLY_ACTIVITIES,
  GET_SCHEDULE_WITH_PAST_DUE,
} from "./queries";

export const useQueryGetSchedule = (
  organizationId: string,
  userId: string,
  of: QueryScheduleArgs["input"]["of"],
  noCache = false,
) =>
  useQuery<{ schedule: GetScheduleResponse }, QueryScheduleArgs>(GET_SCHEDULE, {
    variables: {
      input: {
        organizationId,
        userId,
        of,
      },
    },
    fetchPolicy: noCache ? "no-cache" : "cache-first",
  });

export const useQueryGetScheduleOnlyActivities = (
  organizationId: string,
  userId: string,
  of: QueryScheduleArgs["input"]["of"],
  noCache = false,
) =>
  useQuery<{ schedule: GetScheduleResponse }, QueryScheduleArgs>(
    GET_SCHEDULE_ONLY_ACTIVITIES,
    {
      variables: {
        input: {
          organizationId,
          userId,
          of,
        },
      },
      fetchPolicy: noCache ? "no-cache" : "cache-first",
    },
  );

export const useQueryGetScheduleWithPastDue = (
  organizationId: string,
  userId: string,
  of: QueryScheduleArgs["input"]["of"],
  noCache = false,
) =>
  useQuery<{ schedule: GetScheduleResponse }, QueryScheduleArgs>(
    GET_SCHEDULE_WITH_PAST_DUE,
    {
      variables: {
        input: {
          organizationId,
          userId,
          of,
        },
      },
      fetchPolicy: noCache ? "no-cache" : "cache-first",
    },
  );
