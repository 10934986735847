import { createContext, useContext } from "react";

// context and hook for accessing callbacks from node components
type FlowSnapshotContextType = {
  currentStepId: string;
  selectedEditNodeId: string;
  currentStepHasChanged: boolean;
  onUpdateAnswer: (parentNodeId: string, nextAnswerJSON: string) => void;
  onRequestEditStep: (nodeId: string) => void;
  onRequestCommitChanges: () => void;
  onDiscardChanges: () => void;
};

export const FlowSnapshotContext = createContext<FlowSnapshotContextType>(
  {} as FlowSnapshotContextType,
);

export const useFlowSnapshotContext = () => useContext(FlowSnapshotContext);
