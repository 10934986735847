import * as Yup from "yup";
import toast from "src/libs/toast";
import {
  ActionType,
  GoalStatus,
  GoalTemplate,
  UpdateGoalData,
} from "src/graphql";
import { SelectOption } from "src/types";
import { ActionInfo } from "../../../util";
import { ySelectOptionSchema } from "src/utils";
import { Formik, FormikProps } from "formik";
import { FormikSelect } from "src/components";
import { GoalTemplatePreview } from "../GoalTemplatePreview";
import { useRef } from "react";
import { SubFormCommonProps } from "../AddActionModal";
import log from "loglevel";
import { Stack } from "@mantine/core";
import { Disclaimer } from "../shared";

type UpdateGoalActionFormProps = SubFormCommonProps & {
  goalTemplateOptions: SelectOption<string>[];
  goalTemplatesById: Record<string, GoalTemplate>;
};

export const UpdateGoalActionForm = ({
  goalTemplateOptions,
  goalTemplatesById,
  innerRef,
  node,
  onCreateAction,
  onDirtyStateChange,
  onValidStateChange,
}: UpdateGoalActionFormProps) => {
  const dirtyStateRef = useRef(false);
  const validStateRef = useRef(false);

  const initialValues: WrappedFormValues = {
    goalTemplateId: null,
    status: {
      label: "Complete Goal",
      value: GoalStatus.Completed,
    },
  };

  return (
    <Formik
      innerRef={innerRef as React.RefObject<FormikProps<WrappedFormValues>>}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        if (!node) return; // nothing renders without a node selected
        try {
          setSubmitting(true);
          const actionInfo = parseFormValues(formValues);
          onCreateAction(node, actionInfo);
          // eslint-disable-next-line
        } catch (err: any) {
          log.error(err.message);
          setSubmitting(false);
          toast.error("Failed to add action; please try again");
        }
      }}
    >
      {({ values, dirty, isValid }) => {
        if (onDirtyStateChange && dirty !== dirtyStateRef.current) {
          dirtyStateRef.current = dirty;
          requestAnimationFrame(() => onDirtyStateChange(dirty));
        }
        if (onValidStateChange && isValid !== validStateRef.current) {
          validStateRef.current = isValid;
          requestAnimationFrame(() => onValidStateChange(isValid));
        }

        return (
          <Stack mt="0.75em" spacing="0.5em">
            <FormikSelect
              name="goalTemplateId"
              label="Goal"
              options={goalTemplateOptions}
            />

            {values.goalTemplateId && (
              <>
                <Disclaimer>
                  Note: A <strong>Complete Goal</strong> type action will
                  complete if the user this Flow is assigned to has an existing
                  goal created from the selected template. Otherwise, the Flow
                  will continue transparently.
                </Disclaimer>
                <div>
                  <GoalTemplatePreview
                    template={goalTemplatesById[values.goalTemplateId.value]}
                  />
                </div>
              </>
            )}
          </Stack>
        );
      }}
    </Formik>
  );
};

type WrappedFormValues = Omit<UpdateGoalData, "goalTemplateId" | "status"> & {
  goalTemplateId: SelectOption<string> | null;
  status: SelectOption<typeof GoalStatus.Completed>; // limited to completion for now, not rendered
};

const parseFormValues = (formValues: WrappedFormValues): ActionInfo => {
  if (!formValues.goalTemplateId) {
    throw new Error("Missing goal template ID - check schema validator");
  }
  if (!formValues.status) {
    throw new Error("Missing target goal status - check schema validator");
  }

  return {
    actionType: ActionType.UpdateGoalData,
    actionData: {
      goalTemplateId: formValues.goalTemplateId.value,
      status: formValues.status.value,
    },
  };
};

const ValidationSchema = Yup.object({
  goalTemplateId: ySelectOptionSchema(Yup.string().required()).required(),
  status: ySelectOptionSchema(
    Yup.mixed<GoalStatus>().oneOf(Object.values(GoalStatus)).required(),
  ).required(),
});
