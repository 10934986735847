import { useState } from "react";
import { Button } from "@mantine/core";
import { makeDefaultStagePositions, useFlowBuilderData } from "src/components";
import { ActionInfo, PearNodesData } from "src/components/flow-graph/util";
import { ActionData, Permission } from "src/graphql";
import { Node } from "react-flow-renderer";
import { Plus } from "react-feather";
import { FlowBuilderContext } from "src/components/flow-graph/template-editor/context";
import { AddActionModal } from "src/components/flow-graph/template-editor/add-action-modal";
import { createDummyNode, RecommendationExtended } from "../utils";
import { useAuthContext } from "src/hooks";
import { FlowActionItem } from "src/components/flow-graph/flow-nodes/FlowActionItem";
import {
  extractIdFromActionData,
  getActionDataInput,
  getActionInfos,
} from "src/utils/actionData";
import { v4 } from "uuid";

type RecommendationActionsProps = {
  values: RecommendationExtended;
  setFieldValue: (
    field: string,
    value: ActionData[],
    shouldValidate?: boolean,
  ) => void;
  readOnly: boolean;
};

export const RecommendationActions = ({
  values,
  setFieldValue,
  readOnly,
}: RecommendationActionsProps) => {
  const { hasAnyPermission } = useAuthContext();
  const { isLoading, parsedData } = useFlowBuilderData();
  const [isActionModalOpen, toggleActionModal] = useState<boolean>(false);

  const handleAddAction = (
    _node: Node<PearNodesData>,
    actionInfo: ActionInfo,
  ) => {
    const nextActions = [...(values.actions ?? [])];

    actionInfo.actionData.id = v4();

    const actionData = getActionDataInput(actionInfo);
    nextActions.push(actionData);

    setFieldValue("actions", nextActions);
  };

  const handleDeleteAction = (actionInfo: ActionInfo) => {
    const nextActions = [...(values.actions ?? [])];

    setFieldValue(
      "actions",
      nextActions.filter((actionData) => {
        const id = extractIdFromActionData(actionData);

        return id !== actionInfo.actionData.id;
      }),
    );
  };

  const actionInfos = () => {
    const actionInfos: ActionInfo[] = [];

    values.actions?.forEach((actionInfo) => {
      actionInfos.push(...getActionInfos(actionInfo));
    });

    return actionInfos;
  };

  if (isLoading) return null;

  if (!parsedData) {
    return null;
  }

  return (
    <FlowBuilderContext.Provider
      value={{
        builderData: parsedData,
        readOnly: false,
        nodesSupportActions: true,
        grabbedStage: null,
        stagePositions: makeDefaultStagePositions(0),
        onDeleteAction: () => {},
        onDeleteStepNode: () => {},
        onToggleMultiOutput: () => {},
        onToggleActionModal: () => {},
      }}
    >
      {hasAnyPermission(Permission.RecommendationWriteAccess) && (
        <div style={{ marginBottom: "1em" }}>
          <Button
            disabled={readOnly}
            onClick={() => toggleActionModal(true)}
            leftIcon={<Plus size="18" />}
          >
            Add Action
          </Button>
        </div>
      )}
      <div style={{ fontSize: 12 }}>
        {actionInfos().map((actionInfo, i) => {
          return (
            <FlowActionItem
              actionInfo={actionInfo}
              key={actionInfo.actionData.id}
              onDeleteAction={(actionInfo) => handleDeleteAction(actionInfo)}
              isLastAction={i === actionInfos.length - 1}
            />
          );
        })}
      </div>
      <AddActionModal
        isOpen={isActionModalOpen}
        node={createDummyNode()}
        onRequestClose={() => toggleActionModal(false)}
        onCreateAction={handleAddAction}
      />
    </FlowBuilderContext.Provider>
  );
};
