import { gql } from "@apollo/client";
import { voicemailFields } from "./types";

export const GET_VOICEMAILS = gql`
  query GetVoicemails($input: GetVoicemailsInput!, $pagination: PaginationInput!) {
    response: voicemails(input: $input, pagination: $pagination) {
      success
      message
      data {
        page
        total
        unlistened
        data {
          ${voicemailFields}
        }
      }
    }
  }
`;

// signedVoicemailRecordingUrl
export const GET_SIGNED_VOICEMAIL_RECORDING_URL = gql`
  query GetSignedVoicemailRecordingUrl($call: ID!, $organizationId: ID!) {
    signedVoicemailRecordingUrl(call: $call, organizationId: $organizationId) {
      success
      data
    }
  }
`;
