import { Button, Card, Checkbox, Group, Stack, Tooltip } from "@mantine/core";
import { FieldArray, Form, Formik } from "formik";
import { MouseEventHandler, useMemo, useState } from "react";
import {
  FormikInput,
  FormikMultiSelect,
  FormikTextarea,
  LoaderComponent,
  StyledLabel,
} from "src/components";
import { DirtyLeavePrompt } from "src/components/form/DirtyLeavePrompt";
import { FormikCheckbox } from "src/components/input/FormikCheckbox";
import { FormikInputWrapper } from "src/components/input/FormikInputWrapper";
import { FormikSelect } from "src/components/input/FormikSelect";
import { FormikTextEditor } from "src/components/input/FormikTextEditor/FormikTextEditor";
import { WarnLockingReferencesModal } from "src/components/warn-locking-references-modal";
import {
  ActivityType,
  FlowType,
  Integration,
  LockingReference,
  Member,
  useQueryMember,
} from "src/graphql";
import { useAuthContext } from "src/hooks";
import { FormContext, FormContextType, SelectOption } from "src/types";
import { EmailTemplateModal } from "../templates-email/EmailTemplateModal";
import {
  ActivityTemplateFormValues,
  ActivityTemplateSchema,
  createBaseActivityTaskTemplate,
  createBaseActivityTemplate,
  TaskTemplateOptions,
  UnwrappedActivityTemplateFormValues,
  useTaskTemplateOptions,
  useUpsertTemplate,
  wrapFormValues,
  wrapTaskFormValues,
} from "./utils";
import { placeOfServiceCodeOptions } from "../organization/PlaceOfServiceCodesSection";
import { wrapSelectOption } from "src/utils";

export const flowBasedActivityTypes = Object.values(FlowType);

export const isFlowBasedActivityType = (
  value: ActivityType,
): value is FlowType => flowBasedActivityTypes.includes(value as FlowType);

export type ActivityTemplateFormParams = Readonly<{
  id?: string;
  disabled?: boolean;
  references?: LockingReference[];
  defaultValues?: UnwrappedActivityTemplateFormValues;
  context: FormContextType;
}>;

export function ActivityTemplateForm({
  id,
  disabled,
  references = [],
  defaultValues = createBaseActivityTemplate(),
  context,
}: ActivityTemplateFormParams) {
  const { selectedOrganization } = useAuthContext();

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Mutation
  const [upsertTemplate, response] = useUpsertTemplate({
    onSuccessPreNavigation: () => setHasUnsavedChanges(false),
  });

  // Locking references warning modal
  const [warningModalDetails, setWarningModalDetails] =
    useState<ActivityTemplateFormValues | null>(null);

  const [showEmailTemplateModal, setShowEmailTemplateModal] = useState(false);

  const activeReferences = useMemo(
    () =>
      references.length > 0 ? references.filter((ref) => ref.isActive) : [],
    [references],
  );

  // Template options for form
  const taskTemplateOptions = useTaskTemplateOptions();

  const onLockingReferenceConfirm = () => {
    if (warningModalDetails) {
      setWarningModalDetails(null);
      upsertTemplate(id, warningModalDetails);
    }
  };

  const onSubmit = (values: ActivityTemplateFormValues) => {
    if (activeReferences.length > 0) setWarningModalDetails(values);
    else upsertTemplate(id, values);
  };

  const showAppointmentFields =
    selectedOrganization.activatedIntegrations?.includes(
      Integration.RedoxAppointmentScheduling,
    );

  if (taskTemplateOptions.loading) return <LoaderComponent />;

  return (
    <>
      <Formik
        validationSchema={ActivityTemplateSchema}
        initialValues={wrapFormValues(defaultValues, taskTemplateOptions.all)}
        onSubmit={onSubmit}
      >
        {({ values, isValid, setFieldValue, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Stack spacing="xs">
                <FormikInput
                  name="title"
                  type="text"
                  label="Title"
                  placeholder="Title"
                  required
                />

                <FormikTextarea
                  name="description"
                  label="Description"
                  placeholder="Description"
                />

                {selectedOrganization.billingEnabled && (
                  <FormikInputWrapper name="billable">
                    <Checkbox
                      my="sm"
                      label="Enable billing on template"
                      checked={values.billable}
                      onChange={({ target }) =>
                        setFieldValue("billable", target.checked)
                      }
                    />
                  </FormikInputWrapper>
                )}

                {showAppointmentFields && (
                  <FormikCheckbox
                    name="syncAppointment"
                    label="Sync appointment with EHR"
                  />
                )}

                {values.syncAppointment && <AppointmentFields />}
                {values.billable && <DefaultBillingConfiguration />}

                <Stack spacing={0}>
                  <StyledLabel>Tasks</StyledLabel>
                  <FieldArray
                    name="tasks"
                    render={(helpers) => (
                      <Group>
                        {values.tasks.map((task, index) => {
                          const type = task.type.value as ActivityType;

                          return (
                            <ActivityTaskTemplateFormCard
                              key={`${task.type}-${index}`}
                              cardStyle={{ width: 300 }}
                              disabled={disabled}
                              namePrefix={`tasks.${index}`}
                              type={type}
                              taskTemplateOptions={taskTemplateOptions}
                              onRemove={() => helpers.remove(index)}
                              context={context}
                              omitEventType={values.tasks.length > 1}
                              onEmailTemplateCreate={
                                context === FormContext.Template
                                  ? () => setShowEmailTemplateModal(true)
                                  : undefined
                              }
                            />
                          );
                        })}

                        {(values.tasks.length === 0 ||
                          values.tasks[0].type.value !==
                            ActivityType.Event) && (
                          <Button
                            h={300}
                            w={300}
                            key="add-task"
                            variant="light"
                            disabled={response.loading || disabled}
                            onClick={() => {
                              const task = createBaseActivityTaskTemplate(
                                values.tasks.length,
                              );

                              const wrappedTask = wrapTaskFormValues(
                                task,
                                taskTemplateOptions.all,
                              );

                              helpers.push(wrappedTask);
                            }}
                          >
                            Add Task
                          </Button>
                        )}
                      </Group>
                    )}
                  />
                </Stack>
              </Stack>

              <Group mt="md" position="right">
                <Tooltip
                  label="You do not have write access"
                  disabled={!disabled}
                >
                  <span>
                    <Button
                      loading={response.loading}
                      disabled={!isValid || disabled}
                      data-testid="create-or-update-activity-template-button"
                      type="submit"
                    >
                      {id
                        ? "Update Activity Template"
                        : "Create Activity Template"}
                    </Button>
                  </span>
                </Tooltip>
              </Group>

              <DirtyLeavePrompt
                hasUnsavedChanges={hasUnsavedChanges}
                setHasUnsavedChanges={setHasUnsavedChanges}
              />
            </Form>
          );
        }}
      </Formik>

      <WarnLockingReferencesModal
        isOpen={Boolean(warningModalDetails)}
        lockedObject="Activity Template"
        activeReferences={activeReferences}
        onConfirm={onLockingReferenceConfirm}
        onRequestClose={() => setWarningModalDetails(null)}
      />

      <EmailTemplateModal
        opened={showEmailTemplateModal}
        onRequestedClose={() => setShowEmailTemplateModal(false)}
      />
    </>
  );
}

export const DefaultBillingConfiguration = () => {
  const { selectedOrganization } = useAuthContext();

  const procedureOptions = useMemo(() => {
    const costs = selectedOrganization.billingInsuranceConfigurations.flatMap(
      (config) => config.costs.map((config) => config.procedure),
    );

    return costs.map(wrapSelectOption);
  }, [selectedOrganization.billingInsuranceConfigurations]);

  const organizationPlaceOfServiceOptions = useMemo(
    () =>
      placeOfServiceCodeOptions.filter((option) =>
        selectedOrganization.placeOfServiceCodes.includes(
          parseInt(option.value, 10),
        ),
      ),
    [selectedOrganization.placeOfServiceCodes],
  );

  const diagnosisOptions = useMemo(
    () =>
      selectedOrganization.diagnosisCodes.map(({ code, description }) => ({
        label: `${code} - ${description ?? "No description"}`,
        value: code,
      })),
    [selectedOrganization.diagnosisCodes],
  );

  return (
    <Stack mb="lg" spacing={0}>
      <StyledLabel color="grey" m={0} p={0}>
        These default settings will be used at the end of activity to
        auto-populate billing related fields
      </StyledLabel>

      <FormikSelect
        clearable
        name={`defaultBillingDetails.procedure`}
        label="Procedure"
        placeholder="Select Procedure"
        options={procedureOptions}
      />

      <FormikSelect
        clearable
        name={`defaultBillingDetails.placeOfService`}
        label="Place of Service"
        placeholder="Select Place of Service"
        options={organizationPlaceOfServiceOptions}
      />

      <FormikMultiSelect
        name={`defaultBillingDetails.diagnosisCodes`}
        label="Diagnosis"
        placeholder="Select one or more diagnosis code"
        options={diagnosisOptions}
      />
    </Stack>
  );
};

export const AppointmentFields = () => (
  <>
    <FormikInput
      name="appointmentDetails.locationFacility"
      type="text"
      label="Appointment Facility"
      placeholder="Community Hospital"
    />
    <FormikInput
      name="appointmentDetails.locationType"
      type="text"
      label="Appointment Location type"
      placeholder="Phone, Department, Home, Nursing Unit, Provider's Office"
    />
    <FormikInput
      required
      name="appointmentDetails.locationDepartment"
      type="text"
      label="Appointment Department"
      placeholder="Department"
    />
    <FormikInput
      name="appointmentDetails.locationRoom"
      type="text"
      label="Appointment Room"
      placeholder="136"
    />
  </>
);

type ActivityTaskTemplateFormCardProps = {
  readonly disabled?: boolean;
  readonly type: ActivityType;
  readonly taskTemplateOptions: TaskTemplateOptions;
  readonly namePrefix: string;
  readonly onRemove: () => void;
  readonly cardStyle?: React.CSSProperties;
  readonly cardOnClick?: MouseEventHandler<HTMLDivElement>;
  readonly context: FormContextType;
  readonly memberId?: string;
  readonly omitEventType?: boolean;
  readonly member?: Member;
  readonly onEmailTemplateCreate?: () => void;
};

export const TASK_TYPE_OPTIONS: SelectOption<ActivityType>[] = [
  { label: "SMS", value: ActivityType.Sms },
  { label: "Email", value: ActivityType.Email },
  { label: "Assessment", value: ActivityType.Assessment },
  { label: "Script", value: ActivityType.Script },
  { label: "Journey", value: ActivityType.Journey },
  { label: "Send EHR Note", value: ActivityType.SendEhrNote },
  { label: "Sync EHR Member", value: ActivityType.SyncEhrMember },
  { label: "Group Event", value: ActivityType.Event },
];

export function ActivityTaskTemplateFormCard({
  disabled,
  type,
  taskTemplateOptions,
  namePrefix,
  onRemove,
  cardStyle,
  cardOnClick,
  context,
  memberId,
  omitEventType,
  member,
  onEmailTemplateCreate,
}: ActivityTaskTemplateFormCardProps): JSX.Element {
  const { selectedOrganization } = useAuthContext();
  const { data: memberDataResponse } = useQueryMember(
    memberId ?? "",
    [selectedOrganization._id],
    !memberId,
  );

  const memberData = memberDataResponse?.member.data;

  const typeOptions = TASK_TYPE_OPTIONS.filter((option) => {
    if (!selectedOrganization.callerId && option.value === ActivityType.Sms) {
      return type === ActivityType.Sms || false;
    }

    const activatedIntegrations =
      selectedOrganization.activatedIntegrations ?? [];

    if (
      !activatedIntegrations.includes(Integration.RedoxNoteSending) &&
      option.value === ActivityType.SendEhrNote
    )
      return false;

    if (
      !activatedIntegrations.includes(Integration.RedoxPatientSync) &&
      option.value === ActivityType.SyncEhrMember
    )
      return false;

    if (
      memberData &&
      option.value === ActivityType.Email &&
      !memberData.contactInfo.email
    )
      return false;

    if (option.value === ActivityType.Event && omitEventType) return false;

    return true;
  });

  return (
    <Card withBorder h={300} style={cardStyle} onClick={cardOnClick}>
      <Stack style={{ flexGrow: 1, height: "100%" }} spacing="xs">
        <FormikSelect
          required
          disabled={disabled}
          name={`${namePrefix}.type`}
          label="Type"
          placeholder="Select task type"
          options={typeOptions}
        />

        {type === ActivityType.Email && (
          <FormikSelect
            required
            disabled={disabled}
            name={`${namePrefix}.formId`}
            label="Template"
            placeholder="Select a template"
            options={taskTemplateOptions.email}
            creatable
            getCreateLabel={() => "+ Create New Template"}
            onCreate={() => {
              if (onEmailTemplateCreate) onEmailTemplateCreate();
              return null;
            }}
          />
        )}

        {type === ActivityType.Event && (
          <FormikSelect
            required
            disabled={disabled}
            name={`${namePrefix}.formId`}
            label="Template"
            placeholder="Select a template"
            options={taskTemplateOptions.event}
            creatable
            onCreate={(query) => {
              const id = `CREATE:${query}__${type}`;
              const option = { label: query, value: id, type };
              return option;
            }}
          />
        )}

        {type === ActivityType.SendEhrNote && (
          <FormikSelect
            required
            disabled={disabled}
            name={`${namePrefix}.formId`}
            label="Template"
            placeholder="Select a template"
            options={taskTemplateOptions.ehrNote}
          />
        )}

        {isFlowBasedActivityType(type) && (
          <FormikSelect
            required
            disabled={disabled}
            name={`${namePrefix}.formId`}
            label="Template"
            placeholder="Select a template"
            options={taskTemplateOptions.flow[type]}
            creatable
            onCreate={(query) => {
              const id = `CREATE:${query}__${type}`;
              const option = { label: query, value: id, type };
              return option;
            }}
          />
        )}

        {type === ActivityType.Sms &&
          (disabled ? (
            <FormikTextarea
              required
              disabled
              name={`${namePrefix}.description`}
              label="Message"
            />
          ) : (
            <FormikTextEditor
              required
              textOnly={true}
              name={`${namePrefix}.description`}
              label="Message"
              styles={{
                content: {
                  minHeight: "150px",
                  maxHeight: "150px",
                },
              }}
            />
          ))}

        <Button
          color="red"
          variant="light"
          onClick={onRemove}
          style={{ marginTop: "auto" }}
          disabled={disabled}
        >
          Delete
        </Button>
      </Stack>
    </Card>
  );
}
