import { ApolloCache, useMutation, useQuery } from "@apollo/client";
import { GET_EXTERNAL_RESOURCES } from "../ExternalResource/queries";
import {
  ExternalResource,
  GetExternalResourcesResponse,
  GetNoteResponse,
  GetNotesInput,
  GetNotesResponse,
  MutationCreateNoteArgs,
  MutationUpdateNoteArgs,
  Note,
  QueryNotesArgs,
} from "../schemaTypes";
import { CREATE_NOTE, UPDATE_NOTE } from "./mutations";
import { GET_NOTES } from "./queries";

export const useQueryNotes = (input: GetNotesInput) =>
  useQuery<{ notes: GetNotesResponse }, QueryNotesArgs>(GET_NOTES, {
    variables: { input },
  });

type UpdateGetNoteQueryCacheOptions = {
  cache: ApolloCache<unknown>;
  variables: QueryNotesArgs;
  note: Note;
};

const updateGetNotesQuery = ({
  cache,
  variables,
  note,
}: UpdateGetNoteQueryCacheOptions) => {
  cache.updateQuery<{ notes: GetNotesResponse }>(
    { query: GET_NOTES, variables },

    (data) => {
      if (!data?.notes.data) return;

      const notes = [...data.notes.data, note];
      return { ...data, notes: { ...data.notes, data: notes } };
    },
  );
};

type UpdateGetExternalResourcesCacheOptions = {
  cache: ApolloCache<unknown>;
  organizationId: string;
  mapWithNote: (resource: ExternalResource) => ExternalResource;
};

const updateGetExternalResourcesQuery = ({
  cache,
  organizationId,
  mapWithNote,
}: UpdateGetExternalResourcesCacheOptions) => {
  cache.updateQuery<{
    externalResources: GetExternalResourcesResponse;
  }>(
    {
      query: GET_EXTERNAL_RESOURCES,
      variables: { organizationId },
    },

    (data) => {
      if (!data?.externalResources.data) return;
      else
        return {
          ...data,
          externalResources: {
            ...data.externalResources,
            data: data.externalResources.data.map(mapWithNote),
          },
        };
    },
  );
};

export const useMutationCreateNote = () =>
  useMutation<{ createNote: GetNotesResponse }, MutationCreateNoteArgs>(
    CREATE_NOTE,
    {
      update: (cache, result) => {
        const createdNotes = result.data?.createNote.data;
        if (!createdNotes) return;

        createdNotes.forEach((createdNote) => {
          const {
            organizationId,
            activityId,
            memberId,
            externalResourceId,
            externalResourceContactId,
          } = createdNote;

          // Activity notes
          if (createdNote.activityId) {
            updateGetNotesQuery({
              cache,
              note: createdNote,
              variables: { input: { organizationId, activityId } },
            });

            updateGetNotesQuery({
              cache,
              note: createdNote,
              variables: { input: { organizationId, activityId, memberId } },
            });
          }

          // Member notes
          if (createdNote.memberId)
            updateGetNotesQuery({
              cache,
              note: createdNote,
              variables: { input: { organizationId, memberId } },
            });

          // External resource contacts
          if (createdNote.externalResourceContactId) {
            updateGetNotesQuery({
              cache,
              note: createdNote,
              variables: {
                input: { organizationId, externalResourceContactId },
              },
            });

            updateGetExternalResourcesQuery({
              cache,
              organizationId,
              mapWithNote: (resource) => {
                const contacts = resource.contacts.map((contact) =>
                  externalResourceContactId === contact._id
                    ? { ...contact, notes: [...contact.notes, createdNote] }
                    : contact,
                );

                return { ...resource, contacts };
              },
            });
          }

          // External resources
          if (createdNote.externalResourceId) {
            updateGetNotesQuery({
              cache,
              note: createdNote,
              variables: { input: { organizationId, externalResourceId } },
            });

            updateGetExternalResourcesQuery({
              cache,
              organizationId,
              mapWithNote: (resource) => {
                if (resource._id !== externalResourceId) return resource;
                return { ...resource, notes: [...resource.notes, createdNote] };
              },
            });
          }
        });
      },
    },
  );

export const useMutationUpdateNote = () =>
  useMutation<{ updateNote: GetNoteResponse }, MutationUpdateNoteArgs>(
    UPDATE_NOTE,
  );
