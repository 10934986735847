import { Textarea, TextInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import React, { memo, useEffect, useState } from "react";
import { Check, X } from "react-feather";
import { Position } from "react-flow-renderer";

import { AnswerType } from "src/graphql";
import { useAutoFocusInputRef, useAutoFocusTextAreaRef } from "src/hooks";
import styled from "styled-components";
import * as Yup from "yup";
import { useFlowSnapshotContext } from "../snapshot-editor/context";
import {
  AnswerNodeData,
  READ_ONLY_ACKNOWLEDGED_VALUE,
  READ_ONLY_NOT_ACKNOWLEDGED_VALUE,
} from "../util";
import {
  getBgColor,
  getBgHighlightColor,
  getBgMutedColor,
  MeasurableNodeProps,
} from "./shared";
import { StyledEdgeHandle } from "./StyledEdgeHandle";

// base answer node component
const AnswerContainer = styled.div<{
  readOnly: boolean;
  visited: boolean;
  hasUnsavedChanges: boolean;
}>`
  background-color: ${({ visited, hasUnsavedChanges }) =>
    visited ? getBgColor(hasUnsavedChanges) : "rgba(0, 0, 0, 0.03)"};
  color: ${({ visited }) => (visited ? "white" : "inherit")};
  border-radius: 3px;
  padding: 5px;
  position: relative;
  cursor: ${({ readOnly }) => (readOnly ? "default" : "inherit")};
`;

// select-one/select-multi variant
const SelectableAnswerContainer = styled(AnswerContainer)<{
  isEditable: boolean;
}>`
  cursor: ${({ isEditable, visited }) =>
    isEditable && !visited ? "pointer" : "default"};

  &:hover {
    background-color: ${({ isEditable, visited, hasUnsavedChanges }) =>
      isEditable && !visited ? getBgMutedColor(hasUnsavedChanges) : null};
  }

  &:active {
    background-color: ${({ isEditable, visited, hasUnsavedChanges }) =>
      isEditable && !visited ? getBgColor(hasUnsavedChanges) : null};
  }
`;

// toggle single value variant
const ToggleAnswerContainer = styled(AnswerContainer)<{
  isEditable: boolean;
}>`
  cursor: ${({ isEditable }) => (isEditable ? "pointer" : "default")};
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${({ isEditable, visited, hasUnsavedChanges }) =>
      isEditable
        ? visited
          ? getBgHighlightColor(hasUnsavedChanges)
          : getBgMutedColor(hasUnsavedChanges)
        : undefined};
  }

  &:active {
    background-color: ${({ isEditable, visited, hasUnsavedChanges }) =>
      isEditable && !visited ? getBgColor(hasUnsavedChanges) : null};
  }
`;

export const SnapshotAnswerFlowNode = memo(
  ({ data, measuringProps }: MeasurableNodeProps<AnswerNodeData>) => {
    const { selectedEditNodeId, onUpdateAnswer } = useFlowSnapshotContext();

    const isEditable = data.parentStepId === selectedEditNodeId;

    const handleUpdateAnswer = (answerValue: string) => {
      onUpdateAnswer(data.parentStepId, answerValue);
    };

    const sharedProps: AnswerNodeMetaProps = {
      isEditable,
      visited: !!data.visited,
      answerValue: data.answerValue,
      isOutputNode: data.isOutputNode,
      style: measuringProps?.style,
      hasUnsavedChanges: !!data.hasUnsavedChanges,
      onUpdateAnswer: handleUpdateAnswer,
    };

    return (
      <div ref={measuringProps?.innerRef} style={{ zIndex: 2 }}>
        {(() => {
          switch (data.answerType) {
            case AnswerType.Multi:
            case AnswerType.Boolean:
              return <SelectAnswerType {...sharedProps} />;
            case AnswerType.MultiChoice:
              return <MultiSelectAnswerType {...sharedProps} />;
            case AnswerType.Date:
              return <DateAnswerType {...sharedProps} />;
            case AnswerType.Text:
              return <TextInputAnswerType {...sharedProps} />;
            case AnswerType.Number:
              return <NumberInputAnswerType {...sharedProps} />;
            case AnswerType.ReadOnlyText:
              return <ReadOnlyAnswerType {...sharedProps} />;
          }
        })()}
      </div>
    );
  },
);

type AnswerNodeMetaProps = {
  isEditable: boolean;
  visited: boolean;
  answerValue: string;
  isOutputNode: boolean;
  hasUnsavedChanges: boolean;
  style?: React.CSSProperties;
  onUpdateAnswer: (answer: string) => void;
};

const SelectAnswerType = ({
  isEditable,
  visited,
  style,
  answerValue,
  isOutputNode,
  hasUnsavedChanges,
  onUpdateAnswer: onSelectAnswer,
}: AnswerNodeMetaProps) => (
  <SelectableAnswerContainer
    readOnly={true}
    visited={visited}
    style={style}
    isEditable={isEditable}
    hasUnsavedChanges={hasUnsavedChanges}
    onClick={isEditable ? () => onSelectAnswer(answerValue) : undefined}
  >
    {answerValue}

    {isOutputNode && (
      <StyledEdgeHandle
        type={"source"}
        position={Position.Right}
        isConnectable={false}
      />
    )}
  </SelectableAnswerContainer>
);

const MultiSelectAnswerType = ({
  isEditable,
  visited,
  style,
  answerValue,
  isOutputNode,
  hasUnsavedChanges,
  onUpdateAnswer: onSelectAnswer,
}: AnswerNodeMetaProps) => (
  <ToggleAnswerContainer
    readOnly={true}
    visited={visited}
    style={style}
    isEditable={isEditable}
    hasUnsavedChanges={hasUnsavedChanges}
    onClick={isEditable ? () => onSelectAnswer(answerValue) : undefined}
  >
    {answerValue}

    {isOutputNode && (
      <StyledEdgeHandle
        type={"source"}
        position={Position.Right}
        isConnectable={false}
      />
    )}
  </ToggleAnswerContainer>
);

const TextInputAnswerType = ({
  isEditable,
  visited,
  style,
  answerValue,
  hasUnsavedChanges,
  isOutputNode,
  onUpdateAnswer,
}: AnswerNodeMetaProps) => {
  const textAreaElementRef = useAutoFocusTextAreaRef(isEditable);
  const noValueLabel = "User Text Input";
  const [workingValue, setWorkingValue] = useState(
    answerValue === noValueLabel ? "" : answerValue,
  );

  // reset workingValue state when editing stops
  useEffect(() => {
    !isEditable && setWorkingValue(answerValue);
  }, [isEditable, answerValue]);

  // sets in local state for render mgmt, and also reports to editor
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setWorkingValue(e.currentTarget.value);
    onUpdateAnswer(e.currentTarget.value);
  };

  return (
    <>
      <AnswerContainer
        readOnly={true}
        visited={visited}
        hasUnsavedChanges={hasUnsavedChanges}
        style={{ ...(style ?? {}), padding: isEditable ? 0 : undefined }}
      >
        {!isEditable && (answerValue ? answerValue : noValueLabel)}
        {isEditable && (
          <Textarea
            minRows={4}
            maxRows={4}
            value={workingValue}
            onChange={handleChange}
            ref={textAreaElementRef}
          />
        )}

        {isOutputNode && (
          <StyledEdgeHandle
            type={"source"}
            position={Position.Right}
            isConnectable={false}
          />
        )}
      </AnswerContainer>
    </>
  );
};

const NumberInputAnswerType = ({
  isEditable,
  visited,
  style,
  answerValue,
  hasUnsavedChanges,
  isOutputNode,
  onUpdateAnswer,
}: AnswerNodeMetaProps) => {
  const inputElementRef = useAutoFocusInputRef(isEditable);
  const noValueLabel = "User Number Input";
  const [workingValue, setWorkingValue] = useState(
    answerValue === noValueLabel ? "" : answerValue,
  );

  // reset workingValue state when editing stops
  useEffect(() => {
    !isEditable && setWorkingValue(answerValue);
  }, [isEditable, answerValue]);

  // sets in local state for render mgmt, and also reports to editor
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!!e.currentTarget.value && e.currentTarget.value !== "-") {
      try {
        Yup.number().validateSync(e.currentTarget.value);
      } catch {
        e.preventDefault();
        return;
      }
    }
    setWorkingValue(e.currentTarget.value);
    onUpdateAnswer(e.currentTarget.value);
  };

  return (
    <>
      <AnswerContainer
        readOnly={true}
        visited={visited}
        hasUnsavedChanges={hasUnsavedChanges}
        style={{ ...(style ?? {}), padding: isEditable ? 0 : undefined }}
      >
        {!isEditable && (answerValue ? answerValue : noValueLabel)}
        {isEditable && (
          <TextInput
            value={workingValue}
            onChange={handleChange}
            ref={inputElementRef}
          />
        )}

        {isOutputNode && (
          <StyledEdgeHandle
            type={"source"}
            position={Position.Right}
            isConnectable={false}
          />
        )}
      </AnswerContainer>
    </>
  );
};

const ReadOnlyAnswerType = ({
  visited,
  answerValue,
  isEditable,
  style,
  hasUnsavedChanges,
  isOutputNode,
  onUpdateAnswer,
}: AnswerNodeMetaProps) => {
  const [workingValue, setWorkingValue] = useState(
    visited ? READ_ONLY_ACKNOWLEDGED_VALUE : READ_ONLY_NOT_ACKNOWLEDGED_VALUE,
  );

  // reset workingValue state when editing stops
  useEffect(() => {
    !isEditable && setWorkingValue(answerValue);
  }, [isEditable, answerValue]);

  // sets in local state for render mgmt, and also reports to editor
  const handleToggle = () => {
    const nextValue =
      workingValue === READ_ONLY_ACKNOWLEDGED_VALUE
        ? READ_ONLY_NOT_ACKNOWLEDGED_VALUE
        : READ_ONLY_ACKNOWLEDGED_VALUE;
    setWorkingValue(nextValue);
    onUpdateAnswer(nextValue);
  };

  return (
    <ToggleAnswerContainer
      readOnly={true}
      visited={workingValue === READ_ONLY_ACKNOWLEDGED_VALUE}
      isEditable={isEditable}
      style={{ ...(style ?? {}), cursor: isEditable ? "pointer" : undefined }}
      hasUnsavedChanges={hasUnsavedChanges}
      onClick={isEditable ? handleToggle : undefined}
    >
      {workingValue === READ_ONLY_ACKNOWLEDGED_VALUE && (
        <>
          <Check size="17" />
          &nbsp;Acknowledged
        </>
      )}

      {workingValue !== READ_ONLY_ACKNOWLEDGED_VALUE && (
        <>
          <X size="17" />
          &nbsp;Not Acknowledged
        </>
      )}

      {isOutputNode && (
        <StyledEdgeHandle
          type={"source"}
          position={Position.Right}
          isConnectable={false}
        />
      )}
    </ToggleAnswerContainer>
  );
};

const DateAnswerType = ({
  visited,
  answerValue: answer,
  isEditable,
  style,
  hasUnsavedChanges,
  isOutputNode,
  onUpdateAnswer,
}: AnswerNodeMetaProps) => {
  const [value, setValue] = useState(answer ? dayjs(answer).toDate() : null);
  const noValueLabel = "User Date Input";

  // Reset when editing stops
  useEffect(() => {
    !isEditable && setValue(answer ? dayjs(answer).toDate() : null);
  }, [isEditable, answer]);

  // Update local value and also answer
  const handleChange = (value: Date | null) => {
    setValue(value);
    onUpdateAnswer(value ? value.toISOString() : "");
  };

  return (
    <AnswerContainer
      readOnly={true}
      visited={visited}
      hasUnsavedChanges={hasUnsavedChanges}
      style={{ ...(style ?? {}), padding: isEditable ? 0 : undefined }}
    >
      {!isEditable && (value ? dayjs(value).format("LL") : noValueLabel)}

      {isEditable && (
        <DatePickerInput
          clearable
          placeholder="No date"
          valueFormat="LL"
          value={value}
          onChange={handleChange}
        />
      )}

      {isOutputNode && (
        <StyledEdgeHandle
          type="source"
          position={Position.Right}
          isConnectable={false}
        />
      )}
    </AnswerContainer>
  );
};
