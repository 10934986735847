import { Button, Select, Stack } from "@mantine/core";
import { FormikProps } from "formik";
import { useMemo, useRef, useState } from "react";
import { Node } from "react-flow-renderer";

import {
  FlowBuilderData,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  StyledLabel,
} from "src/components";
import { ActionType, CarePathwayStatus, MemberField } from "src/graphql";
import { SelectOption } from "src/types";
import { ActionInfo, PearNodesData } from "../../util";
import { useFlowBuilderContext } from "../context";
import {
  CreateGoalActionForm,
  ScheduleActivityActionForm,
  UpdateGoalActionForm,
} from "./forms";
import { UpdateMemberActionForm } from "./forms/UpdateMemberActionForm";
import { CreateReferralActionForm } from "./forms/CreateReferralActionForm";
import { StartCarePathwayActionForm } from "./forms/StartCarePathwayActionForm";
import { UpdateCarePathwayStatusActionForm } from "./forms/UpdateCarePathwayStatusActionForm";
import { CarePathwayStatusMap } from "src/utils/carePathway";
import { CreateConsentActionForm } from "./forms/CreateConsentActionForm";
import { AddOrRemoveGroupForm } from "./forms/AddOrRemoveGroupForm";

// shared props for the sub-forms driving action creation by type
export type SubFormCommonProps = {
  innerRef: React.RefObject<FormikProps<unknown>>;
  node: Node<PearNodesData> | null;
  onCreateAction: (node: Node<PearNodesData>, actionInfo: ActionInfo) => void;
  onDirtyStateChange: (dirty: boolean) => void;
  onValidStateChange: (valid: boolean) => void;
};

type ActionModalProps = {
  isOpen: boolean;
  node: Node<PearNodesData> | null;
  onRequestClose: () => void;
  onCreateAction: (node: Node<PearNodesData>, actionInfo: ActionInfo) => void;
};

export const AddActionModal = ({
  isOpen,
  node,
  onRequestClose,
  onCreateAction,
}: ActionModalProps) => {
  const subFormRef: React.RefObject<FormikProps<unknown>> = useRef(null);
  const [actionType, setActionType] = useState<ActionType | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const { builderData } = useFlowBuilderContext();

  const selectOptionsByType = useMemo(
    () =>
      builderData?.actionOptionsByTypeById
        ? wrapSelectOptions(builderData?.actionOptionsByTypeById)
        : ({} as Record<ActionType, SelectOption<string>[]>),
    [builderData?.actionOptionsByTypeById],
  );

  const handleSetActionType = (nextType: ActionType | null) => {
    // reset any subform state
    setDirty(false);
    setIsValid(false);
    setIsSubmitting(false);

    setActionType(nextType);
  };

  const handleSubmit = () => {
    if (!node || !subFormRef.current) return null; // nothing renders when node is not set
    setIsSubmitting(true);
    subFormRef.current.submitForm();
    setIsSubmitting(false);
    onRequestClose();
  };

  const sortSelectOptions = (
    a: SelectOption<string>,
    b: SelectOption<string>,
  ) => a.label.toLowerCase().localeCompare(b.label.toLowerCase());

  // common props shared between the sub forms
  const commonProps: SubFormCommonProps = {
    innerRef: subFormRef,
    node,
    onCreateAction,
    onDirtyStateChange: (dirty: boolean) => setDirty(dirty),
    onValidStateChange: (valid: boolean) => setIsValid(valid),
  };

  return (
    <Modal opened={isOpen} onClose={onRequestClose}>
      {node && (
        <>
          <ModalHeader>Add Action</ModalHeader>

          <ModalBody>
            {/* {First select an action type} */}
            <Stack spacing={0}>
              <StyledLabel>Action Type</StyledLabel>
              <Select
                placeholder="Please select one of actions"
                value={actionType}
                data={actionTypeSelectOptions}
                onChange={(type) => handleSetActionType(type as ActionType)}
              />
            </Stack>

            {/* Once action type selected, render corresponding form */}
            {actionType === ActionType.CreateGoalData && (
              <CreateGoalActionForm
                goalTemplateOptions={selectOptionsByType[actionType].sort(
                  sortSelectOptions,
                )}
                goalTemplatesById={
                  builderData?.actionOptionsByTypeById[actionType]
                }
                {...commonProps}
              />
            )}

            {actionType === ActionType.ScheduleActivityData && (
              <ScheduleActivityActionForm
                templateOptions={selectOptionsByType[actionType].sort(
                  sortSelectOptions,
                )}
                templatesById={builderData?.actionOptionsByTypeById[actionType]}
                {...commonProps}
              />
            )}

            {actionType === ActionType.UpdateGoalData && (
              <UpdateGoalActionForm
                goalTemplateOptions={selectOptionsByType[actionType].sort(
                  sortSelectOptions,
                )}
                goalTemplatesById={
                  builderData?.actionOptionsByTypeById[actionType]
                }
                {...commonProps}
              />
            )}

            {actionType === ActionType.UpdateMemberData && (
              <UpdateMemberActionForm
                memberFieldOptions={Object.values(MemberField)
                  .map((interaction) => ({
                    label: interaction.toString(),
                    value: interaction,
                  }))
                  .sort(sortSelectOptions)}
                dataIdOptions={selectOptionsByType[actionType].sort(
                  sortSelectOptions,
                )}
                dataIdsById={builderData?.actionOptionsByTypeById[actionType]}
                {...commonProps}
              />
            )}

            {actionType === ActionType.CreateReferral && (
              <CreateReferralActionForm
                resourceOptions={selectOptionsByType[actionType].sort(
                  sortSelectOptions,
                )}
                {...commonProps}
              />
            )}

            {actionType === ActionType.StartCarePathway && (
              <StartCarePathwayActionForm
                carePathwayTemplateOptions={selectOptionsByType[
                  actionType
                ].sort(sortSelectOptions)}
                carePathwayTemplatesById={
                  builderData?.actionOptionsByTypeById[actionType]
                }
                {...commonProps}
              />
            )}

            {actionType === ActionType.UpdateCarePathwayStatus && (
              <UpdateCarePathwayStatusActionForm
                carePathwayTemplateOptions={selectOptionsByType[
                  actionType
                ].sort(sortSelectOptions)}
                carePathwayStatusOptions={Object.values(CarePathwayStatus)
                  .map((status) => ({
                    label: CarePathwayStatusMap[status],
                    value: status,
                  }))
                  .sort(sortSelectOptions)}
                carePathwayTemplatesById={
                  builderData?.actionOptionsByTypeById[actionType]
                }
                {...commonProps}
              />
            )}
            {actionType === ActionType.CreateConsent && (
              <CreateConsentActionForm
                consentTemplateOptions={selectOptionsByType[actionType].sort(
                  sortSelectOptions,
                )}
                consentTemplatesById={
                  builderData?.actionOptionsByTypeById[actionType]
                }
                {...commonProps}
              />
            )}
            {(actionType === ActionType.AddMemberToGroup ||
              actionType === ActionType.RemoveMemberFromGroup) && (
              <AddOrRemoveGroupForm
                key={actionType}
                groupOptions={selectOptionsByType[actionType].sort(
                  sortSelectOptions,
                )}
                groupsById={builderData?.actionOptionsByTypeById[actionType]}
                actionType={actionType}
                {...commonProps}
              />
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              color="red"
              variant="outline"
              disabled={isSubmitting}
              onClick={onRequestClose}
            >
              Cancel
            </Button>

            <Button
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={!dirty || !isValid}
            >
              Add Action
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

const actionTypeSelectOptions: SelectOption<ActionType>[] = [
  {
    label: "Create Goal",
    value: ActionType.CreateGoalData,
  },
  {
    label: "Complete Goal",
    value: ActionType.UpdateGoalData,
  },
  {
    label: "Schedule Activity",
    value: ActionType.ScheduleActivityData,
  },
  {
    label: "Update Member",
    value: ActionType.UpdateMemberData,
  },
  {
    label: "Create Referral",
    value: ActionType.CreateReferral,
  },
  {
    label: "Start Care Pathway",
    value: ActionType.StartCarePathway,
  },
  {
    label: "Update Care Pathway Status",
    value: ActionType.UpdateCarePathwayStatus,
  },
  {
    label: "Collect Consent",
    value: ActionType.CreateConsent,
  },
  {
    label: "Add Member To Group",
    value: ActionType.AddMemberToGroup,
  },
  {
    label: "Remove Member From Group",
    value: ActionType.RemoveMemberFromGroup,
  },
];

const wrapSelectOptions = (
  actionOptions: FlowBuilderData["actionOptionsByTypeById"],
): Record<ActionType, SelectOption<string>[]> => {
  const carePathwayTemplateSelectOptions = Object.values(
    actionOptions[
      (ActionType.StartCarePathway, ActionType.UpdateCarePathwayStatus)
    ],
  ).map((template) => ({
    label: template.name,
    value: template._id,
  }));

  const goalTemplateSelectOptions = Object.values(
    actionOptions[ActionType.CreateGoalData],
  ).map((template) => ({
    label: template.name,
    value: template._id,
  }));
  const activityTemplateSelectOptions = Object.values(
    actionOptions[ActionType.ScheduleActivityData],
  ).map((template) => ({
    label: template.title,
    value: template._id,
  }));

  const memberUpdateSelectOptions = Object.values(
    actionOptions[ActionType.UpdateMemberData],
  ).map((dataId) => ({
    label: dataId.dataId,
    value: dataId.dataId,
  }));

  const externalResourceSelectOptions = Object.values(
    actionOptions[ActionType.CreateReferral],
  ).map((resource) => ({
    label: resource.title,
    value: resource._id,
  }));

  const consentTemplateSelectOptions = Object.values(
    actionOptions[ActionType.CreateConsent],
  ).map((resource) => ({
    label: resource.name,
    value: resource._id,
  }));

  const addOrRemoveGroupSelectOptions = Object.values(
    actionOptions[ActionType.AddMemberToGroup],
  ).map((resource) => ({
    label: resource.title,
    value: resource._id,
  }));

  return {
    [ActionType.CreateGoalData]: goalTemplateSelectOptions,
    [ActionType.ScheduleActivityData]: activityTemplateSelectOptions,
    [ActionType.UpdateGoalData]: goalTemplateSelectOptions,
    [ActionType.UpdateMemberData]: memberUpdateSelectOptions,
    [ActionType.CreateReferral]: externalResourceSelectOptions,
    [ActionType.StartCarePathway]: carePathwayTemplateSelectOptions,
    [ActionType.UpdateCarePathwayStatus]: carePathwayTemplateSelectOptions,
    [ActionType.CreateConsent]: consentTemplateSelectOptions,
    [ActionType.AddMemberToGroup]: addOrRemoveGroupSelectOptions,
    [ActionType.RemoveMemberFromGroup]: addOrRemoveGroupSelectOptions,
  };
};
