import * as Yup from "yup";
import { parsePhoneNumber } from "libphonenumber-js";

const YUP_PHONE_METHOD = "phone";

declare module "yup" {
  export interface StringSchema {
    /**
     * Check for phone number validity using libphonenumber-js, mirrors backend validation
     */
    phone(errrorMessage?: string): this;
  }
}

Yup.addMethod(
  Yup.string,
  YUP_PHONE_METHOD,
  function (errorMessage = "Invalid phone number") {
    return this.test(YUP_PHONE_METHOD, errorMessage, (value) => {
      if (!value) return true;

      const parsedNumber = parsePhoneNumber(value, "US");
      return parsedNumber.isPossible();
    });
  },
);
