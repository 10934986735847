import { gql } from "@apollo/client";
import { DataFields } from "../DataPoint/types";
import { CompleteActionDataFields, FlowFields } from "./types";

export const CREATE_FLOW = gql`
  mutation CreateFlow($input: CreateFlowInput!) {
    createFlow(input: $input) {
      success
      message
    }
  }
`;

export const DELETE_FLOW = gql`
  mutation DeleteFlow($flowId: ID!, $organizationId: ID!) {
    deleteFlow(flowId: $flowId, organizationId: $organizationId) {
      success
      message
    }
  }
`;

export const UPDATE_FLOW_PROGRESS = gql`
  mutation UpdateFlowProgress($input: UpdateFlowProgressInput!) {
    updateFlowProgress(input: $input) {
      success
      message
      data {
        ${CompleteActionDataFields}
      }
      createdDataPoint {
        ${DataFields}
      }
    }
  }
`;

export const EDIT_FLOW_FROM_SNAPSHOT = gql`
  mutation UpdateFlowFromSnapshot($input: UpdateFlowFromSnapshotEditorInput!) {
    updateFlowFromSnapshotEditor(input: $input) {
      success
      message
      data {
        ${FlowFields}
      }
      createdObjects {
        ${CompleteActionDataFields}
      }
      createdDataPoints {
        ${DataFields}
      }
    }
  }
`;

export const UNDO_LAST_FLOW_PROGRESS = gql`
  mutation UndoLastFlowProgress($input: UndoLastFlowProgressInput!) {
    undoLastFlowProgress(input: $input) {
      success
      message
    }
  }
`;
