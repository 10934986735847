import { gql } from "@apollo/client";
import { PhoneCallFields, ClaimSuccessFields } from "./types";

export const UPDATE_PHONE_CALL_ENTRY = gql`
  mutation UpdatePhoneCallEntry($input: UpdatePhoneCallEntryInput!) {
    updatePhoneCallEntry(input: $input) {
      success
      message
      data {
        ${PhoneCallFields}
      }
    }
  }
`;

export const CREATE_VOICE_GRANT_ACCESS_TOKEN = gql`
  mutation CreateVoiceGrantAccessToken($userId: ID!, $organizationId: ID!) {
    voiceGrantAccessToken(userId: $userId, organizationId: $organizationId) {
      success
      message
      data
    }
  }
`;

export const CREATE_CONFERENCE_CALL = gql`
  mutation createConferenceCall($input: CreateConferenceCallInput!) {
    createConferenceCall(input: $input) {
      success
      message
    }
  }
`;

export const ADD_PARTICIPANT_TO_CONFERENCE_CALL = gql`
  mutation addParticipantToConferenceCall(
    $input: AddParticipantToConferenceCallInput!
  ) {
    addParticipantToConferenceCall(input: $input) {
      success
      message
    }
  }
`;

export const CLAIM_INCOMING_PHONE_CALL = gql`
  mutation claimIncomingPhoneCall(
    $phoneCallId: ID!
    $organizationId: ID!
  ) {
    claimIncomingPhoneCall(phoneCallId: $phoneCallId, organizationId:$organizationId) {
      success
      message
      data {
        ${ClaimSuccessFields}
      }
    }
  }
`;
