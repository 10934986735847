import { gql } from "@apollo/client";
import { questionFields } from "./types";

export const GET_PAGINATED_QUESTIONS = gql`
query GetPaginatedQuestions($input: GetPaginatedQuestionsInput!, $pagination: PaginationInput!){
  response: getPaginatedQuestions(input: $input, pagination: $pagination) {
    success
    message
    data {
      total
      page
      data {
        ${questionFields}
        _count {
          dataPoints
        }
      }
    }
  }
}
`;

export const GET_ASSESSMENT_QUESTIONS = gql`
  query GetAssessment($organizationId: ID!, $formId: String!) {
    getAssessment(organizationId: $organizationId, formId: $formId) {
      success
      message
      data {
        firstStepId
        questions {
          id
          dataId
          isActive
          answerType
          questionText
          questionType
          answerOptions
          stepId
          next {
            id
            response
            nextStepId
            flowTemplateStepId
          }
        }
      }
    }
  }
`;
