import { useMutation, useSubscription } from "@apollo/client";

import {
  GetTextMessagesInput,
  MutationSendTextMessageArgs,
  QueryTextMessagesArgs,
  SendTextMessageResponse,
  SubscriptionCreatedOrUpdatedTextMessageArgs,
  TextMessage,
} from "src/graphql";
import { useInfiniteQuery } from "src/hooks/useInfiniteQuery";
import { SEND_TEXT_MESSAGE } from "./mutations";
import { GET_TEXT_MESSAGES } from "./queries";
import { TEXT_MESSAGE_STATUS_CHANGED } from "./subscriptions";

export const useInfiniteQueryTextMessages = <ElementRef extends Element>(
  input: GetTextMessagesInput,
) =>
  useInfiniteQuery<QueryTextMessagesArgs, TextMessage, ElementRef>({
    document: GET_TEXT_MESSAGES,
    variables: { input },
    fetchPolicy: "no-cache",
  });

export const useMutationSendTextMessage = () =>
  useMutation<
    { sendTextMessage: SendTextMessageResponse },
    MutationSendTextMessageArgs
  >(SEND_TEXT_MESSAGE);

export const useCreatedOrUpdatedTextMessage = (
  organizationId: string,
  hasMemberReadAccess: boolean,
) =>
  useSubscription<
    { createdOrUpdatedTextMessage: TextMessage },
    SubscriptionCreatedOrUpdatedTextMessageArgs
  >(TEXT_MESSAGE_STATUS_CHANGED, {
    variables: { organizationId },
    skip: !hasMemberReadAccess,
    shouldResubscribe: true,

    onData(options) {
      options.client.refetchQueries({ include: [GET_TEXT_MESSAGES] });
    },
  });
