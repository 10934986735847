import {
  IconBuildingEstate,
  Icon as TablerIcon,
  IconBuildingBank,
} from "@tabler/icons-react";
import * as React from "react";
import {
  Home,
  UserPlus,
  Briefcase,
  UserCheck,
  Phone,
  Icon,
  Layers,
  Award,
  Copy,
  PieChart,
  Users,
  ExternalLink,
  Archive,
  Database,
  Tool,
  Lock,
} from "react-feather";

import { AuthContextType } from "./providers";
import { Permission } from "./graphql";

export type RouteConfig = {
  exact?: boolean;
  component: React.LazyExoticComponent<React.ComponentType<unknown>>;
};

export const AuthRoutes: Record<string, RouteConfig> = {
  "/members": {
    exact: false,
    component: React.lazy(() => import("./pages/members")),
  },
  "/users": {
    exact: true,
    component: React.lazy(() => import("./pages/users")),
  },
  "/settings/:subPage": {
    exact: true,
    component: React.lazy(() => import("./pages/user-settings")),
  },
  "/activity/:activityId": {
    exact: true,
    component: React.lazy(() => import("./pages/activity")),
  },
  "/dashboard": {
    exact: true,
    component: React.lazy(() => import("./pages/dashboard")),
  },
  "/organization": {
    exact: false,
    component: React.lazy(() => import("./pages/organization")),
  },
  "/call-center": {
    exact: true,
    component: React.lazy(() => import("./pages/call-center")),
  },
  "/recommendations": {
    exact: false,
    component: React.lazy(() => import("./pages/recommendations")),
  },
  "/carepathways/:templateId": {
    exact: false,
    component: React.lazy(
      () => import("./pages/templates-carepathway/EditCarePathWayView"),
    ),
  },
  "/duplications": {
    exact: false,
    component: React.lazy(() => import("./pages/duplications")),
  },
  "/user-management": {
    exact: true,
    component: React.lazy(() => import("./pages/user-management")),
  },
  "/subcontractor-payers": {
    exact: true,
    component: React.lazy(() => import("./pages/subcontractor-payers")),
  },
  "/organizations-management": {
    exact: true,
    component: React.lazy(() => import("./pages/organizations-managment")),
  },
  "/review-members": {
    exact: true,
    component: React.lazy(() => import("./pages/review-members")),
  },
  "/external-resources": {
    exact: false,
    component: React.lazy(() => import("./pages/external-resources")),
  },
  "/billing/claims": {
    exact: true,
    component: React.lazy(() => import("./pages/billing/bills/index")),
  },
  "/billing/bundles": {
    exact: true,
    component: React.lazy(() => import("./pages/billing/batches/index")),
  },
  "/billing/matching": {
    exact: true,
    component: React.lazy(
      () => import("./pages/billing/payment-reconciliation/index"),
    ),
  },
  "/billing/reports": {
    exact: true,
    component: React.lazy(
      () => import("./pages/billing/provider-reports/index"),
    ),
  },
  "/power-dialer": {
    exact: true,
    component: React.lazy(() => import("./pages/power-dialer")),
  },
  "/data-management": {
    exact: true,
    component: React.lazy(() => import("./pages/data-management")),
  },
  "/data-management/calculations/:calculationId": {
    exact: true,
    component: React.lazy(() => import("./pages/calculation")),
  },
  "/data-management/import-templates/:dataImportTemplateId": {
    exact: true,
    component: React.lazy(() => import("./pages/data-import-template")),
  },
  "/data-management/export-templates/:dataExportTemplateId": {
    exact: true,
    component: React.lazy(() => import("./pages/data-export-template")),
  },
  "/analytics": {
    exact: true,
    component: React.lazy(() => import("./pages/analytics")),
  },
  "/templates/:templateTab": {
    exact: true,
    component: React.lazy(() => import("./pages/templates")),
  },
  "/builder": {
    exact: true,
    component: React.lazy(() => import("./pages/builder")),
  },
  "/builder/:builderType/:builderId/:templateId?": {
    exact: true,
    component: React.lazy(() => import("./pages/builder/builder")),
  },
  "/templates/events/:templateId": {
    exact: true,
    component: React.lazy(
      () => import("./pages/templates-event/EditEventTemplateView"),
    ),
  },
  "/hub-control": {
    exact: true,
    component: React.lazy(() => import("./pages/hub-control")),
  },
  "*": {
    component: React.lazy(() => import("./pages/not-found")),
  },
};

export type RouteDescription = {
  allowedPermissions: Permission[];
  path: string;
  label: string;
  Icon: Icon | TablerIcon;
  omit?: (ctx: AuthContextType) => boolean;
};
export type SideBarSection = {
  children: RouteDescription[];
};

export const RouteStyleConfig = {
  "/analytics": {
    bgColor: "#F6F8FA",
  },
};

export const SidebarSections: SideBarSection[] = [
  {
    children: [
      {
        allowedPermissions: [
          Permission.MemberReadAccess,
          Permission.MemberWriteAccess,
        ],
        path: "/dashboard",
        label: "Dashboard",
        Icon: Home,
      },
      {
        allowedPermissions: [
          Permission.MemberReadAccess,
          Permission.MemberWriteAccess,
        ],
        path: "/members",
        label: "Members",
        Icon: UserCheck,
        omit(ctx) {
          return ctx.hasExactPermissions(Permission.RecommendingProvider);
        },
      },
      {
        allowedPermissions: [
          Permission.MemberReadAccess,
          Permission.MemberWriteAccess,
        ],
        path: "/call-center",
        label: "Call Center",
        Icon: Phone,
        omit(ctx) {
          return !ctx.selectedOrganization?.callerId;
        },
      },
    ],
  },
  {
    children: [
      {
        allowedPermissions: [Permission.RecommendingProvider],
        path: "/review-members",
        label: "Review Members",
        Icon: UserCheck,
        omit: (ctx) =>
          !ctx.hasAnyPermission(Permission.RecommendingProvider) ||
          ctx.currentUser.isSuperAdmin,
      },
    ],
  },
  {
    children: [
      {
        allowedPermissions: [
          Permission.BuilderToolsReadAccess,
          Permission.BuilderToolsWriteAccess,
        ],
        path: "/builder",
        label: "Builder",
        Icon: Tool,
      },
      {
        allowedPermissions: [
          Permission.BuilderToolsReadAccess,
          Permission.BuilderToolsWriteAccess,
        ],
        path: "/templates/activities",
        label: "Templates",
        Icon: Layers,
      },
      {
        allowedPermissions: [
          Permission.RecommendationReadAccess,
          Permission.RecommendationWriteAccess,
        ],
        path: "/recommendations",
        label: "Recommendations",
        Icon: Award,
      },
      {
        allowedPermissions: [Permission.ExternalResourceWriteAccess],
        path: "/external-resources",
        label: "External Resources",
        Icon: ExternalLink,
      },
      {
        allowedPermissions: [Permission.AnalyticsAccess],
        path: "/analytics",
        label: "Analytics",
        Icon: PieChart,
      },
      {
        allowedPermissions: [Permission.UserManagement],
        path: "/user-management",
        label: "User Management",
        Icon: Users,
      },
      {
        allowedPermissions: [
          Permission.DataImportAndExport,
          Permission.DataManagement,
        ],
        path: "/data-management",
        label: "Data Management",
        Icon: Database,
      },
    ],
  },
  {
    children: [
      {
        allowedPermissions: [Permission.OrganizationAccess],
        path: "/users",
        label: "Users",
        Icon: UserPlus,
      },
      {
        allowedPermissions: [
          Permission.ClaimGeneration,
          Permission.ClaimsSubmission,
        ],
        path: "/billing/claims",
        label: "Claims",
        Icon: Archive,
        omit: (ctx) => !ctx.selectedOrganization.billingEnabled,
      },
      {
        allowedPermissions: [Permission.OrganizationAccess],
        path: "/organization",
        label: "Organization",
        Icon: Briefcase,
      },
      {
        allowedPermissions: [Permission.OrganizationAccess],
        path: "/hub-control",
        label: "HUB Control",
        Icon: Lock,
        omit: (ctx) => !ctx.selectedOrganization.hubControlEnabled,
      },
    ],
  },
  {
    children: [
      {
        allowedPermissions: [],
        path: "/duplications",
        label: "Duplicate",
        Icon: Copy,
      },
      {
        allowedPermissions: [],
        path: "/organizations-management",
        label: "Organizations",
        Icon: IconBuildingEstate,
      },
      {
        allowedPermissions: [],
        path: "/subcontractor-payers",
        label: "Subcontractor Payers",
        Icon: IconBuildingBank,
      },
    ],
  },
];
