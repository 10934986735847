import { ActionIcon, Button, Grid } from "@mantine/core";
import MembersTable from "../members-table/MembersTable";
import { useAuthContext } from "src/hooks";
import {
  Group,
  Member,
  useMutationAddMemberToGroup,
  useMutationRemoveMemberFromGroup,
  useQueryGetGroupMembers,
} from "src/graphql";
import { useMemo } from "react";
import toast from "src/libs/toast";
import { DataTableComponent, TableHeader } from "../table";
import { TableColumn } from "react-data-table-component";
import { getFullName } from "src/utils";
import { ArrowLeft, ArrowRight } from "react-feather";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../modal";
import { DontTranslate } from "src/libs/localization/components/DontTranslate";

type MemberGroupMembersModalProps = {
  group: Group;
  onClose: () => void;
};

export const MemberGroupMembersModal = ({
  group,
  onClose,
}: MemberGroupMembersModalProps) => {
  const { selectedOrganizationId } = useAuthContext();

  const [mutationAddMemberToGroup] = useMutationAddMemberToGroup(
    group._id,
    selectedOrganizationId,
  );

  const [mutationRemoveMemberFromGroup] = useMutationRemoveMemberFromGroup(
    group._id,
    selectedOrganizationId,
  );

  const groupMembersQuery = useQueryGetGroupMembers(
    group._id,
    selectedOrganizationId,
  );

  const groupMembers = useMemo(
    () => groupMembersQuery.data?.groupMembers?.data ?? [],
    [groupMembersQuery],
  );

  const handleAddMemberToGroup = async (memberId: string) => {
    const response = await mutationAddMemberToGroup({
      variables: {
        groupId: group._id,
        memberId: memberId,
        organizationId: selectedOrganizationId,
      },
    }).then((response) => response.data?.addMemberToGroup);

    if (response?.success) toast.success("Successfully added member");
    else toast.error("Failed to add member");
  };

  const handleRemoveMemberFromGroup = async (memberId: string) => {
    const response = await mutationRemoveMemberFromGroup({
      variables: {
        groupId: group._id,
        memberId: memberId,
        organizationId: selectedOrganizationId,
      },
    }).then((response) => response.data?.removeMemberFromGroup);

    if (response?.success) toast.success("Successfully Removed Member");
    else toast.error("Failed to remove member");
  };

  const memberColumns: TableColumn<Member>[] = [
    {
      name: "Name",
      cell: (member) => <DontTranslate>{getFullName(member)}</DontTranslate>,
    },
    {
      name: "Remove",
      center: true,
      width: "150px",
      cell: (member) => (
        <ActionIcon
          color="red"
          onClick={() => handleRemoveMemberFromGroup(member._id)}
        >
          <ArrowLeft size="16" />
        </ActionIcon>
      ),
    },
  ];

  return (
    <Modal opened onClose={onClose} size={900}>
      <ModalHeader>Update group members</ModalHeader>

      <ModalBody>
        <Grid>
          <Grid.Col md={6}>
            <MembersTable
              organizationIds={[selectedOrganizationId]}
              noSelect
              noFirstName
              noLastName
              noEmail
              noGroups
              noPhone
              noDOB
              noMedicaidStatus
              noMedicareStatus
              noEHRType
              noPrimaryInsurance
              noSecondaryInsurance
              noRecommendationStatus
              noNotes
              noFile
              noBatchID
              onMemberSelect={(member) =>
                groupMembers.every((m) => m._id !== member._id) &&
                handleAddMemberToGroup(member._id)
              }
              additionalColumns={[
                {
                  name: "Add to group",
                  center: true,
                  width: "150px",
                  cell: (member) => (
                    <ActionIcon
                      color="green"
                      onClick={() => handleAddMemberToGroup(member._id)}
                      disabled={groupMembers.some((m) => m._id === member._id)}
                    >
                      <ArrowRight size="16" />
                    </ActionIcon>
                  ),
                },
              ]}
            />
          </Grid.Col>

          <Grid.Col md={6}>
            <TableHeader noSearch />
            <DataTableComponent
              noDataText="Please assign members to this group"
              data={groupMembers}
              columns={memberColumns}
              noRowsPerPage
              highlightOnHover
              pointerOnHover
              onRowClicked={(member) => handleRemoveMemberFromGroup(member._id)}
            />
          </Grid.Col>
        </Grid>
      </ModalBody>

      <ModalFooter>
        <Button color="red" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
