import { Chip, ChipProps } from "@mantine/core";
import { X } from "react-feather";

type DeletableChipProps = Omit<ChipProps, "onChange"> & {
  onDelete: () => void;
};

export const DeletableChip = ({
  onDelete,
  children,
  ...rest
}: DeletableChipProps) => {
  return (
    <Chip
      onChange={onDelete}
      checked={true}
      {...rest}
      styles={{
        iconWrapper: { display: "none " },
      }}
    >
      {children}
      &nbsp;
      <X size="14" />
    </Chip>
  );
};
