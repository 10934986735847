import { DataPoint } from "src/graphql";

export const dataPointContainsText = (text: string, dataPoint: DataPoint) => {
  return (
    dataPoint.answer.includes(text) ||
    dataPoint.dataId.includes(text) ||
    dataPoint.questionText?.includes(text)
  );
};

export const compareDataPoints = (a: DataPoint, b: DataPoint) => {
  return (
    a.dataId.localeCompare(b.dataId) ||
    (a.questionText ?? "").localeCompare(b.questionText ?? "") ||
    (a.createdAt ?? "").localeCompare(b.createdAt ?? "")
  );
};

export const reservedDataIds: Record<string, string> = {
  D1: "First Name",
  D2: "Last Name",
  D3: "Nick Name",
  D4: "Gender",
  D5: "Date of Birth",
  D6: "Address 1",
  D53: "Address 2",
  D7: "Phone",
  D8: "Sex",
  D13: "Email",
  D60: "Zip Code",
  D25: "Race",
  D26: "Spoken Languages",
  D31: "Primary Insurance Company",
  D44: "Ethnicity",
  D45: "Medicaid Status",
  D46: "Medicare Status",
  D47: "Pronouns",
  D50: "Sexual Orientation",
  D48: "Diagnosis Codes",
  D67: "Name Pronunciation",
  D68: "Pregnancy Status",
  D69: "Primary Client Identification Number (CIN)",
  D70: "Secondary Insurance Company",
  D71: "Secondary Client Identification Number (CIN)",
  D72: "Timezone",
  D73: "Diagnoses",
  D74: "Prescriptions",
  D79: "Groups",
  D80: "Maiden Name or Alias",
  D81: "Medical Record Number",
  D82: "Highest Education Level",
  D83: "Employment Status",
  D84: "Employer Name",
  D85: "Marital Status",
  D86: "People in Household",
  D87: "Children in Household",
  DROQ1: "Read Only Text Question",
};

export const getReservedDataIDFromPlaceholder = (placeholder: string) => {
  return (Object.keys(reservedDataIds) as Array<string>).find(
    (did) => reservedDataIds[did] === placeholder,
  );
};

export const dataIdFormatMessage =
  "Data ID's must be of the form <Capital Letter(s)><Number(s)>, e.g. A1, BGD12, etc...";
