import { DocumentNode, FetchPolicy, useQuery } from "@apollo/client";
import { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { PaginationInput } from "src/graphql";
import { PaginatedQueryResult } from "./usePagination";
import log from "loglevel";

type PaginatedResponse<T extends { _id: string }> = {
  response: { data: PaginatedQueryResult<T> };
};

const PAGE_SIZE = 10;

interface InfiniteQueryHookProps<
  Variables extends { pagination: PaginationInput },
> {
  document: DocumentNode;
  variables: Omit<Variables, "pagination">;
  fetchPolicy?: FetchPolicy;
}

export const useInfiniteQuery = <
  Variables extends { pagination: PaginationInput },
  T extends { _id: string },
  ElementRef extends Element,
>({
  document,
  variables,
  fetchPolicy = "cache-first",
}: InfiniteQueryHookProps<Variables>) => {
  const lastElementRef = useRef<ElementRef | null>(null);
  const currentPageRef = useRef(1);

  const { data: result, fetchMore } = useQuery<PaginatedResponse<T>, Variables>(
    document,
    {
      variables: {
        ...variables,
        pagination: { page: 1, size: PAGE_SIZE },
      } as Variables,
      fetchPolicy,
    },
  );

  const { data, completed } = useMemo((): { data: T[]; completed: boolean } => {
    const response = result?.response.data;

    return {
      data: response?.data || [],
      completed: response?.total === response?.data.length,
    };
  }, [result?.response]);

  // In case a refetch happens, apollo retriggers the first query
  // Hence we would only have data from first page
  // We reset the page counter locally, so that we can refetch as we scroll up
  useEffect(() => {
    const page = result?.response.data.page;
    if (page) currentPageRef.current = page;
  }, [result?.response]);

  // Intersection observer setup
  // useLayoutEffect is used to hook up the intersection observer
  // before it's tracked element is shown to user
  useLayoutEffect(() => {
    const element = lastElementRef.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!element || !hasIOSupport)
      return log.warn("Could not setup intersection observer", {
        element,
        hasIOSupport,
      });
    else log.debug("Setting up intersection observer");

    const observer = new IntersectionObserver(([entry]) => {
      if (completed) return;
      if (!entry.isIntersecting) return;

      fetchMore({
        variables: {
          pagination: { page: currentPageRef.current + 1, size: PAGE_SIZE },
        },
      }).catch((error) => log.error(error));
    });

    observer.observe(element);

    return () => observer.disconnect();
  }, [completed, fetchMore]);

  return { lastElementRef, data, completed };
};
