import { useMemo } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import toast from "src/libs/toast";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Text, Stack, Title } from "@mantine/core";
import { FormikInput } from "src/components";
import { JWT_ERRORS_SET } from "src/constants/errors";
import {
  useLazyQueryResendSignupEmail,
  useMutationSetPassword,
} from "src/graphql";
import {
  OneTimeSessionState,
  useOneTimeSession,
  useURLQueryParams,
} from "src/hooks";
import { LoaderComponent } from "../loader";

const Schema = Yup.object({
  email: Yup.string().required(),
  password: Yup.string().min(8, "Must be 8 or more characters").required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match!")
    .required("Confirm your password!"),
});

export const SignupForm = () => {
  const history = useHistory();
  const params = useURLQueryParams();
  const oneTimeIdToken = params.get("token");
  const email = params.get("email");
  const name = params.get("name");
  const greeting = name ? `Welcome, ${name.split(" ")[0]}!` : "Welcome!";

  // exchange ID token for a one-time 10m TTL session cookie
  const { sessionState, setSessionExpired } = useOneTimeSession(oneTimeIdToken);

  const initialValues = useMemo(
    () => ({
      email,
      password: "",
      confirmPassword: "",
    }),
    [email],
  );

  const [mutationSetPassword, { loading: setPasswordLoading }] =
    useMutationSetPassword();
  const [lazyQueryResendSignupEmail, { loading: resendSignupLoading }] =
    useLazyQueryResendSignupEmail();

  const handleSubmit = async (password: string) => {
    try {
      const res = await mutationSetPassword({
        variables: { password },
      });
      if (!res.data?.setPassword?.success)
        throw new Error(res.data?.setPassword?.message);
      toast.success("Successfully Activated");
      history.push("/login");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const err = error.message
        ? JWT_ERRORS_SET[error.message] || error.message
        : "";
      toast.error(err || "Couldn't Signup! Something went wrong.");
      setSessionExpired();
    }
  };

  const handleResendSignupEmail = async () => {
    if (!email) return; // should never happen, page will redirect if email not set
    try {
      const res = await lazyQueryResendSignupEmail({
        variables: { email },
      });
      if (!res.data?.resendSignupEmail?.success)
        throw new Error(res.data?.resendSignupEmail?.message);
      toast.success("Signup email sent!");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const err = error.message
        ? JWT_ERRORS_SET[error.message] || error.message
        : "";
      toast.error(err || "Couldn't send email! Something went wrong.");
    }
  };

  if (!oneTimeIdToken || !email) return <Redirect to="/login" />;

  if (sessionState === OneTimeSessionState.Loading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <Title order={5} align="center">
        {greeting}
      </Title>

      {/* Bad or expired token, offer to resend signup email */}
      {sessionState === OneTimeSessionState.Expired && (
        <Stack mt="sm" spacing="sm">
          <Text align="center">
            Sorry, this signup link has expired. Request a new signup link?
          </Text>

          <Button
            loading={resendSignupLoading}
            onClick={handleResendSignupEmail}
          >
            Resend Signup Email
          </Button>
        </Stack>
      )}

      {/* Token's good! proceed with completing signup. */}
      {sessionState === OneTimeSessionState.Active && (
        <>
          <Text align="center" mb="25px">
            Set your password now to complete your Pear Suite registration.
          </Text>

          <Formik
            initialValues={initialValues}
            validationSchema={Schema}
            validateOnChange={true}
            enableReinitialize={true}
            onSubmit={(values) => handleSubmit(values.password)}
          >
            {({ isValid, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Stack spacing="sm" mt="sm">
                    <FormikInput
                      label="Email"
                      type="email"
                      name="email"
                      disabled
                      required
                    />

                    <FormikInput
                      label="Password"
                      type="password"
                      name="password"
                      required
                    />

                    <FormikInput
                      label="Confirm password"
                      type="password"
                      name="confirmPassword"
                      required
                    />

                    <Button
                      loading={setPasswordLoading}
                      disabled={!isValid}
                      mt="sm"
                      type="submit"
                    >
                      Sign Up
                    </Button>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
};
