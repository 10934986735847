import { ActionIcon, Tooltip } from "@mantine/core";
import { memo, useState } from "react";
import { Check, Edit2, X } from "react-feather";
import { Position } from "react-flow-renderer";
import { OverflowFadeMask } from "src/components/display";
import { useDetectOverflow } from "src/hooks";
import styled from "styled-components";

import { useFlowSnapshotContext } from "../snapshot-editor/context";
import { NodeEditMask } from "../snapshot-editor/NodeEditMask";
import { StepNodeData } from "../util";
import { getBgColor, getBgHighlightColor, MeasurableNodeProps } from "./shared";
import { StyledEdgeHandle } from "./StyledEdgeHandle";

export const STEP_NODE_TITLE_HEIGHT = 34;

const StepContainer = styled.div<{
  readOnly: boolean;
  visited: boolean;
  isCurrent: boolean;
  isSelectedEditNode: boolean;
  hasUnsavedChanges: boolean;
}>`
  width: 300px;
  display: flex;
  flex-direction: column;
  box-shadow:
    0 0 6px rgba(0, 0, 0, 0.16),
    0 0 6px rgba(0, 0, 0, 0.23);
  border: ${({ isCurrent, hasUnsavedChanges }) =>
    isCurrent ? `2px solid ${getBgColor(hasUnsavedChanges)}` : "none"};
  background-color: white;
  height: 100%;
  opacity: ${({ isCurrent, visited }) => (isCurrent || visited ? 1 : 0.7)};
  border-radius: 3px;
  overflow: hidden;
  cursor: ${({ readOnly }) => (readOnly ? "default" : "inherit")};
`;

const HeaderContainer = styled.div<{
  isCurrent: boolean;
  visited: boolean;
  hasUnsavedChanges: boolean;
}>`
  height: ${STEP_NODE_TITLE_HEIGHT}px;
  background-color: ${({ isCurrent, visited, hasUnsavedChanges }) =>
    isCurrent || visited
      ? getBgColor(hasUnsavedChanges)
      : "var(--color-content-primary-disabled)"};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleContainer = styled.div`
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0px;
  color: white;
`;

const QuestionContentWrapper = styled.div`
  position: relative;
  max-height: 300px;
  overflow: hidden;
`;

const QuestionContent = styled.div`
  padding: 5px;
  margin-bottom: 4px;
  max-height: 100%;
  overflow-y: auto;
`;

const SectionHeader = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
  padding: 0px 3px;
  font-size: 12px;
`;

export const EditNodeButton = styled.div<{
  isEditing: boolean;
  hasUnsavedChanges: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: ${({ isEditing, hasUnsavedChanges }) =>
    isEditing
      ? getBgHighlightColor(hasUnsavedChanges)
      : getBgColor(hasUnsavedChanges)};
  color: white;
  height: ${STEP_NODE_TITLE_HEIGHT}px;
  width: ${STEP_NODE_TITLE_HEIGHT}px;
  border-top-right-radius: 3px;
  cursor: ${({ isEditing }) => (isEditing ? "inherit" : "pointer")};
  pointer-events: ${({ isEditing }) => (isEditing ? "none" : "all")};

  &:hover {
    background-color: ${({ hasUnsavedChanges }) =>
      getBgHighlightColor(hasUnsavedChanges)};
  }

  &:active {
    background-color: ${({ hasUnsavedChanges }) =>
      getBgColor(hasUnsavedChanges)};
  }
`;

const EditControlsContainer = styled.div<{ isEditing: boolean }>`
  display: flex;
  position: absolute;
  top: ${({ isEditing }) => (isEditing ? "calc(100% + 10px)" : "100%")};
  right: 0px;
  opacity: ${({ isEditing }) => (isEditing ? 1 : 0)};
  pointer-events: ${({ isEditing }) => (isEditing ? "all" : "none")};
  transition: all 0.2s;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

export const SnapshotStepFlowNode = memo(
  ({ data, id, measuringProps }: MeasurableNodeProps<StepNodeData>) => {
    const {
      step: { question },
      isMultiOutput,
      isInputNode,
    } = data;
    const {
      currentStepId,
      selectedEditNodeId,
      currentStepHasChanged,
      onRequestEditStep,
      onRequestCommitChanges,
      onDiscardChanges,
    } = useFlowSnapshotContext();
    const [overflowBottomedOut, setOverflowBottomedOut] = useState(false);

    const [titleElementRef, titleElementOverflowing] =
      useDetectOverflow<HTMLDivElement>();
    const [questionElementRef, questionElementOverflowing] =
      useDetectOverflow<HTMLDivElement>();

    const isMasked = !!selectedEditNodeId && id !== selectedEditNodeId;
    const isEditing = id === selectedEditNodeId;
    const isCurrent = id === currentStepId;
    const hasUnsavedChanges =
      !!data.hasUnsavedChanges || (isCurrent && currentStepHasChanged);

    const handleOverflowScroll = (e: React.UIEvent<HTMLDivElement>) => {
      const bottomedOut =
        e.currentTarget.scrollHeight - e.currentTarget.scrollTop >=
        e.currentTarget.clientHeight;
      if (bottomedOut !== overflowBottomedOut)
        setOverflowBottomedOut(bottomedOut);
    };

    return (
      <StepContainer
        ref={measuringProps?.innerRef}
        className={"nodrag"}
        visited={!!data.visited}
        isCurrent={isCurrent}
        readOnly={true}
        isSelectedEditNode={id === selectedEditNodeId}
        hasUnsavedChanges={hasUnsavedChanges}
      >
        <NodeEditMask isEnabled={isMasked} />
        <HeaderContainer
          isCurrent={currentStepId === id}
          visited={!!data.visited}
          hasUnsavedChanges={hasUnsavedChanges}
        >
          <div style={{ minWidth: 0 }}>
            <Tooltip
              label={`${question.dataId ?? "-"} | ${question.questionTitle}`}
              multiline
              width={300}
              withArrow
              disabled={!titleElementOverflowing}
              color={getBgColor(hasUnsavedChanges)}
              position="top-start"
              withinPortal
            >
              <TitleContainer ref={titleElementRef}>
                {question.dataId && (
                  <>
                    <strong>{question.dataId}</strong>
                    &nbsp;|&nbsp;
                  </>
                )}
                {question.questionTitle}
              </TitleContainer>
            </Tooltip>
          </div>
          {(!!data.visited || isCurrent) && (
            <EditNodeButton
              isEditing={isEditing}
              onClick={() => onRequestEditStep(id)}
              hasUnsavedChanges={hasUnsavedChanges}
            >
              <Edit2 size={14} />
            </EditNodeButton>
          )}
        </HeaderContainer>

        <QuestionContentWrapper>
          <QuestionContent
            ref={questionElementRef}
            className={questionElementOverflowing ? "nowheel" : undefined}
            onScroll={
              questionElementOverflowing ? handleOverflowScroll : undefined
            }
            dangerouslySetInnerHTML={{ __html: question.questionText }}
          />
          <OverflowFadeMask
            $visible={questionElementOverflowing && !overflowBottomedOut}
          />
        </QuestionContentWrapper>

        {!isInputNode && (
          <StyledEdgeHandle
            type={"target"}
            position={Position.Left}
            isConnectable={false}
          />
        )}

        {!isMultiOutput && (
          <StyledEdgeHandle
            type={"source"}
            position={Position.Right}
            isConnectable={false}
          />
        )}

        <SectionHeader style={{ marginBottom: "0.25rem" }}>
          Answers
        </SectionHeader>

        <EditControlsContainer isEditing={isEditing}>
          <ActionIcon onClick={onDiscardChanges} color="red" variant="filled">
            <X size="18" />
          </ActionIcon>

          <Tooltip label="Save" withinPortal withArrow>
            <ActionIcon
              onClick={onRequestCommitChanges}
              color="green"
              variant="filled"
            >
              <Check size="18" />
            </ActionIcon>
          </Tooltip>
        </EditControlsContainer>
      </StepContainer>
    );
  },
);
