import { gql } from "@apollo/client";
import { DataFields, DataPointIdWithAnswerTypeFields } from "./types";

export const GET_DATA_BY_MEMBER = gql`
  query DataByMember($memberId: ID!, $organizationId:ID!) {
        dataByMember(memberId: $memberId, organizationId:$organizationId ) {
          success
          message
          data {
                ${DataFields}
          }
        }
      }
`;

export const GET_DATA_BY_ACTIVITY = gql`
  query DataByActivity($activityId: ID!, $memberId: ID, $organizationId:ID!) {
        dataByActivity(activityId: $activityId, memberId: $memberId, organizationId:$organizationId) {
          success
          message
          data {
                ${DataFields}
          }
        }
      }
`;

export const GET_DATA_IDS_WITH_ANSWER_TYPE_BY_ORGANIZATION_ID = gql`
  query DataIdsWithAnswerTypesByOrganizationId($organizationId: ID!, $includeDemographicOptions: Boolean) {
        dataIdsWithAnswerTypesByOrganizationId(organizationId: $organizationId, includeDemographicOptions: $includeDemographicOptions) {
          success
          message
          data {
              ${DataPointIdWithAnswerTypeFields}
          }
        }
      }
`;
