import { useMutation, useQuery } from "@apollo/client";

import {
  CarePathwayResponse,
  CarePathwaysResponse,
  MutationDeleteCarePathwayArgs,
  QueryCarePathwaysByMemberIdArgs,
} from "../schemaTypes";
import { CARE_PATHWAYS_BY_MEMBER_ID } from "./queries";
import { DELETE_CARE_PATHWAY } from "./mutations";

export const useQueryCarePathwaysByMemberId = (
  organizationId: string,
  memberId: string,
  episodeOfCareId?: string,
) =>
  useQuery<
    { carePathwaysByMemberId: CarePathwaysResponse },
    QueryCarePathwaysByMemberIdArgs
  >(CARE_PATHWAYS_BY_MEMBER_ID, {
    variables: {
      memberId,
      episodeOfCareId,
      organizationId,
    },
  });

export const useMutationDeleteCarePathway = () =>
  useMutation<
    {
      deleteCarePathway: CarePathwayResponse;
    },
    MutationDeleteCarePathwayArgs
  >(DELETE_CARE_PATHWAY);
