/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Creates a type based off of TableColumn<T> config values, which results in a map of column names
 * that can be disabled with a simple boolean. Useful for flexibly hiding columns in tables
 * without having to hard code a bunch of props.
 *
 * Caveat:
 * - Does not work for columns which have a 'Name' property that is set to a non-string value;
 *   for outlier columns with a component for the header, you can still use the column config `omit`
 *   property with a component prop.
 */

import { TableColumn } from "react-data-table-component";

export type OmitColumnConfig<T extends TableColumn<any>[]> = Partial<
  Record<OmittableColumns<T>, boolean>
>;

type OmittableColumns<T extends TableColumn<any>[]> =
  T[number] extends TableColumn<any>
    ? T[number]["name"] extends string
      ? T[number]["name"]
      : never
    : never;

export const withOmittedColumns = <T extends TableColumn<any>[]>(
  columns: T,
  omit: OmitColumnConfig<T>,
) =>
  columns.map(({ name, ...rest }) => ({
    ...rest,
    name,
    omit:
      typeof name === "string" && name in omit
        ? omit[name as OmittableColumns<T>] || rest.omit
        : rest.omit,
  }));
