import { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

export const useHistorySearch = (setState: (search: string) => void) => {
  const history = useHistory();

  const search = useMemo(() => {
    const query = new URLSearchParams(history.location.search);
    return query.get("query") ?? "";
  }, [history.location.search]);

  useEffect(() => {
    const timeout = setTimeout(() => setState(search ?? ""), 100);
    return () => clearTimeout(timeout);
    // NOTE: Ideally, we should not ignore the exhaustive-deps warning
    // but in this case, the setState is not memoized and causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const setSearch = (search: string) => {
    history.replace({ search: `query=${search}` });
  };

  return { search, setSearch };
};
