import { useQuery } from "@apollo/client";
import {
  GetTitledReferencesResponse,
  LockingReference,
  QueryTitledReferencesArgs,
} from "../schemaTypes";
import { GET_TITLED_LOCKING_REFERENCES } from "./queries";

export const useQueryTitledReferences = (
  organizationId: string,
  references: LockingReference[],
) =>
  useQuery<
    { titledReferences: GetTitledReferencesResponse },
    QueryTitledReferencesArgs
  >(GET_TITLED_LOCKING_REFERENCES, {
    variables: { input: { organizationId, references } },
  });
