import { gql } from "@apollo/client";
import { recommendationsFields } from "./types";

export const GET_RECOMMENDATIONS = gql`
  query RecommendationsByOrganizationId($organizationId: ID!) {
    recommendationsByOrganizationId(organizationId: $organizationId) {
      success
      message
      data {
       ${recommendationsFields}
      }
    }
  }
`;

export const GET_RECOMMENDATION_BY_ID = gql`
  query RecommendationById($recommendationId: ID!, $organizationId: ID!) {
    recommendationById(recommendationId: $recommendationId, organizationId: $organizationId) {
      success
      message
      data {
       ${recommendationsFields}
      }
    }
  }
`;

export const GET_MEMBER_RECOMMENDATIONS = gql`
  query RecommendationsByMemberId($memberId: ID!, $episodeOfCareId: String, $organizationId:ID!) {
    memberRecommendations(memberId: $memberId, episodeOfCareId: $episodeOfCareId,organizationId:$organizationId) {
      success
      message
      data {
       ${recommendationsFields}
      }
    }
  }
`;
