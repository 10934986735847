/**
 * This library wrap the original react hot toast library
 * to make mgiration from that package easier
 */
import type { MantineColor } from "@mantine/core";
import { notifications, NotificationProps } from "@mantine/notifications";

// this is modeled after `react-hot-toast` api
type PearSuiteNotificationOptions = {
  duration?: number;
  icon?: React.ReactNode | string;
  style?: NotificationProps["style"];
};

const showPearSuiteNotification = (
  color: MantineColor,
  message: React.ReactNode | string,
  options?: PearSuiteNotificationOptions,
) => {
  notifications.show({
    color,
    message,
    autoClose: options?.duration,
    icon: options?.icon,
    style: options?.style,
  });
};

// this is a toast object
const toast = {
  success(
    message: React.ReactNode | string,
    options?: PearSuiteNotificationOptions,
  ) {
    showPearSuiteNotification("green", message, options);
  },
  error(
    message: React.ReactNode | string,
    options?: PearSuiteNotificationOptions,
  ) {
    showPearSuiteNotification("red", message, options);
  },
};

export default toast;
