import { gql } from "@apollo/client";
import {
  carePathwayTemplateMinimalFields,
  carePathwayTemplateFields,
  carePathwayFields,
} from "./types";

export const GET_CAREPATHWAY_TEMPLATES = gql`
  query CarePathwayTemplatesByOrganizationId($organizationId: ID!) {
    carePathwayTemplatesByOrganizationId(organizationId: $organizationId) {
      success
      message
      data {
       ${carePathwayTemplateMinimalFields}
      }
    }
  }
`;

export const GET_CAREPATHWAY_TEMPLATES_BY_MEMBER_ID = gql`
  query CarePathwayTemplatesByMemberId($memberId: ID!,$organizationId:ID!) {
    carePathwayTemplatesByMemberId(memberId: $memberId, organizationId:$organizationId) {
      success
      message
      data {
       ${carePathwayTemplateMinimalFields}
      }
    }
  }
`;

export const GET_CAREPATHWAY_TEMPLATE_BY_ID = gql`
  query CarePathwayTemplateById($carePathwayTemplateId: ID!, $carePathwayId: String, $organizationId: ID!) {
    carePathwayTemplateById(carePathwayTemplateId: $carePathwayTemplateId, carePathwayId: $carePathwayId, organizationId: $organizationId) {
      success
      message
      data {
       ${carePathwayTemplateFields}
      }
    }
  }
`;

export const GET_CAREPATHWAY_TEMPLATE_DATA_BY_MEMBER_ID = gql`
  query CarePathwayTemplateDataByMemberId(
    $carePathwayId: ID!
    $memberId: ID!
    $organizationId: ID!
    ) {
    carePathwayTemplateDataByMemberId(
      carePathwayId: $carePathwayId
      memberId: $memberId
      organizationId: $organizationId) {
      success
      message
      data {
       ${carePathwayFields}
      }
    }
  }
`;
