import { Badge, Button, Group, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ActivityStatus } from "src/graphql";
import { useAuthContext } from "src/hooks";
import { useStartActivity } from "src/hooks/useStartActivity";
import { useUserCurrentActivtyStatus } from ".";
import { DontTranslate } from "src/libs/localization/components/DontTranslate";

export const MastheadActivityStatus = () => {
  const { selectedOrganization } = useAuthContext();
  const status = useUserCurrentActivtyStatus();
  const startActivity = useStartActivity();

  const activity = useMemo(() => status?.activity, [status]);
  const memberNames = useMemo(
    () => activity?.members.map((member) => member.memberDisplayName) ?? [],
    [activity],
  );

  if (selectedOrganization.timeTrackingEnabled === false) return null;

  if (status?.error) return <ErrorStatus error={status.error} />;

  if (activity?.status === ActivityStatus.Scheduled)
    return (
      <EncounterWithMemberStatus
        date={new Date(activity.date)}
        names={memberNames}
        onClick={() => startActivity(activity)}
      />
    );

  if (activity?.status === ActivityStatus.InProgress)
    return (
      <OngoingActivityStatus
        duration={activity.duration}
        names={memberNames}
        onClick={activity.endActivity}
      />
    );

  return null;
};

const ErrorStatus = ({ error }: { error: string }) => {
  return (
    <Group px="md">
      <Badge>ERROR</Badge>

      <Text size="sm" color="white">
        {error}
      </Text>
    </Group>
  );
};

const formatDuration = (seconds: number) =>
  dayjs.duration(seconds, "seconds").format("HH:mm:ss");

const EncounterWithMemberStatus = ({
  date,
  names,
  onClick,
}: {
  date: Date;
  names: string[];
  onClick: () => void;
}) => {
  const getSecondsLeftUntilActivity = useCallback(
    () => dayjs(date).diff(undefined, "seconds"),
    [date],
  );

  const [time, setTime] = useState(getSecondsLeftUntilActivity);

  useEffect(() => {
    const updateLoop = setInterval(
      () => setTime(getSecondsLeftUntilActivity),
      1000,
    );

    return () => clearInterval(updateLoop);
  }, [getSecondsLeftUntilActivity]);

  return (
    <Group px="md">
      <Button
        rightIcon={time < 0 ? <Badge color="red">NOW</Badge> : null}
        onClick={onClick}
      >
        Start activity with
        <DontTranslate>
          &nbsp;{names.length === 1 ? names[0] : `${names.length} members`}
        </DontTranslate>
      </Button>
    </Group>
  );
};

const OngoingActivityStatus = ({
  duration,
  names,
  onClick,
}: {
  duration: number;
  names: string[];
  onClick: () => void;
}) => {
  const [currentDuration, setCurrentDuration] = useState(duration);

  useEffect(() => {
    setCurrentDuration(duration);
  }, [duration]);

  useEffect(() => {
    const updateLoop = setInterval(
      () => setCurrentDuration((value) => value + 1),
      1000,
    );

    return () => clearInterval(updateLoop);
  }, []);

  return (
    <Group px="md">
      <Button
        rightIcon={<Badge color="red">{formatDuration(currentDuration)}</Badge>}
        onClick={onClick}
      >
        End activity with
        <DontTranslate>
          &nbsp;{names.length === 1 ? names[0] : `${names.length} members`}
        </DontTranslate>
      </Button>
    </Group>
  );
};
