import { Button, Checkbox, Group, Slider, Stack, Text } from "@mantine/core";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import log from "loglevel";
import { useMemo } from "react";
import toast from "src/libs/toast";
import {
  Group as PearSuiteGroup,
  UpdateGroupInput,
  useMutationCreateMemberGroup,
  useMutationUpdateMemberGroup,
} from "src/graphql";
import { useAuthContext } from "src/hooks";
import * as Yup from "yup";
import { FormikInput, StyledLabel } from "../input";
import { FormikDateInput } from "../input/FormikDateInput";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../modal";

type GroupFormValues = Yup.InferType<typeof schema>;

enum GroupDuration {
  Forever = "Forever",
  TillDate = "TillDate",
  TillRelativeTime = "TillRelativeTime",
}

const durationOptions = [
  GroupDuration.Forever,
  GroupDuration.TillDate,
  GroupDuration.TillRelativeTime,
];

const getDurationFromGroup = (group?: PearSuiteGroup) => {
  if (!group) return GroupDuration.Forever;

  if (group.endAt) return GroupDuration.TillDate;
  if (group.endInDays || group.endInWeeks || group.endInMonths)
    return GroupDuration.TillRelativeTime;

  return GroupDuration.Forever;
};

const schema = Yup.object({
  title: Yup.string().required("Title for group is required"),
  duration: Yup.mixed().oneOf(durationOptions),

  endInDays: Yup.number().nullable(true).default(0),
  endInWeeks: Yup.number().nullable(true).default(0),
  endInMonths: Yup.number().nullable(true).default(0),

  endAt: Yup.date().when("duration", {
    is: GroupDuration.TillDate,
    then: (schema) => schema.required(),
    otherwise: (_) => Yup.mixed(),
  }),
});

type MemberGroupFormModalProps = {
  values?: PearSuiteGroup;
  opened: boolean;
  onClose: () => void;
};

export const MemberGroupFormModal = ({
  values,
  opened,
  onClose,
}: MemberGroupFormModalProps) => {
  const { selectedOrganizationId } = useAuthContext();

  const initialValues = useMemo(
    () =>
      ({
        ...values,
        endAt: values?.endAt ? dayjs(values.endAt).toDate() : undefined,
        duration: getDurationFromGroup(values),
      }) as GroupFormValues,
    [values],
  );

  const [mutationUpdateGroup, { loading: updating }] =
    useMutationUpdateMemberGroup(selectedOrganizationId);

  const [mutationCreateGroup, { loading: creating }] =
    useMutationCreateMemberGroup(selectedOrganizationId);

  const handleSubmit = async (formValues: GroupFormValues) => {
    try {
      const response = values?._id
        ? await mutationUpdateGroup({
            variables: {
              id: values._id,
              organizationId: selectedOrganizationId,
              input: unwrapValues(formValues),
            },
          }).then((response) => response.data?.updateGroup)
        : await mutationCreateGroup({
            variables: {
              input: {
                organizationId: selectedOrganizationId,
                ...unwrapValues(formValues),
              },
            },
          }).then((response) => response.data?.createGroup);

      if (response?.success !== true) throw new Error(response?.message);

      toast.success("Group updated successfully!");
      onClose();
    } catch (error) {
      log.error(error);
      toast.error("Couldn't update group! Something went wrong.");
    }
  };

  return (
    <Modal opened={opened} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({
          values: { duration, endInDays, endInWeeks, endInMonths },
          setFieldValue,
        }) => (
          <Form>
            <ModalHeader>
              {values?._id ? "Update group" : "Create group"}
            </ModalHeader>

            <ModalBody spacing="md">
              <FormikInput type="text" name="title" label="Title" />

              <Checkbox
                label="Remove member automatically from group"
                checked={duration !== GroupDuration.Forever}
                onChange={(event) =>
                  setFieldValue(
                    "duration",
                    event.target.checked
                      ? GroupDuration.TillDate
                      : GroupDuration.Forever,
                  )
                }
              />

              {duration !== GroupDuration.Forever && (
                <Checkbox
                  label="Remove member automatically from group after specified time has passed since joining"
                  checked={duration === GroupDuration.TillRelativeTime}
                  onChange={(event) =>
                    setFieldValue(
                      "duration",
                      event.target.checked
                        ? GroupDuration.TillRelativeTime
                        : GroupDuration.TillDate,
                    )
                  }
                />
              )}

              {duration === GroupDuration.TillDate && (
                <FormikDateInput name="endAt" label="Select a specific date" />
              )}

              {duration === GroupDuration.TillRelativeTime && (
                <div>
                  <StyledLabel>Select relative time</StyledLabel>
                  <Group w="100%" pt="xs">
                    <Stack
                      align="stretch"
                      style={{ flexGrow: 1, flexBasis: 0 }}
                    >
                      <Slider
                        min={0}
                        max={7}
                        step={1}
                        value={endInDays ?? 0}
                        onChange={(x) => setFieldValue("endInDays", x)}
                      />
                      <Text size="sm" color="green" align="center">
                        {endInDays} day{(endInDays ?? 0 > 1) ? "s" : ""}
                      </Text>
                    </Stack>

                    <Stack
                      align="stretch"
                      style={{ flexGrow: 1, flexBasis: 0 }}
                    >
                      <Slider
                        min={0}
                        max={4}
                        step={1}
                        value={endInWeeks ?? 0}
                        onChange={(x) => setFieldValue("endInWeeks", x)}
                      />
                      <Text size="sm" color="green" align="center">
                        {endInWeeks} week{(endInWeeks ?? 0 > 1) ? "s" : ""}
                      </Text>
                    </Stack>

                    <Stack
                      align="stretch"
                      style={{ flexGrow: 1, flexBasis: 0 }}
                    >
                      <Slider
                        min={0}
                        max={12}
                        step={1}
                        value={endInMonths ?? 0}
                        onChange={(x) => setFieldValue("endInMonths", x)}
                      />
                      <Text size="sm" color="green" align="center">
                        {endInMonths} month{(endInMonths ?? 0 > 1) ? "s" : ""}
                      </Text>
                    </Stack>
                  </Group>
                </div>
              )}
            </ModalBody>

            <ModalFooter>
              <Button variant="outline" color="red" onClick={onClose}>
                Cancel
              </Button>

              <Button type="submit" loading={updating || creating}>
                {values?._id ? "Update" : "Create"}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const unwrapValues = (values: GroupFormValues): UpdateGroupInput => {
  switch (values.duration) {
    case GroupDuration.TillDate:
      return {
        title: values.title,
        endAt: dayjs(values.endAt).toISOString(),
        endInDays: 0,
        endInWeeks: 0,
        endInMonths: 0,
      };

    case GroupDuration.TillRelativeTime:
      return {
        title: values.title,
        endAt: undefined,
        endInDays: values.endInDays ?? 0,
        endInWeeks: values.endInWeeks ?? 0,
        endInMonths: values.endInMonths ?? 0,
      };

    case GroupDuration.Forever:
    default:
      return { title: values.title };
  }
};
