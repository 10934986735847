import {
  Button,
  Group,
  Pagination,
  ScrollArea,
  Stack,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useState } from "react";
import { Plus, Search } from "react-feather";
import { DeleteModal, LoaderComponent } from "src/components";
import { AnswerType, FlowType, Question, QuestionType } from "src/graphql";
import {
  useMutationDeleteQuestion,
  usePaginatedQueryQuestions,
} from "src/graphql/Question/hooks";
import { useAuthContext } from "src/hooks";
import toast from "src/libs/toast";
import { QuestionEditorModal } from "./QuestionEditorModal";
import { QuestionListEntry } from "./QuestionListEntry";

export const BASE_QUESTION_ID = "newQuestionId";
export const makeBaseQuestion = (
  selectedOrganizationId: string,
  flowType: FlowType = FlowType.Script,
): Question => ({
  _id: BASE_QUESTION_ID,
  answerOptions: [],
  answerType: AnswerType.Boolean,
  dataId: "",
  organizationId: selectedOrganizationId,
  questionText: "",
  questionTitle: "",
  lockingReferences: [],
  questionType:
    flowType === FlowType.Journey ? QuestionType.Step : QuestionType.Question,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
});

type QuestionListPaneProps = {
  flowType: FlowType;
  onUpdateQuestion: (question: Question) => void;
};

export const QuestionListPane = ({
  flowType,
  onUpdateQuestion,
}: QuestionListPaneProps) => {
  const theme = useMantineTheme();
  const { selectedOrganizationId } = useAuthContext();
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(
    null,
  );

  const [requestedDeleteQuestion, setRequestedDeleteQuestion] =
    useState<Question | null>(null);

  const questionType =
    flowType === FlowType.Journey ? QuestionType.Step : QuestionType.Question;

  const questionsQuery = usePaginatedQueryQuestions({
    organizationId: selectedOrganizationId,
    type: questionType,
  });

  const [mutationDeleteQuestion] = useMutationDeleteQuestion();

  const handleConfirmDelete = async (question: Question) => {
    try {
      const deleteResponse = await mutationDeleteQuestion({
        variables: {
          input: {
            _id: question._id,
            organizationId: selectedOrganizationId,
          },
        },
      });

      if (deleteResponse.data?.deleteQuestion?.success === false)
        throw new Error("Failed to delete question");

      toast.success("Question deleted!");
      setRequestedDeleteQuestion(null);
    } catch (err) {
      const defaultErrorMessage = "Failed to delete question";

      toast.error(
        err instanceof Error
          ? (err.message ?? defaultErrorMessage)
          : defaultErrorMessage,
      );
    }
  };

  return (
    <Stack
      h="100%"
      w={300}
      spacing="xs"
      style={{ borderLeft: `1px solid ${theme.colors.gray[1]}` }}
    >
      {/* List Header */}
      <Group position="apart" mt="sm" mx="sm">
        <strong>{flowType === FlowType.Journey ? "Steps" : "Questions"}</strong>

        <Button
          size="xs"
          leftIcon={<Plus size="18" />}
          onClick={() =>
            setSelectedQuestion(
              makeBaseQuestion(selectedOrganizationId, flowType),
            )
          }
        >
          Add
        </Button>
      </Group>

      {/* Question Search */}
      <TextInput
        mx="sm"
        placeholder={
          flowType === FlowType.Journey
            ? "Search steps... "
            : "Search questions..."
        }
        icon={<Search size={14} />}
        value={questionsQuery.searchTerm}
        onChange={(e) => questionsQuery.setSearchTerm(e.currentTarget.value)}
      />

      {/* No Questions Message */}
      {questionsQuery.data?.total === 0 && (
        <Stack h="100%" justify="center" align="center">
          {questionType === QuestionType.Question
            ? "Add a Question to get started!"
            : "Add a Step to get started!"}
        </Stack>
      )}

      {/* Loader */}
      {questionsQuery.loading && !questionsQuery.data?.data.length && (
        <Stack h="100%" justify="center" align="center">
          <LoaderComponent />
        </Stack>
      )}

      {/* Questions List */}
      <ScrollArea
        w="100%"
        h="100%"
        display={questionsQuery.data?.data ? undefined : "none"}
      >
        <Stack spacing="xs" px="sm">
          {questionsQuery.data?.data.map((question) =>
            question._id === BASE_QUESTION_ID ? null : (
              <QuestionListEntry
                key={question._id}
                question={question}
                onRequestEdit={(q) => setSelectedQuestion(q)}
                onRequestDelete={(q) => setRequestedDeleteQuestion(q)}
              />
            ),
          )}
        </Stack>
      </ScrollArea>

      <Pagination
        size="sm"
        mx="sm"
        mb="sm"
        noWrap
        grow
        style={{ justifySelf: "stretch" }}
        disabled={questionsQuery.loading}
        total={questionsQuery.totalPages}
        value={questionsQuery.page}
        onChange={questionsQuery.setPage}
      />

      {selectedQuestion && (
        <QuestionEditorModal
          selectedQuestion={selectedQuestion}
          onUpdateQuestion={onUpdateQuestion}
          onRequestClose={() => setSelectedQuestion(null)}
        />
      )}

      {requestedDeleteQuestion && (
        <DeleteModal
          modalIsOpen={!!requestedDeleteQuestion}
          deleteFunction={handleConfirmDelete}
          setModalIsOpen={() => setRequestedDeleteQuestion(null)}
          row={requestedDeleteQuestion}
        />
      )}
    </Stack>
  );
};
