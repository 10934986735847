import { gql } from "@apollo/client";
import { UserFields } from "./types";

export const UPDATE_USER = gql`
  mutation ($input: UpdateUserInput!) {
    updateUser(input: $input) {
      success
      message
      data {
        ${UserFields}
      }
    }
  }
`;

export const CHANGE_USER_PASSWORD = gql`
  mutation ($userId: ID!, $newPassword: String!) {
    changeUserPassword(userId: $userId, newPassword: $newPassword) {
      success
      message
      data {
        ${UserFields}
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation ($userId: ID!, $organizationId: ID!) {
    deleteUser(userId: $userId, organizationId: $organizationId) {
      success
      message
    }
  }
`;

export const RETIRE_USER = gql`
  mutation ($userId: ID!, $organizationId: ID!) {
    retireUser(userId: $userId, organizationId: $organizationId) {
      success
      message
      data {
        ${UserFields}
      }
    }
  }
`;

export const REACTIVATE_USER = gql`
  mutation ($userId: ID!, $organizationId: ID!) {
    reactivateUser(userId: $userId, organizationId: $organizationId) {
      success
      message
      data {
        ${UserFields}
      }
    }
  }
`;

export const ASSIGN_USER_TO_MEMBER = gql`
  mutation AssignUserToMember($input: UserMemberInput!) {
    assignUserToMember(input: $input) {
      success
      message
    }
  }
`;

export const UNASSIGN_USER_FROM_MEMBER = gql`
  mutation UnassignUserFromMember($input: UserMemberInput!) {
    unassignUserFromMember(input: $input) {
      success
      message
    }
  }
`;

export const BULK_ASSIGN_USERS = gql`
  mutation BulkAssignUsersToMembers($input: BulkUserAssignmentInput!) {
    bulkAssignUsersToMembers(input: $input) {
      success
      message
    }
  }
`;

export const SEND_IN_CALL_PING = gql`
  mutation SendInCallPing {
    inCallPing {
      success
      message
    }
  }
`;

export const LINK_RECOMMENDING_PROVIDER_TO_ORGANIZATION = gql`
  mutation LinkRecommendingProviderToOrganization(
    $input: LinkRecommendingProviderInput!
  ) {
    linkRecommendingProviderToOrganization(input: $input) {
      success
      message
      data {
        ${UserFields}
      }
    }
  }
`;

export const UNLINK_RECOMMENDING_PROVIDER_FROM_ORGANIZATION = gql`
  mutation UnlinkRecommendingProviderFromOrganization(
    $input: UnlinkRecommendingProviderInput!
  ) {
    unlinkRecommendingProviderFromOrganization(input: $input) {
      success
      message
      data {
        ${UserFields}
      }
    }
  }
`;
