import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "src/libs/toast";
import { Button, Group, Stack, Text } from "@mantine/core";
import { LoaderComponent, DeleteModal } from "src/components";
import {
  useQueryGoals,
  useMutationUpdateGoalStatus,
  useMutationDeleteGoal,
  GoalStatus,
} from "src/graphql";
import { CreateGoalModal } from "../CreateGoalModal";
import log from "loglevel";
import { arrayToKeyedObj } from "src/utils";
import { GoalDetailControls } from "./GoalDetailControls";
import { GoalList, GoalStatusTab, GoalStatusToTab } from "./GoalList";
import { GoalDetail } from "./GoalDetail";
import { UpdateGoalModal } from "../UpdateGoalModal";
import { useAuthContext } from "src/hooks";

type TabbedGoalsPaneProps = {
  memberId: string;
  userId: string;
  organizationId: string;
  small?: boolean;
  activityId?: string;
  multiMemberActivity?: boolean;
  readonly?: boolean;
  episodeOfCareId?: string;
};

export const TabbedGoalsPane = ({
  memberId,
  userId,
  organizationId,
  small,
  activityId,
  multiMemberActivity,
  readonly = false,
  episodeOfCareId,
}: TabbedGoalsPaneProps) => {
  const { memberPageReadOnly, selectedOrganizationId } = useAuthContext();
  const [pendingDeleteRequest, setPendingDeleteRequest] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [selectedGoalId, setSelectedGoalId] = useState("");
  const [selectedTab, setSelectedTab] = useState<GoalStatusTab>("current");

  const { loading: goalsLoading, data: goalsResponse } = useQueryGoals(
    selectedOrganizationId,
    memberId,
    episodeOfCareId,
  );

  const [mutationUpdateGoalStatus] = useMutationUpdateGoalStatus();
  const [mutationDeleteGoal] = useMutationDeleteGoal();

  // set up state from response
  const [goals, goalsById, hasGoals] = useMemo(() => {
    const goals = Object.values(goalsResponse?.goals.data ?? []);
    const goalsById = arrayToKeyedObj(goals, "_id");
    const hasGoals = !!goals.length;
    return [goals, goalsById, hasGoals];
  }, [goalsResponse]);

  // selects a goal and tab if possible if no goals are selected
  const selectDefaultGoal = useCallback(() => {
    const goalStatusFilter: Partial<GoalStatus>[] =
      selectedTab === "current"
        ? [GoalStatus.Active, GoalStatus.Unresolved]
        : [GoalStatus.Completed, GoalStatus.Resolved];

    // default to selected tab
    let nextGoal = goals.find((goal) => goalStatusFilter.includes(goal.status));

    // if none in selected tab, check second
    if (!nextGoal)
      nextGoal = goals.find((goal) => !goalStatusFilter.includes(goal.status));

    setSelectedTab(
      nextGoal?.status ? GoalStatusToTab[nextGoal.status] : "current",
    );
    setSelectedGoalId(nextGoal?._id ?? "");
  }, [goals, selectedTab]);

  const selectedGoal = goalsById[selectedGoalId];

  // if goals are loaded & no goal is selected, select a default
  useEffect(() => {
    if (goals.length && !selectedGoal) {
      selectDefaultGoal();
    }
  }, [goals, selectedGoal, selectDefaultGoal]);

  const handleDeleteGoal = async () => {
    setPendingDeleteRequest(true);
    try {
      const res = await mutationDeleteGoal({
        variables: {
          input: {
            goalId: selectedGoal._id,
            memberId: selectedGoal.memberId,
            organizationId: selectedOrganizationId,
          },
        },
      });

      if (res.errors?.length || res.data?.deleteGoal?.success === false) {
        throw new Error("Failed to persist ActionItem update");
      }

      toast.success("Goal successfully deleted!");
      selectDefaultGoal();
    } catch (e) {
      log.error(e);
      toast.error("Couldn't delete goal, something went wrong!");
    } finally {
      setPendingDeleteRequest(false);
    }
  };

  const handleStatusChange = async (value: GoalStatus) => {
    if (value === GoalStatus.Completed) {
      const allItemsCompleted = selectedGoal.actionItems.every(
        (item) => item.completed,
      );
      const allInterventionsCompleted = [
        ...(selectedGoal.activities || []).map((activity) => activity?.status),
        ...(selectedGoal.referrals || []).map((referral) => referral?.status),
        ...(selectedGoal.carePathways || []).map(
          (carePathway) => carePathway?.status,
        ),
      ].every(
        (status) =>
          status === "Complete" ||
          status === "Sent" ||
          status === "CompletedSuccessful" ||
          status === "CompletedUnsuccessful",
      );

      if (!allItemsCompleted || !allInterventionsCompleted) {
        toast.error(
          "All action items and interventions must be completed to mark this goal as completed.",
        );
        return;
      }
    }

    try {
      const res = await mutationUpdateGoalStatus({
        variables: {
          memberId,
          goalId: selectedGoal._id,
          status: value,
          organizationId: selectedOrganizationId,
        },
        optimisticResponse: {
          updateGoalStatus: {
            success: true,
            message: "",
            data: {
              ...selectedGoal,
              status: value,
            },
          },
        },
      });

      if (res.errors?.length || res.data?.updateGoalStatus?.success === false) {
        throw new Error("Failed to update goal status");
      }
    } catch {
      toast.error("Couldn't update goal status, something went wrong!");
      throw new Error("Failed to update goal status");
    }
  };

  return (
    <>
      <Group
        w="100%"
        align="top"
        spacing={0}
        noWrap
        pos="relative"
        style={{ overflow: "hidden", flexGrow: 1 }}
      >
        {goalsLoading && (
          <Group w="100%" position="center">
            <LoaderComponent />
          </Group>
        )}

        {!goalsLoading && (
          <>
            {!hasGoals && (
              <NoGoals
                readOnly={memberPageReadOnly || readonly}
                selectedTab={selectedTab}
                onRequestCreateGoal={() => setCreateModalVisible(true)}
              />
            )}

            {hasGoals && selectedGoal && (
              <>
                <GoalList
                  small={small}
                  goals={goals}
                  selectedGoalId={selectedGoalId}
                  selectedTab={selectedTab}
                  onSelectTab={setSelectedTab}
                  onSelectGoal={(id) => setSelectedGoalId(id)}
                />

                <div
                  style={{
                    maxHeight: small ? "250px" : "350px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  <GoalDetail selectedGoal={selectedGoal} />

                  <GoalDetailControls
                    onRequestDelete={() => setDeleteModalOpen(true)}
                    onRequestEdit={() => setUpdateModalVisible(true)}
                    deletePending={pendingDeleteRequest}
                    goalStatus={selectedGoal.status}
                    onStatusUpdate={handleStatusChange}
                    readOnly={memberPageReadOnly || readonly}
                  />
                </div>
              </>
            )}
          </>
        )}
      </Group>

      <CreateGoalModal
        isOpen={createModalVisible}
        memberId={memberId}
        organizationId={organizationId}
        userId={userId}
        onRequestClose={() => setCreateModalVisible(false)}
        afterUpdateSuccess={() => setCreateModalVisible(false)}
        activityId={activityId}
        multiMemberActivity={multiMemberActivity}
      />

      {selectedGoal && updateModalVisible && (
        <UpdateGoalModal
          memberId={memberId}
          userId={userId}
          goal={selectedGoal}
          isOpen={updateModalVisible}
          setIsOpen={setUpdateModalVisible}
        />
      )}

      <DeleteModal
        modalIsOpen={deleteModalOpen}
        setModalIsOpen={setDeleteModalOpen}
        deleteFunction={handleDeleteGoal}
        row=""
      />
    </>
  );
};

type NoGoalsProps = {
  onRequestCreateGoal: () => void;
  selectedTab: GoalStatusTab;
  readOnly?: boolean;
};

const NoGoals = ({
  onRequestCreateGoal,
  selectedTab,
  readOnly,
}: NoGoalsProps) => (
  <Stack
    w="100%"
    align="center"
    justify="center"
    style={{ backgroundColor: "var(--color-white-muted)" }}
  >
    {selectedTab === "current" && (
      <>
        {!readOnly ? (
          <>
            <Text weight={600}>Member has no active goals.</Text>
            <Button onClick={onRequestCreateGoal}>Create New Goal</Button>
          </>
        ) : (
          <Text size="sm">Member has no goals.</Text>
        )}
      </>
    )}

    {selectedTab === "completed" && (
      <Text weight={600}>Member has no completed goals</Text>
    )}
  </Stack>
);
