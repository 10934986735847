import { gql } from "@apollo/client";
import { actionFields } from "../FlowTemplate/types";
import { questionFields } from "../Question/types";
import { FlowFields } from "./types";

export const GET_FLOWS_BY_MEMBER_ID = gql`
  query getFlowsByMemberId($memberId: ID!, $organizationId: ID!) {
    getFlowsByMemberId(memberId: $memberId, organizationId: $organizationId) {
      success
      message
      data {
        ${FlowFields}
      }
    }
  }
`;

export const GET_ONBOARDING_FLOW = gql`
  query GetOnboardingFlow($memberId: ID!, $organizationId: ID!, $activityId: ID!) {
    onboardingFlow(memberId: $memberId, organizationId: $organizationId, activityId: $activityId) {
      success
      message
      data {
        ${FlowFields}
      }
    }
  }
`;

export const GET_FLOW_BY_ACTIVITY = gql`
  query GetFlowByActivity($input: GetFlowByActivityInput!) {
    getFlowByActivity(input: $input) {
      success
      message
      data {
        ${FlowFields}
      }
    }
  }
`;

export const GET_FLOW_SNAPSHOT_BY_ACTIVITY_ID = gql`
  query FlowSnapshotByActivityId($activityId: ID!, $organizationId: ID!) {
    flowSnapshotByActivityId(activityId: $activityId, organizationId:$organizationId ) {
      success
      message
      data {
        associatedUserId,
        associatedActivityId,
        flow {
          ${FlowFields}
        }
      }
    }
  }
`;

export const GET_FLOW = gql`
query GetFlow($flowId: ID!, $organizationId:ID! ) {
  getFlow(flowId: $flowId, organizationId:$organizationId) {
    success
    message
    data {
         ${FlowFields}
    }
  }
}`;

export const GET_NEXT_FLOW_QUESTION = gql`
  query GetNextFlowQuestion($flowId: ID!, $organizationId:ID!) {
    getNextFlowQuestion(flowId: $flowId, organizationId:$organizationId) {
      success
      message
      data {
        currentStep
        question {
          ${questionFields}
        }
        actions {
          ${actionFields}
        }
      }
    }
  }
`;
