import {
  Text,
  Divider,
  Group,
  Stack,
  Anchor,
  Button,
  ActionIcon,
} from "@mantine/core";
import { DownloadCloud } from "react-feather";
import { ProviderRecommendation } from "src/graphql";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../modal";

interface FileModalProps {
  recommendations: ProviderRecommendation[];
  organizationIds: string[];
  onClose: () => void;
  onFileClick: (
    memberId: string,
    organizationIds: string[],
    batchId: string,
    signatureRequestId?: string,
  ) => Promise<void>;
  memberId: string;
}

export const FileModal: React.FC<FileModalProps> = ({
  recommendations,
  organizationIds,
  onClose,
  onFileClick,
  memberId,
}) => (
  <Modal
    opened={!!recommendations.length}
    onClose={onClose}
    title="Provider Recommendation Files"
  >
    <ModalHeader onClose={onClose}>Provider Recommendation Files</ModalHeader>
    <ModalBody>
      <Text mb="sm">
        Download the files below for the provider recommendations for this
        member.
      </Text>
      {recommendations.length > 0 ? (
        <Stack spacing="sm">
          {recommendations.map((recommendation) => {
            const batchId = recommendation.batch?._id;
            const signatureRequestId = recommendation.batch?.signatureRequestId;

            return (
              <div key={batchId}>
                <Group position="apart" noWrap>
                  {batchId ? (
                    <>
                      <Anchor
                        component="button"
                        onClick={() =>
                          onFileClick(
                            memberId,
                            organizationIds,
                            batchId,
                            signatureRequestId,
                          )
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <ActionIcon variant="light" color="green">
                          <DownloadCloud size="20px" />
                        </ActionIcon>
                        <Text
                          lineClamp={1}
                          style={{ maxWidth: "calc(100% - 40px)" }}
                        >
                          {`Batch ID: ${batchId}`}
                        </Text>
                      </Anchor>
                    </>
                  ) : (
                    <Text color="dimmed">
                      No file available for this recommendation
                    </Text>
                  )}
                </Group>
                <Divider my="xs" />
              </div>
            );
          })}
        </Stack>
      ) : (
        <Text color="dimmed" mt="md">
          No provider recommendations available for this member.
        </Text>
      )}
    </ModalBody>
    <ModalFooter>
      <Button onClick={onClose}>Close</Button>
    </ModalFooter>
  </Modal>
);
