import { useMemo } from "react";
import toast from "src/libs/toast";
import { useHistory, useLocation } from "react-router-dom";
import {
  ActivityAppointmentDetails,
  ActivityTaskTemplate,
  ActivityTaskTemplateInput,
  ActivityTemplateAppointmentDetails,
  ActivityType,
  DefaultBillingDetails,
  FlowType,
  useMutationCreateActivityTemplate,
  useMutationUpdateActivityTemplate,
  useQueryEventTemplates,
  useQueryFlowTemplates,
} from "src/graphql";
import { useQueryEmailTemplates } from "src/graphql/EmailTemplate/hooks";
import { useAuthContext } from "src/hooks";
import { SelectOption } from "src/types";
import { ySelectOptionSchema } from "src/utils";
import * as Yup from "yup";
import { useQueryEHRNoteTemplates } from "src/graphql/EHRNoteTemplate";
import { BASE_CREATE_TEMPLATE_ID } from "../templates";
import { isFlowBasedActivityType } from "./TemplateForm";
import { placeOfServiceCodeOptions } from "../organization/PlaceOfServiceCodesSection";

// FORM VALUES SCHEMA AND PARSERS
export const ActivityTaskTemplateSchema = Yup.object({
  type: ySelectOptionSchema(Yup.string().oneOf(Object.values(ActivityType)))
    .strict(true)
    .required("Task type is required"),

  formId: Yup.mixed<SelectOption<string> | undefined>().when("type", {
    is: (type: SelectOption<ActivityType>) =>
      isFlowBasedActivityType(type?.value) ||
      type?.value === ActivityType.Email ||
      type?.value === ActivityType.Event ||
      (type.value !== ActivityType.Sms &&
        type.value !== ActivityType.SyncEhrMember),
    then: ySelectOptionSchema(Yup.string())
      .strict(true)
      .required("Task template is required"),
  }),

  description: Yup.string()
    .trim()
    .nullable(true)
    .when("type", {
      is: (type: SelectOption<ActivityType>) =>
        type?.value === ActivityType.Sms,
      then: Yup.string().trim().required("Message is required for SMS tasks"),
    }),
}).strict();

export const AppointmentDetailsSchema = Yup.object({
  syncAppointment: Yup.boolean().optional(),
  appointmentDetails: Yup.object({
    locationFacility: Yup.string().nullable().optional(),
    locationType: Yup.string().nullable().optional(),
    locationDepartment: Yup.string().required(
      "Location department is required",
    ),
    locationRoom: Yup.string().nullable().optional(),
  })
    .strict(true)
    .when("syncAppointment", {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(true).optional(),
    }),
});

export const ActivityTemplateSchema = Yup.object({
  title: Yup.string().trim().required("Activity template title is required"),
  description: Yup.string().optional(),
  tasks: Yup.array(ActivityTaskTemplateSchema).required(),
  billable: Yup.boolean().required(),
  defaultBillingDetails: Yup.object({
    placeOfService: ySelectOptionSchema(Yup.string())
      .strict(true)
      .nullable()
      .optional(),
    procedure: ySelectOptionSchema(Yup.string())
      .strict(true)
      .nullable()
      .optional(),
    diagnosisCodes: Yup.array(
      ySelectOptionSchema(Yup.string()).strict(true).required(),
    )
      .nullable()
      .optional(),
  })
    .nullable()
    .optional(),
}).concat(AppointmentDetailsSchema);

export type ActivityTemplateFormValues = Yup.InferType<
  typeof ActivityTemplateSchema
>;

export type UnwrappedActivityTemplateFormValues = {
  title: string;
  description?: string;
  tasks: ActivityTaskTemplate[];
  billable: boolean;
  syncAppointment: boolean;
  appointmentDetails?: ActivityTemplateAppointmentDetails;
  defaultBillingDetails?: DefaultBillingDetails;
};

export const unwrapFormValues = (
  value: ActivityTemplateFormValues,
): UnwrappedActivityTemplateFormValues => {
  return {
    title: value.title,
    description: value.description,
    tasks: value.tasks.map(unwrapTaskFormValues),
    billable: value.billable,
    syncAppointment: value.syncAppointment ?? false,
    appointmentDetails: unwrapAppointmentDetails(value.appointmentDetails),
    defaultBillingDetails: unwrapDefaultBillingDetails(
      value.defaultBillingDetails,
    ),
  };
};

export const unwrapAppointmentDetails = (
  appointmentDetails:
    | undefined
    | {
        locationFacility?: string | null;
        locationDepartment: string;
        locationType?: string | null;
        locationRoom?: string | null;
      },
):
  | (ActivityAppointmentDetails & ActivityTemplateAppointmentDetails)
  | undefined => {
  if (appointmentDetails)
    return {
      locationDepartment: appointmentDetails.locationDepartment,
      locationFacility: appointmentDetails.locationFacility ?? undefined,
      locationRoom: appointmentDetails.locationRoom ?? undefined,
      locationType: appointmentDetails.locationType ?? undefined,
    };
  else return undefined;
};

export const wrapFormValues = (
  value: UnwrappedActivityTemplateFormValues,
  forms: { label: string; value: string }[],
): ActivityTemplateFormValues => {
  return {
    title: value.title,
    description: value.description ?? undefined,
    tasks: value.tasks.map((task) => wrapTaskFormValues(task, forms)),
    billable: value.billable,
    syncAppointment: value.syncAppointment,
    // Assertion, IDK why yup forces the nested partial values to required
    appointmentDetails:
      value.appointmentDetails as ActivityTemplateFormValues["appointmentDetails"],
    defaultBillingDetails: wrapDefaultBillingDetails(
      value.defaultBillingDetails,
    ),
  };
};

export const unwrapDefaultBillingDetails = (
  defaultBillingDetails: ActivityTemplateFormValues["defaultBillingDetails"],
): DefaultBillingDetails => {
  const procedure = defaultBillingDetails?.procedure?.value;
  const placeOfService = defaultBillingDetails?.placeOfService?.value;
  const diagnosisCodes = (defaultBillingDetails?.diagnosisCodes?.map(
    (d) => d.value,
  ) ?? []) as string[];

  return {
    procedure,
    placeOfService,
    diagnosisCodes,
  };
};

export const wrapDefaultBillingDetails = (
  defaultBillingDetails?: DefaultBillingDetails,
): ActivityTemplateFormValues["defaultBillingDetails"] => {
  return {
    procedure: defaultBillingDetails?.procedure
      ? {
          label: defaultBillingDetails?.procedure ?? "",
          value: defaultBillingDetails?.procedure ?? "",
        }
      : null,
    placeOfService: defaultBillingDetails?.placeOfService
      ? (placeOfServiceCodeOptions.find(
          (v) => v.value === defaultBillingDetails?.placeOfService,
        ) ?? null)
      : null,
    diagnosisCodes: defaultBillingDetails?.diagnosisCodes
      ? defaultBillingDetails?.diagnosisCodes?.map((code) => ({
          label: code,
          value: code,
        }))
      : null,
  };
};

export const unwrapTaskFormValues = (
  task: ActivityTemplateFormValues["tasks"][number],
  id: number,
): UnwrappedActivityTemplateFormValues["tasks"][number] => ({
  id,
  description: task.description ?? undefined,
  type: task.type.value as ActivityType,
  formId: task.formId?.value,
});

export const wrapTaskFormValues = (
  task: ActivityTaskTemplate,
  forms: { label: string; value: string }[],
): ActivityTemplateFormValues["tasks"][number] => ({
  description: task.description ?? undefined,
  type: { label: task.type, value: task.type },
  formId: task.formId
    ? forms.find((form) => form.value === task.formId)
    : undefined,
});

// HOOKS
export type TaskTemplateOptions = {
  flow: Record<FlowType, { label: string; value: string; type: FlowType }[]>;
  ehrNote: SelectOption<string>[];
  email: SelectOption<string>[];
  event: SelectOption<string>[];
  all: SelectOption<string>[];
  loading: boolean;
};

export const useTaskTemplateOptions = (): TaskTemplateOptions => {
  const { selectedOrganizationId } = useAuthContext();

  const flowTemplatesQuery = useQueryFlowTemplates(selectedOrganizationId);
  const emailTemplatesQuery = useQueryEmailTemplates(selectedOrganizationId);
  const ehrNoteTemplatesQuery = useQueryEHRNoteTemplates(
    selectedOrganizationId,
  );
  const eventTemplatesQuery = useQueryEventTemplates(selectedOrganizationId);

  const flowTemplateOptions = useMemo(
    () =>
      groupByType(
        Object.values(FlowType),
        flowTemplatesQuery.data?.flowTemplates.data?.map((t) => ({
          label: t.flowTemplate.title,
          value: t.flowTemplate.familyId,
          type: t.flowTemplate.flowType,
        })) ?? [],
      ),
    [flowTemplatesQuery],
  );

  const emailTemplateOptions = useMemo(
    () =>
      emailTemplatesQuery.data?.emailTemplates.data?.map(
        (template): SelectOption<string> => ({
          label: template.emailTemplate.subject,
          value: template.emailTemplate._id,
        }),
      ) ?? [],
    [emailTemplatesQuery],
  );

  const ehrNoteTemplatesOptions = useMemo(
    () =>
      ehrNoteTemplatesQuery.data?.ehrNoteTemplates.data?.map(
        (template): SelectOption<string> => ({
          label: template.name,
          value: template.id,
        }),
      ) ?? [],
    [ehrNoteTemplatesQuery],
  );

  const eventTemplateOptions = useMemo(
    () =>
      eventTemplatesQuery.data?.eventTemplates.data?.map(
        (template): SelectOption<string> => ({
          label: template.title,
          value: template._id,
        }),
      ) ?? [],
    [eventTemplatesQuery],
  );

  const all = useMemo(
    () => [
      ...flowTemplateOptions.Assessment,
      ...flowTemplateOptions.Journey,
      ...flowTemplateOptions.Script,
      ...emailTemplateOptions,
      ...ehrNoteTemplatesOptions,
      ...eventTemplateOptions,
    ],
    [
      flowTemplateOptions,
      emailTemplateOptions,
      ehrNoteTemplatesOptions,
      eventTemplateOptions,
    ],
  );

  const loading = flowTemplatesQuery.loading || emailTemplatesQuery.loading;

  return {
    flow: flowTemplateOptions,
    email: emailTemplateOptions,
    ehrNote: ehrNoteTemplatesOptions,
    event: eventTemplateOptions,
    all,
    loading,
  };
};

interface UpsertTemplateHookOptions {
  onSuccessPreNavigation?: () => void;
}

export const useUpsertTemplate = (options: UpsertTemplateHookOptions) => {
  const history = useHistory();
  const location = useLocation();
  const { selectedOrganizationId } = useAuthContext();

  const [createActivityTemplate, createActivityTemplateResult] =
    useMutationCreateActivityTemplate(selectedOrganizationId);

  const [updateActivityTemplate, updateActivityTemplateResult] =
    useMutationUpdateActivityTemplate(selectedOrganizationId);

  const responseLoading =
    createActivityTemplateResult.loading ||
    updateActivityTemplateResult.loading;

  const createORUpdateTemplate = async (
    id: string | undefined,
    formValues: ActivityTemplateFormValues,
  ) => {
    try {
      const values = unwrapFormValues(formValues);

      const response =
        id && id !== BASE_CREATE_TEMPLATE_ID
          ? await updateActivityTemplate({
              variables: {
                input: {
                  organizationId: selectedOrganizationId,
                  templateId: id,
                  updateActivityTemplate: { ...values },
                },
              },
            }).then((res) => res.data?.updateActivityTemplate)
          : await createActivityTemplate({
              variables: {
                input: {
                  organization: selectedOrganizationId,
                  ...values,
                },
              },
            }).then((res) => res.data?.createActivityTemplate);

      if (!response?.success || !response?.data)
        throw new Error(response?.message);

      toast.success(
        id && id !== BASE_CREATE_TEMPLATE_ID
          ? "Successfully updated activity template"
          : "Successfully created activity template",
      );

      options.onSuccessPreNavigation?.();

      history.replace(
        location.pathname.replaceAll(
          BASE_CREATE_TEMPLATE_ID,
          response.data._id,
        ),
      );
    } catch (error) {
      const defaultErrorMessage = id
        ? "Failed to update activity template"
        : "Failed to create activity template";

      toast.error(
        error instanceof Error
          ? (error.message ?? defaultErrorMessage)
          : defaultErrorMessage,
      );
    }
  };

  return [createORUpdateTemplate, { loading: responseLoading }] as const;
};

const groupByType = <T extends { type: K }, K extends string>(
  values: K[],
  array: T[],
) => {
  const initialValue = Object.fromEntries(
    values.map((key) => [key, [] as T[]]),
  ) as Record<K, T[]>;

  return array.reduce((grouped, element) => {
    grouped[element.type].push(element);
    return grouped;
  }, initialValue);
};

// BASE TEMPLATES FACTORY
export const createBaseActivityTemplate = () => ({
  title: "",
  tasks: [] as ActivityTaskTemplateInput[],
  billable: false,
  syncAppointment: false,
  defaultBillingDetails: {},
});

export const createBaseActivityTaskTemplate = (
  id: number,
): ActivityTaskTemplateInput => ({
  id,
  type: ActivityType.Assessment,
  description: "",
});
