import * as Yup from "yup";

export const RecommendationFormSchema = Yup.object({
  name: Yup.string()
    .min(2, "Title must be longer than 2 characters")
    .max(50, "Title must be shorter than 50 characters")
    .required("Required"),
  recommendationText: Yup.string()
    .min(10, "Description must be more than 10 characters")
    .required("Required"),

  conditionsGroups: Yup.array()
    .of(
      Yup.object().shape({
        conditionOperator: Yup.string().required("Required"),
        conditions: Yup.array()
          .of(
            Yup.object().shape({
              operator: Yup.string().required("Required"),
              dataId: Yup.string().required("Required"),
              value: Yup.array()
                .min(1, "Value is required")
                .required("Required"),
            }),
          )
          .min(1, "You must have at least 1 condition"),
      }),
    )
    .min(1, "You must have at least 1 group"),
});
