import { Alert, Button, LoadingOverlay, SimpleGrid } from "@mantine/core";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import {
  FormikInput,
  FormikSelect,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "src/components";
import { FormikTextEditor } from "src/components/input/FormikTextEditor/FormikTextEditor";
import { WarnLockingReferencesModal } from "src/components/warn-locking-references-modal";
import {
  AccessType,
  EmailTemplate,
  LockingReference,
  Permission,
  SendEmailTemplateTo,
} from "src/graphql";
import {
  useMutationCreateEmailTemplate,
  useMutationUpdateEmailTemplate,
  useQueryEmailTemplate,
} from "src/graphql/EmailTemplate/hooks";
import { useAuthContext } from "src/hooks";
import toast from "src/libs/toast";
import { yupNoScriptTag } from "src/utils";
import * as Yup from "yup";

const Schema = Yup.object({
  to: Yup.object().shape({
    value: Yup.string().required("Send to is required"),
  }),
  recipientAddress: Yup.string()
    .email("Invalid email address")
    .when("to", {
      is: (to: { value: string }) => to.value === "Email",
      then: Yup.string().required("Email address is required"),
    }),
  subject: yupNoScriptTag("Subject cannot contain script tags").required(
    "Subject is required",
  ),
  body: yupNoScriptTag("Message cannot contain script tags").required(
    "Message is required",
  ),
});

type EmailTemplateFormikFields = {
  to: { label: string; value: SendEmailTemplateTo };
  recipientAddress?: string;
  subject: string;
  body: string;
};

type EmailTemplateFormProps = {
  readonly opened: boolean;
  readonly onRequestedClose: () => void;
  readonly emailTemplateId?: string;
};

type LockingReferenceModalData = {
  formState: EmailTemplateFormikFields;
  lockingReferences: LockingReference[];
};

const initialTemplateValues = {
  to: { label: "Member", value: SendEmailTemplateTo.Member },
} as EmailTemplateFormikFields;

export const EmailTemplateModal = ({
  opened,
  onRequestedClose,
  emailTemplateId,
}: EmailTemplateFormProps) => {
  const { selectedOrganizationId, hasAnyPermission } = useAuthContext();

  const [lockingReferencesModalData, setLockingReferencesModalData] =
    useState<LockingReferenceModalData | null>(null);

  const { data: previousTemplateQuery, loading: queryLoading } =
    useQueryEmailTemplate(selectedOrganizationId, emailTemplateId);

  const [mutationCreateEmailTemplate, { loading: createLoading }] =
    useMutationCreateEmailTemplate(selectedOrganizationId);

  const [mutationUpdateEmailTemplate, { loading: updateLoading }] =
    useMutationUpdateEmailTemplate(selectedOrganizationId);

  const previousTemplate = useMemo(
    () => previousTemplateQuery?.emailTemplate?.data,
    [previousTemplateQuery],
  );

  const handleSubmit = async (values: EmailTemplateFormikFields) => {
    try {
      const response = emailTemplateId
        ? await mutationUpdateEmailTemplate({
            variables: {
              input: {
                _id: emailTemplateId,
                organizationId: selectedOrganizationId,
                ...unwrapEmailTemplate(values),
              },
            },
          }).then((res) => res.data?.updateEmailTemplate)
        : await mutationCreateEmailTemplate({
            variables: {
              input: {
                organizationId: selectedOrganizationId,
                ...unwrapEmailTemplate(values),
              },
            },
          }).then((res) => res.data?.createEmailTemplate);

      if (!response?.success) throw new Error(response?.message);

      toast.success("Email template created");
      onRequestedClose();
    } catch (error) {
      const defaultErrorMessage = emailTemplateId
        ? "Failed to update email template"
        : "Failed to create email template";

      toast.error(
        error instanceof Error
          ? (error.message ?? defaultErrorMessage)
          : defaultErrorMessage,
      );
    }
  };

  const handleFormSubmit = async (values: EmailTemplateFormikFields) => {
    const activeLockingReferences =
      previousTemplate?.emailTemplate.lockingReferences.filter(
        (r) => r.isActive,
      ) ?? [];

    if (activeLockingReferences.length === 0) handleSubmit(values);
    else
      setLockingReferencesModalData({
        formState: values,
        lockingReferences: activeLockingReferences,
      });
  };

  const onLockingReferenceConfirm = async () => {
    if (!lockingReferencesModalData) return;
    setLockingReferencesModalData(null);
    handleSubmit(lockingReferencesModalData.formState);
  };

  return (
    <>
      <Modal opened={opened} onClose={onRequestedClose} size={800}>
        <Formik
          key={previousTemplate?.emailTemplate._id ?? "new"}
          initialValues={
            previousTemplate
              ? wrapEmailTemplate(previousTemplate.emailTemplate)
              : initialTemplateValues
          }
          validationSchema={Schema}
          onSubmit={handleFormSubmit}
        >
          {({ isValid, handleSubmit, values, setFieldValue }) => {
            return (
              <>
                {!hasAnyPermission(Permission.BuilderToolsWriteAccess) && (
                  <Alert color="red">
                    You do not have permission to edit email templates
                  </Alert>
                )}

                <LoadingOverlay visible={queryLoading} />

                <Form onSubmit={handleSubmit}>
                  <ModalHeader>
                    {previousTemplate ? "Update" : "Create"} Email Template
                  </ModalHeader>

                  <ModalBody spacing="md">
                    <SimpleGrid
                      breakpoints={[
                        {
                          minWidth: "md",
                          cols: values.to.value === "Email" ? 2 : 1,
                        },
                      ]}
                    >
                      <FormikSelect
                        name="to"
                        label="Send To"
                        options={[
                          { value: "Member", label: "Member" },
                          { value: "Email", label: "Email Address" },
                        ]}
                      />

                      {values.to.value === "Email" && (
                        <FormikInput
                          name="recipientAddress"
                          type="email"
                          label="Email Address"
                        />
                      )}
                    </SimpleGrid>

                    <FormikInput type="text" name="subject" label="Subject" />

                    <FormikTextEditor
                      name="body"
                      label="Message"
                      onChangeOverride={(value) => setFieldValue("body", value)}
                      styles={{ content: { height: "500px" } }}
                    />
                  </ModalBody>

                  <ModalFooter>
                    {hasAnyPermission(Permission.BuilderToolsWriteAccess) && (
                      <>
                        <Button
                          variant="outline"
                          color="red"
                          onClick={onRequestedClose}
                        >
                          Cancel
                        </Button>

                        <Button
                          loading={createLoading || updateLoading}
                          disabled={
                            !isValid ||
                            (previousTemplate &&
                              previousTemplate.accessType !== AccessType.Write)
                          }
                          size="md"
                          type="submit"
                        >
                          {previousTemplate ? "Update" : "Create"}
                        </Button>
                      </>
                    )}
                  </ModalFooter>
                </Form>
              </>
            );
          }}
        </Formik>
      </Modal>

      <WarnLockingReferencesModal
        isOpen={!!lockingReferencesModalData}
        lockedObject="Email Template"
        activeReferences={lockingReferencesModalData?.lockingReferences ?? []}
        onConfirm={onLockingReferenceConfirm}
        onRequestClose={() => setLockingReferencesModalData(null)}
      />
    </>
  );
};

const wrapEmailTemplate = ({
  subject,
  body,
  recipientAddress,
}: EmailTemplate): EmailTemplateFormikFields => {
  return {
    to: recipientAddress
      ? { label: "Email Address", value: "Email" }
      : { label: "Member", value: "Member" },
    subject,
    body,
    recipientAddress: recipientAddress ?? undefined,
  };
};

const unwrapEmailTemplate = ({
  to,
  subject,
  body,
  recipientAddress,
}: EmailTemplateFormikFields) => {
  return {
    sendTo: to.value,
    subject,
    body,
    recipientAddress: to.value === "Email" ? recipientAddress : undefined,
  };
};
