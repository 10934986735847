import { useMutation, useLazyQuery } from "@apollo/client";
import {
  DefaultResponse,
  GetUserResponse,
  MutationExchangeOneTimeIdTokenArgs,
  MutationInviteUserArgs,
  MutationSetPasswordArgs,
  QueryRequestPasswordResetArgs,
  QueryResendSignupEmailArgs,
} from "../schemaTypes";
import { REQUEST_PASSWORD_RESET, RESEND_SIGNUP_EMAIL } from "./queries";
import {
  SET_PASSWORD,
  EXCHANGE_ONE_TIME_ID_TOKEN,
  INVITE_USER,
} from "./mutations";

/**
 * Queries
 */
export const useLazyQueryRequestPasswordReset = () =>
  useLazyQuery<
    { requestPasswordReset: DefaultResponse },
    QueryRequestPasswordResetArgs
  >(REQUEST_PASSWORD_RESET, {
    fetchPolicy: "no-cache",
  });

export const useLazyQueryResendSignupEmail = () =>
  useLazyQuery<
    { resendSignupEmail: DefaultResponse },
    QueryResendSignupEmailArgs
  >(RESEND_SIGNUP_EMAIL);

/**
 * Mutations
 */
export const useMutationInviteUser = () =>
  useMutation<{ inviteUser: GetUserResponse }, MutationInviteUserArgs>(
    INVITE_USER,
    {
      refetchQueries: ["GetUsers", "GetRecommendingProvidersByOrgId"],
    },
  );

export const useMutationExchangeOneTimeIdToken = () =>
  useMutation<
    { exchangeOneTimeIdToken: DefaultResponse },
    MutationExchangeOneTimeIdTokenArgs
  >(EXCHANGE_ONE_TIME_ID_TOKEN);

export const useMutationSetPassword = () =>
  useMutation<{ setPassword: DefaultResponse }, MutationSetPasswordArgs>(
    SET_PASSWORD,
  );
