import { WatchQueryFetchPolicy } from "@apollo/client";
import { useMemo } from "react";
import { AnswerType, DataIdWithAnswerType } from "src/graphql";
import { useQueryDataIdsWithAnswerTypesByOrganizationId } from "src/graphql/DataPoint";

export const useDataPointIdsWithAnswerTypes = (
  organizationId: string,
  includeDemographicOptions = false,
  cache: WatchQueryFetchPolicy = "cache-and-network",
) => {
  const {
    data: dataIdsWithAnswerTypes,
    loading: dataIdsWithAnswerTypesLoading,
  } = useQueryDataIdsWithAnswerTypesByOrganizationId(
    organizationId,
    includeDemographicOptions,
    cache,
  );

  const responseData = useMemo(() => {
    return (
      dataIdsWithAnswerTypes?.dataIdsWithAnswerTypesByOrganizationId.data ?? []
    );
  }, [dataIdsWithAnswerTypes]);

  const map = useMemo(() => {
    const questionTitlesByDataId: Record<string, string[]> = {};
    const answerTypesByDataId: Record<string, AnswerType> = {};
    const answerOptionsByDataId: Record<string, string[]> = {};
    const dataPointTemplateIdsByDataId: Record<string, string> = {};
    const dataPointTemplatesById: Record<string, DataIdWithAnswerType> = {};
    const defaultOptionsByDataId: Record<string, string[]> = {};
    const allUniqueOptionsByDataId: Record<string, string[]> = {};

    responseData.forEach((data) => {
      if (!(data.dataId in questionTitlesByDataId)) {
        questionTitlesByDataId[data.dataId] = [];
      }
      questionTitlesByDataId[data.dataId].push(data.questionTitle);

      answerTypesByDataId[data.dataId] = data.answerType;
      defaultOptionsByDataId[data.dataId] = data.defaultOptions;
      dataPointTemplateIdsByDataId[data.dataId] = data.dataPointTemplateId;
      answerOptionsByDataId[data.dataId] = [];
      dataPointTemplatesById[data.dataPointTemplateId] = data;
      allUniqueOptionsByDataId[data.dataPointTemplateId] = Array.from(
        new Set([...data.answerOptions, ...data.defaultOptions]),
      );

      (data.answerOptions || []).forEach((value) => {
        answerOptionsByDataId[data.dataId].push(value);
      });
    });

    return {
      questionTitlesByDataId,
      answerTypesByDataId,
      answerOptionsByDataId,
      dataPointTemplateIdsByDataId,
      dataPointTemplatesById,
      defaultOptionsByDataId,
      allUniqueOptionsByDataId,
    };
  }, [responseData]);

  return {
    answerTypesByDataId: map.answerTypesByDataId,
    answerOptionsByDataId: map.answerOptionsByDataId,
    questionTitlesByDataId: map.questionTitlesByDataId,
    dataPointTemplateIdsByDataId: map.dataPointTemplateIdsByDataId,
    dataPointTemplatesById: map.dataPointTemplatesById,
    defaultOptionsByDataId: map.defaultOptionsByDataId,
    allUniqueOptionsByDataId: map.allUniqueOptionsByDataId,
    loading: dataIdsWithAnswerTypesLoading,
  };
};
