import { gql } from "@apollo/client";

export const GET_ASSESSMENT = gql`
  query GetAssessment($flowId: ID!, $organizationId: ID!) {
    getAssessment(flowId: $flowId, organizationId: $organizationId) {
      success
      message
      data {
        completed
        firstStepId
        questions {
          id
          dataId
          isActive
          answerType
          questionText
          questionType
          answerOptions
          stepId
          next {
            id
            response
            nextStepId
            flowTemplateStepId
          }
        }
      }
    }
  }
`;
