import { useApolloClient } from "@apollo/client";
import { Call, Device, Logger } from "@twilio/voice-sdk";
import * as React from "react";
import toast from "src/libs/toast";
import { v4 as uuid } from "uuid";

import {
  PhoneCallIdentifierType,
  useLazyQueryMembers,
  useLazyQueryPhoneCall,
  useMutationCreateVoiceGrantAccessToken,
  useMutationSendInCallPing,
  useMutationUpdatePhoneCallEntry,
} from "src/graphql";
import { GET_PHONE_CALLS } from "src/graphql/Twilio/queries";
import { useAuthContext } from "src/hooks";
import {
  CustomIncomingCallParametersSchema,
  IncomingCall,
  MemberInfo,
  TwilioContextType,
} from "src/types";
import log from "loglevel";
import { WidgetTab } from "src/components/twilio/widget/tabs";
import { TwilioError } from "@twilio/voice-sdk/es5/twilio/errors";
import { buildMemberInfo } from "src/utils/call";

// Toggle to enable twilio module debug logging
const debugLogsEnabled = false;

interface TwilioProviderProps {
  render?: () => React.ReactNode;
}

const TwilioContext = React.createContext<TwilioContextType>(
  {} as TwilioContextType,
);

const TwilioProvider: React.FC<
  TwilioProviderProps & React.HTMLAttributes<HTMLDivElement>
> = ({ children, render = null, ...props }) => {
  const inCallPingIntervalRef = React.useRef<number | null>(null);
  const client = useApolloClient();

  const {
    selectedOrganizationId,
    selectedOrganization,
    currentUser,
    setSelectedOrganizationId,
    organizations,
  } = useAuthContext();
  const [isSettingDevice, setIsSettingDevice] = React.useState(false);
  const [device, setDevice] = React.useState<Device>();
  const [outgoingCall, setOutgoingCall] = React.useState<Call>();
  const [conferenceFriendlyName, setConferenceFriendlyName] =
    React.useState("");
  const [lastCallOrgId, setLastCallOrgId] = React.useState<
    string | undefined
  >();
  const [isCallInProgress, setIsCallInProgress] = React.useState(false);
  const [incomingCall, setIncomingCall] = React.useState<IncomingCall>();
  const [updatePhoneCallEntry] = useMutationUpdatePhoneCallEntry();
  const [mutationSendInCallPing] = useMutationSendInCallPing();

  // State to show if an incoming call is recieved and is in progress
  const [isIncomingCallInProgress, setIsIncomingCallInProgress] =
    React.useState(false);
  // State to show that a call is incoming
  const [isCallIncoming, setIsCallIncoming] = React.useState(false);

  const [widgetMembers, setWidgetMembers] = React.useState<
    MemberInfo[] | undefined
  >();

  const [widgetPhoneNumber, setWidgetPhoneNumber] = React.useState<
    string | undefined
  >();
  const [widgetExpanded, expandWidget] = React.useState(false);
  const [widgetTab, setWidgetTab] = React.useState("call");
  const [micState, setMicState] = React.useState<PermissionState>();

  const [createTwilioToken] = useMutationCreateVoiceGrantAccessToken();

  /** Widget */
  const [lazyQueryMembers] = useLazyQueryMembers();

  // Updates the Members for the phone widget on phone number

  React.useEffect(() => {
    if (!selectedOrganizationId) return;
    lazyQueryMembers({
      variables: {
        input: {
          organizations: [selectedOrganizationId],
          excludeInactive: true,
        },
        pagination: {
          search: widgetPhoneNumber,
          size: 10,
        },
      },
    })
      .then((response) =>
        widgetPhoneNumber === "" || widgetPhoneNumber === undefined
          ? []
          : (response.data?.response.data?.data ?? []),
      )
      .then((members) =>
        setWidgetMembers(
          members.map((m) => buildMemberInfo(m, selectedOrganizationId)),
        ),
      );
  }, [lazyQueryMembers, selectedOrganizationId, widgetPhoneNumber]);

  React.useEffect(() => {
    if (navigator?.permissions?.query) {
      navigator.permissions
        .query({
          name: "microphone" as PermissionName,
        })
        .then((res) => {
          setMicState(res.state);
        });
    }
  }, []);

  // effects
  React.useEffect(() => {
    // Using Twilio's "device.updateToken" method throws an undefined property access error which causes the device token to NOT update
    // This is a problem because the token holds the userId and orgId, but if a user switched orgs, the orgId in the device would remain the same
    // This will cause a phone call to be saved in the incorrect org because we save them to the orgId listed in the twilio device token due to users
    // having the ability to be in multiple organizations.
    initTwilioDevice();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId]);

  // unregister device on component unmount
  React.useEffect(
    () => () => {
      device?.removeAllListeners();
      if (
        device?.state === Device.State.Registered ||
        device?.state === Device.State.Registering
      ) {
        device.unregister();
      }
      setDevice(undefined);
      stopPingingInCall();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Add device listener when device is set
  React.useEffect(() => {
    if (
      !device ||
      device.state === Device.State.Registering ||
      device.state === Device.State.Registered
    )
      return;

    addDeviceListeners(device);
    device.register();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  // Add call listeners when call is made
  React.useEffect(() => {
    if (outgoingCall) addCallListeners(outgoingCall);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outgoingCall]);

  // Add listeners to the incoming call
  React.useEffect(() => {
    if (incomingCall) addIncomingCallListeners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingCall]);

  const [lazyQueryPhoneCall] = useLazyQueryPhoneCall();

  const askForPermissions = React.useCallback(async () => {
    if (!selectedOrganization || !selectedOrganization.callerId) {
      toast.error("Organization not found or missing Twilio Caller ID");
      return false;
    }

    if (navigator?.permissions?.query) {
      const resultOfQuery = await navigator.permissions
        .query({ name: "microphone" as PermissionName })
        .catch((error) => {
          log.error("Error querying microphone permissions", error);
          return { state: "denied" } as PermissionStatus;
        });

      if (resultOfQuery.state === "prompt") {
        try {
          await navigator.mediaDevices.getUserMedia({ audio: true });
          setMicState("granted");
          return true;
        } catch (error) {
          setMicState("denied");
          return false;
        }
      } else {
        setMicState(resultOfQuery.state);
        resultOfQuery.onchange = (e) => {
          const target = e.target as PermissionStatus;
          setMicState(target.state);
        };
        return resultOfQuery.state === "granted";
      }
    }
    return false;
  }, [selectedOrganization]);

  const startPingingInCall = () => {
    if (!inCallPingIntervalRef.current) {
      inCallPingIntervalRef.current = window.setInterval(
        mutationSendInCallPing,
        15 * 1000,
      );
    }
  };

  const stopPingingInCall = () => {
    if (inCallPingIntervalRef.current) {
      window.clearInterval(inCallPingIntervalRef.current);
      inCallPingIntervalRef.current = null;
    }
  };

  const addIncomingCallListeners = async () => {
    if (!(await askForPermissions())) {
      toast.error("Microphone access denied, update your permissions");
      return;
    }

    incomingCall?.twilioCall.on("accept", () => {
      startPingingInCall();
      setSelectedOrganizationId(incomingCall.organization._id);
      setIsIncomingCallInProgress(true);
      setIsCallIncoming(false);
      setWidgetTab(WidgetTab.Phone);
      expandWidget(true);
      setWidgetMembers(incomingCall.members);
      setWidgetPhoneNumber(incomingCall.phone);

      updatePhoneCallEntry({
        variables: {
          input: {
            identifier: incomingCall.phoneCallId,
            identifierType: PhoneCallIdentifierType.PhoneCallId,
            organizationId: incomingCall.organization._id,
            userId: currentUser._id,
          },
        },
      }).then((res) => {
        log.log(res);
      });
    });

    incomingCall?.twilioCall.on("disconnect", (call: Call) => {
      client.refetchQueries({ include: [GET_PHONE_CALLS] });
      onCancelOrDisconnectIncommingCall();
    });

    // runs if someone hangs up the call, or call is sent to voicemail
    incomingCall?.twilioCall.on("cancel", () => {
      log.log("Cancelled incoming call");
      onCancelOrDisconnectIncommingCall();
    });

    incomingCall?.twilioCall.on("reject", () => {
      log.log("Rejected incoming call");

      updatePhoneCallEntry({
        variables: {
          input: {
            identifier: incomingCall.phoneCallId,
            identifierType: PhoneCallIdentifierType.PhoneCallId,
            organizationId: selectedOrganizationId,
            userId: currentUser?._id,
          },
        },
      });

      // 2 minutes before rechecking for a voicemail
      onCancelOrDisconnectIncommingCall();
      client.refetchQueries({ include: [GET_PHONE_CALLS] });
    });
  };

  const onCancelOrDisconnectIncommingCall = () => {
    incomingCall?.twilioCall.removeAllListeners();
    stopPingingInCall();
    setIsIncomingCallInProgress(false);
    setIncomingCall(undefined);
    setConferenceFriendlyName("");
    setIsCallIncoming(false);
  };

  const acceptIncomingCall = () => {
    incomingCall?.twilioCall.accept();
  };

  const declineIncomingCall = () => {
    incomingCall?.twilioCall.reject();
    setIsIncomingCallInProgress(false);
    setIncomingCall(undefined);
    setIsCallIncoming(false);
  };

  const addCallListeners = (call: Call) => {
    call.on("accept", () => {
      setIsCallInProgress(true);
      startPingingInCall();
    });
    call.on("disconnect", onCancelOrDisconnectCall);
    call.on("cancel", onCancelOrDisconnectCall);
  };

  const onCancelOrDisconnectCall = () => {
    stopPingingInCall();
    outgoingCall?.removeAllListeners();
    setIsCallInProgress(false);
    setOutgoingCall(undefined);
  };

  const endOutgoingCall = () => {
    outgoingCall?.disconnect();
    setIsCallInProgress(false);
    setOutgoingCall(undefined);
  };

  const endIncomingCall = () => {
    incomingCall?.twilioCall.disconnect();
    setIncomingCall(undefined);
  };

  const addDeviceListeners = (device: Device) => {
    device?.on("registered", () => {
      if (micState === "granted")
        log.log("Twilio.Device Ready to make and receive calls!");
      else {
        log.log("Waiting on mic permissions. Current state: ", micState);
      }
    });

    device?.on("error", (error) => {
      handleTwilioError(error);
    });

    device?.on("incoming", async (twilioCall: Call) => {
      const customParams = Object.fromEntries(twilioCall.customParameters);
      if (!CustomIncomingCallParametersSchema.isValidSync(customParams)) {
        toast.error("Incoming call failed");
        return;
      }

      const organizationId = customParams.organizationId;
      const incomingCallOrg = organizations.find(
        (org) => org._id === organizationId,
      );

      if (!incomingCallOrg) {
        toast.error("Organization not found");
        return;
      }

      const { data: phoneCallResponse } = await lazyQueryPhoneCall({
        variables: {
          input: {
            organizationId: customParams.organizationId,
            identifier: customParams.phoneCallId,
            identifierType: PhoneCallIdentifierType.PhoneCallId,
          },
        },
      });
      const phoneCall = phoneCallResponse?.phoneCall.data;
      if (!phoneCall) {
        toast.error("Incoming call missing phoneCall info!");
        return;
      }

      const fullDetails = phoneCall.members?.map((member): MemberInfo => {
        return {
          _id: member.memberId,
          organizationId: incomingCallOrg._id,
          firstName: member.firstName,
          lastName: member.lastName,
          nickName: member.nickName,
          phone: twilioCall.parameters?.From || "",
        };
      });
      setIsCallIncoming(true);
      setLastCallOrgId(incomingCallOrg._id);
      setIncomingCall({
        conferenceFriendlyName: phoneCall.conferenceFriendlyName,
        phoneCallId: phoneCall._id,
        twilioCall: twilioCall,
        members: fullDetails,
        phone: twilioCall.parameters?.From,
        organization: incomingCallOrg,
      });
    });

    device?.on("tokenWillExpire", async () => {
      const token = await getToken();
      device.updateToken(token);
    });
  };

  const getToken = async () => {
    if (!currentUser || !selectedOrganizationId) {
      throw new Error("User or organization not found");
    }
    const response = await createTwilioToken({
      variables: {
        userId: currentUser?._id ?? "",
        organizationId: selectedOrganizationId ?? "",
      },
    });

    if (!response.data?.voiceGrantAccessToken.data) {
      throw new Error(
        response.data?.voiceGrantAccessToken.message ||
          "Failed to get Twilio token",
      );
    }
    return response.data.voiceGrantAccessToken.data;
  };

  const initTwilioDevice = async () => {
    setIsSettingDevice(true);
    try {
      const res = await getToken();
      if (res) {
        if (device) await device.destroy();
        const twilioDevice = new Device(res, {
          logLevel: debugLogsEnabled ? Logger.levels.DEBUG : Logger.levels.WARN,
        });
        setDevice(twilioDevice);
      } else throw new Error("Error fetching twilio device token");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast.error(e.message || "Could not get access token for twilio");
    } finally {
      setIsSettingDevice(false);
    }
  };

  const makeOutgoingCall = async () => {
    if (!widgetPhoneNumber) {
      toast.error("No phone number selected");
      return;
    }

    if (!selectedOrganization || !selectedOrganization.callerId) {
      toast.error("Organization does not support Twilio");
      return;
    }

    if (!(await askForPermissions())) {
      toast.error("Microphone access denied, update your permissions");
      return;
    }

    setIsCallInProgress(true);
    setLastCallOrgId(selectedOrganization._id);
    const conferenceFriendlyName = makeConferenceFriendlyName(
      selectedOrganizationId,
      selectedOrganization.callerId,
      widgetPhoneNumber,
    );
    setConferenceFriendlyName(conferenceFriendlyName);
    const params = {
      To: widgetPhoneNumber,
      From: selectedOrganization.callerId || "",
      conferenceFriendlyName,
    };
    const connectedCall = await device?.connect({ params });

    setOutgoingCall(connectedCall);
  };

  const handleTwilioError = (error: TwilioError) => {
    setDevice(undefined);
    if (networkErrors.includes(error.code)) {
      toast.error(
        "Whoops. You've seem to have lost connection with our phone provider. Please check your network connection. If this issue persists, please contact support.",
        {
          duration: 10_000,
        },
      );
    } else {
      toast.error(
        "Whoops. We've encountered an unexpected error. Please try again. If this issue persists, please contact support.",
        {
          duration: 7_000,
        },
      );
    }
    log.log("Twilio.Device Error: " + error.message);
  };

  const contextValue = {
    isSettingDevice,
    isCallInProgress,
    isCallIncoming,
    isIncomingCallInProgress,
    incomingCall,
    lastCallOrgId,
    call: outgoingCall || incomingCall?.twilioCall,
    conferenceFriendlyName,
    getAccessToken: initTwilioDevice,
    makeOutgoingCall,
    endIncomingCall,
    endOutgoingCall,
    acceptIncomingCall,
    declineIncomingCall,
    widget: {
      members: widgetMembers,
      phoneNumber: widgetPhoneNumber,
      expanded: widgetExpanded,
      tab: widgetTab,
      setPhoneNumber: setWidgetPhoneNumber,
      expand: expandWidget,
      setTab: setWidgetTab,
    },
    micState,
  };

  return (
    <TwilioContext.Provider value={contextValue}>
      {render ? render() : <div {...props}>{children}</div>}
    </TwilioContext.Provider>
  );
};

const makeConferenceFriendlyName = (
  organizationId: string,
  from: string,
  to: string,
): string => {
  return `conference-${from.replace("+", "")}-${to.replace(
    "+",
    "",
  )}-${organizationId}-${uuid()}`;
};

export { TwilioContext };
export default TwilioProvider;

const networkErrors = [
  31002, // connection declined
  31003, // connection timeout
  31005, // websocket signal inturrupted
  53405, // media connection failed
  53000, // signaling connection failed
];
