import React from "react";
import styled from "styled-components";
import imagePearLogo from "src/assets/images/PearSuite-Horizontal.png";
import { AuthSplash } from "./AuthSplash";
import { Switch, Route } from "react-router-dom";
import { LoaderComponent } from "../loader";
import { LoginForm } from "./LoginForm";
import { SignupForm } from "./SignupForm";
import { Button, Stack, Text, Anchor } from "@mantine/core";
import { RequestPasswordResetForm } from "./RequestResetPasswordForm";
import { ResetPasswordForm } from "./ResetPasswordForm";
import OnboardingRoute from "src/pages/onboarding";
import ReferralStatusRoute from "src/pages/referral-status";
import SubscriptionPlans from "src/pages/plans";
import { useAuth0 } from "@auth0/auth0-react";

const StyledContainer = styled.div`
  background-color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.1);
`;

export const LogoContainer = styled.div`
  width: 100%;
  z-index: 1;
  padding: 15px 0px 10px;
  text-align: center;
  border-top: 20px solid var(--color-pear-green);
`;

const ContentContainer = styled.div`
  width: 320px;
  padding: 20px;
`;

export enum AuthFlowState {
  Loading = 0,
  LoggedOut,
  NoOrgs,
  Disabled,
  OfferSignout,
  UserDocNotFound,
  Authenticated,
}

type AuthFlowContainerProps = {
  authFlowState: AuthFlowState;
  onSignOut: () => void;
  onUserAuthenticated: () => void;
};

export const AuthFlowContainer = ({
  authFlowState,
  onSignOut,
  onUserAuthenticated,
}: AuthFlowContainerProps) => {
  const { logout } = useAuth0();

  return (
    <AuthSplash>
      <Switch>
        <Route path="/plans" component={SubscriptionPlans} />
        <Route path="/onboarding-survey" component={OnboardingRoute} />
        <Route path="/referral-status" component={ReferralStatusRoute} />
        <Route>
          <AuthStep>
            {/* loading authentication session state */}
            {authFlowState === AuthFlowState.Loading && <LoaderComponent />}

            {/* signed out */}
            {authFlowState === AuthFlowState.LoggedOut && (
              <Switch>
                <Route exact path="/login">
                  <LoginForm onUserAuthenticated={onUserAuthenticated} />
                </Route>
                <Route exact path="/signup">
                  <SignupForm />
                </Route>
                <Route exact path="/forgot-password">
                  <RequestPasswordResetForm />
                </Route>
                <Route exact path="/reset">
                  <ResetPasswordForm />
                </Route>
              </Switch>
            )}

            {/* Pear user not found */}
            {authFlowState === AuthFlowState.UserDocNotFound && (
              <>
                <Text align="center" mb="xs">
                  This account is not attached to an existing Pear Suite user.
                  Please try logging in again with your Pear Suite username and
                  password.
                </Text>
                <Text align="center" mb="sm">
                  If you continue to experience issues, please contact{" "}
                  <Anchor href="mailto:support@pearsuite.com">
                    support@pearsuite.com
                  </Anchor>
                </Text>
                <Button fullWidth onClick={() => onSignOut()}>
                  Back to Login
                </Button>
              </>
            )}

            {/* signed in and fetching PearSuite user state */}
            {authFlowState === AuthFlowState.Authenticated && (
              <LoaderComponent />
            )}

            {/* user state is fetched but disabled or has no organizations */}
            {authFlowState === AuthFlowState.NoOrgs && (
              <>
                <Text align="center">
                  This account is not attached to any organizations or has been
                  disabled. Please reach out to support if this is unexpected!
                </Text>
                <Button fullWidth onClick={() => logout()}>
                  Sign Out
                </Button>
              </>
            )}
          </AuthStep>
        </Route>
      </Switch>
    </AuthSplash>
  );
};

type AuthStepProps = {
  children: React.ReactNode;
};

export const AuthStep = ({ children }: AuthStepProps) => (
  <Stack h="100%" align="center" justify="center">
    <StyledContainer>
      <LogoContainer>
        <img src={imagePearLogo} height={50} alt="Pearsuite" />
      </LogoContainer>
      <ContentContainer>{children}</ContentContainer>
    </StyledContainer>
  </Stack>
);
