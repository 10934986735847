import { Form, Formik, FormikErrors, FormikProps } from "formik";
import { useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Divider, Group, Stack, Text, Title } from "@mantine/core";
import { FormikInput, LoaderComponent, StyledLabel } from "src/components";
import {
  useMutationCreateRecommendation,
  useMutationDeleteRecommendation,
  useMutationUpdateRecommendation,
  useQueryRecommendationById,
} from "src/graphql/Recommendations";
import { useAuthContext } from "src/hooks";
import { BASE_RECOMMENDATION_ID } from ".";
import { Save } from "react-feather";
import { RecommendationFormSchema } from "./schema";
import toast from "src/libs/toast";
import { createBaseRecommendationObj, RecommendationExtended } from "./utils";
import { RecommendationActions } from "./components/RecommendationActions";
import { useDataPointIdsWithAnswerTypes } from "src/hooks/useDataPointIdsWithAnswerTypes";
import {
  ConditionsGroups,
  unwrapConditionsGroupValues,
  wrapConditionsGroupValues,
} from "./components/ConditionsGroups";
import { FormikTextEditor } from "src/components/input/FormikTextEditor/FormikTextEditor";
import { AccessType, Permission } from "src/graphql";

export const EditRecommendationView = () => {
  const formikRef = useRef<FormikProps<RecommendationExtended> | null>(null);

  const params = useParams<{ recommendationId: string | undefined }>();
  const { selectedOrganizationId, hasAnyPermission } = useAuthContext();
  const history = useHistory();

  const isCreating =
    !params.recommendationId ||
    params.recommendationId === BASE_RECOMMENDATION_ID;

  const { data: recommendationByIdQuery, loading: isRecommendationLoading } =
    useQueryRecommendationById(
      params.recommendationId ?? BASE_RECOMMENDATION_ID,
      selectedOrganizationId,
      isCreating,
    );
  const readOnly =
    recommendationByIdQuery?.recommendationById.data?.accessType !==
      AccessType.Write && !isCreating;
  const [mutationCreateRecommendation, { loading: createLoading }] =
    useMutationCreateRecommendation(selectedOrganizationId);
  const [mutationUpdateRecommendation, { loading: updateLoading }] =
    useMutationUpdateRecommendation(selectedOrganizationId);
  const [mutationDeleteRecommendation, { loading: deleteLoading }] =
    useMutationDeleteRecommendation(selectedOrganizationId);

  const { answerTypesByDataId, loading: dataIdsLoading } =
    useDataPointIdsWithAnswerTypes(selectedOrganizationId, true);

  const isSubmitting = createLoading || updateLoading || deleteLoading;
  const isCreateOrUpdateLoading = createLoading || updateLoading;

  const recommendation = (recommendationByIdQuery?.recommendationById.data as
    | RecommendationExtended
    | undefined) ?? {
    ...createBaseRecommendationObj(selectedOrganizationId),
  };

  const handleCreate = async (values: RecommendationExtended) => {
    delete values._id;
    try {
      const response = await mutationCreateRecommendation({
        variables: {
          input: { ...values, organizationId: values.organizationId ?? "" },
        },
      });
      if (
        response.errors ||
        !response.data?.createRecommendation.success ||
        !response.data.createRecommendation.data
      ) {
        throw new Error("Graphql request failed; check logs");
      }
    } catch (e) {
      toast.error("Error while creating recommendation!");
      return;
    }

    toast.success("Recommendation created!");
    history.push("/recommendations");
  };

  const handleUpdate = async (values: RecommendationExtended) => {
    delete values.organizationId;
    try {
      const response = await mutationUpdateRecommendation({
        variables: {
          input: {
            ...values,
            _id: values._id ?? BASE_RECOMMENDATION_ID,
            organizationId: selectedOrganizationId,
          },
        },
      });
      if (
        response.errors ||
        !response.data?.updateRecommendation.success ||
        !response.data.updateRecommendation.data
      ) {
        throw new Error("Graphql request failed; check logs");
      }
    } catch (e) {
      toast.error("Error while updating recommendation!");
      return;
    }
    toast.success("Recommendation updated!");
    history.push("/recommendations");
  };

  const handleDelete = async () => {
    try {
      const response = await mutationDeleteRecommendation({
        variables: {
          recommendationId: recommendation._id ?? "BASE_RECOMMENDATION_ID",
          organizationId: selectedOrganizationId,
        },
      });

      if (
        response.errors ||
        !response.data?.deleteRecommendation.success ||
        !response.data.deleteRecommendation.data
      ) {
        throw new Error("Graphql request failed; check logs");
      }
    } catch (e) {
      toast.error("Error while deleting recommendation!");
      return;
    }
    toast.success("Recommendation Deleted!");
    history.push("/recommendations");
  };

  const onSubmit = async (values: RecommendationExtended) => {
    const recommendationInput: RecommendationExtended = {
      _id: values._id,
      name: values.name,
      recommendationText: values.recommendationText,
      organizationId: values.organizationId,
      actions: values.actions,
      conditionsGroups: unwrapConditionsGroupValues(values.conditionsGroups),
    };
    if (isCreating) {
      await handleCreate(recommendationInput);
    } else {
      await handleUpdate(recommendationInput);
    }
  };

  if (isRecommendationLoading || dataIdsLoading) return <LoaderComponent />;

  return (
    <Stack>
      <Title>
        {hasAnyPermission(Permission.RecommendationWriteAccess) &&
          (isCreating ? "Create " : "Update ")}
        Recommendation
      </Title>

      <Formik
        innerRef={formikRef}
        initialValues={{
          ...recommendation,
          conditionsGroups: wrapConditionsGroupValues(
            recommendation.conditionsGroups,
            answerTypesByDataId,
          ),
        }}
        validationSchema={RecommendationFormSchema}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
          dirty,
          errors,
        }) => {
          return (
            <Form onSubmit={handleSubmit} noValidate>
              <Stack>
                <FormikInput
                  required
                  type="text"
                  name="name"
                  label="Name"
                  disabled={
                    !hasAnyPermission(Permission.RecommendationWriteAccess)
                  }
                  onChange={handleChange}
                  placeholder="Enter recommendation name"
                />

                <FormikTextEditor
                  required
                  name="recommendationText"
                  label="Recommendation Description"
                  placeholder="Enter recommendation description"
                  hidePlaceholderButton
                  onChange={handleChange}
                  disabled={
                    !hasAnyPermission(Permission.RecommendationWriteAccess)
                  }
                  stickyToolbar={false}
                />

                <Stack spacing={0}>
                  <StyledLabel>Actions</StyledLabel>
                  <RecommendationActions
                    values={values}
                    setFieldValue={setFieldValue}
                    readOnly={readOnly}
                  />
                </Stack>

                <Divider />

                <Stack spacing={0}>
                  <StyledLabel>Conditions</StyledLabel>
                  <ConditionsGroups
                    conditionsGroups={values.conditionsGroups ?? []}
                    readOnly={readOnly}
                  />
                </Stack>

                {hasAnyPermission(Permission.RecommendationWriteAccess) && (
                  <>
                    <Divider />

                    <Group position="apart">
                      {!isCreating && (
                        <Button
                          color="red"
                          onClick={handleDelete}
                          disabled={isSubmitting || readOnly}
                        >
                          Delete
                        </Button>
                      )}

                      <Button
                        loading={isCreateOrUpdateLoading}
                        disabled={!dirty || isSubmitting || readOnly}
                        leftIcon={<Save size="18" />}
                        type="submit"
                      >
                        {isCreating ? "Create" : "Update"}
                      </Button>
                    </Group>
                  </>
                )}
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};

type CustomErrorMessageProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: FormikErrors<any>;
  fieldName: string;
  submitCount: number;
};

export const CustomErrorMessage = ({
  errors,
  fieldName,
  submitCount,
}: CustomErrorMessageProps) => {
  if (!errors[fieldName] || submitCount === 0) {
    return null;
  }
  return (
    <Text color="red" size="sm">
      {errors[fieldName]}
    </Text>
  );
};
