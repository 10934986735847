import { useMutation, useQuery } from "@apollo/client";

import {
  GetAssessmentResponse,
  MutationSubmitAssessmentArgs,
  QueryGetAssessmentArgs,
  SubmitAssessmentResponse,
} from "../schemaTypes";
import { GET_ASSESSMENT } from "./queries";
import { SUBMIT_ASSESSMENT } from "./mutations";
import { GET_FLOW_BY_ACTIVITY } from "../Flow/queries";

export const useQueryGetAssessment = (
  organizationId: string,
  flowId?: string,
  onboardingToken?: string,
) =>
  useQuery<{ getAssessment: GetAssessmentResponse }, QueryGetAssessmentArgs>(
    GET_ASSESSMENT,
    {
      skip: !flowId,
      variables: flowId ? { flowId, organizationId } : undefined,
      fetchPolicy: "no-cache",
      context: onboardingToken
        ? { headers: { "Pear-Onboarding-Survey": onboardingToken } }
        : undefined,
    },
  );

export const useMutationSubmitAssessment = (onboardingToken?: string) =>
  useMutation<
    { submitAssessment: SubmitAssessmentResponse },
    MutationSubmitAssessmentArgs
  >(SUBMIT_ASSESSMENT, {
    context: onboardingToken
      ? {
          headers: {
            "Pear-Onboarding-Survey": onboardingToken,
          },
        }
      : undefined,

    refetchQueries: [GET_ASSESSMENT, GET_FLOW_BY_ACTIVITY],
  });
