import { Node } from "react-flow-renderer";
import { StepNodeData } from "../util";

type StepNodeIdentifyingToastProps = {
  message: string;
  node: Node<StepNodeData>;
};

export const StepNodeIdentifyingToast = ({
  node,
  message,
}: StepNodeIdentifyingToastProps) => (
  <span>
    {message}
    <br />
    <strong>{`${node.data.step.question.dataId} | ${node.data.step.question.questionTitle}`}</strong>
  </span>
);
