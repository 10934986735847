import { ActionIcon, Button, Group, MultiSelect, Stack } from "@mantine/core";
import { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Plus, Save, Trash2 } from "react-feather";
import {
  DataTableComponent,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SectionHeader,
} from "src/components";
import toast from "src/libs/toast";
import { SelectOption } from "src/types";

type PlaceOfServiceCodesSectionProps = {
  selectedCodes: number[];
  setSelectedCodes: (codes: number[]) => void;
};

export const PlaceOfServiceCodesSection = ({
  selectedCodes,
  setSelectedCodes,
}: PlaceOfServiceCodesSectionProps) => {
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

  const columns: TableColumn<number>[] = [
    {
      name: "Code",
      selector: (code) =>
        placeOfServiceCodeOptions.find((x) => x.value === code.toString())
          ?.label ?? code,
    },
    {
      name: "Action",
      width: "20%",
      center: true,
      cell: (code) => (
        <ActionIcon
          onClick={() =>
            setSelectedCodes(selectedCodes.filter((x) => x !== code))
          }
        >
          <Trash2 size="16" color="red" />
        </ActionIcon>
      ),
    },
  ];

  return (
    <Stack spacing="md">
      <SectionHeader noGap>
        <Group position="apart">
          Place of Service Code
          <Button
            compact
            color="green"
            variant="subtle"
            onClick={() => setCreateModalIsOpen(true)}
            leftIcon={<Plus size={16} />}
          >
            Place of Service Code
          </Button>
        </Group>
      </SectionHeader>

      <Stack spacing="sm" px={10}>
        <DataTableComponent
          columns={columns}
          data={selectedCodes}
          noDataText="No place of service codes found"
          noCardWrapper
          dense
        />
      </Stack>

      <AddPlaceOfServiceCodesModal
        opened={createModalIsOpen}
        existingCodes={selectedCodes}
        onClose={() => setCreateModalIsOpen(false)}
        onSubmit={(code) =>
          setSelectedCodes([...new Set([...selectedCodes, ...code])])
        }
      />
    </Stack>
  );
};

type AddPlaceOfServiceCodesModalProps = {
  opened: boolean;
  existingCodes: number[];
  onSubmit: (code: number[]) => void;
  onClose: () => void;
};

const AddPlaceOfServiceCodesModal = (
  props: AddPlaceOfServiceCodesModalProps,
) => {
  const [selectedCode, setSelectedCode] = useState<number[]>([]);

  const handleSubmit = () => {
    if (!selectedCode) return toast.error("Please select a code");

    setSelectedCode([]);
    props.onSubmit(selectedCode);
    props.onClose();
  };

  const filteredOptions = placeOfServiceCodeOptions.filter(
    (option) => !props.existingCodes.includes(parseInt(option.value, 10)),
  );

  return (
    <Modal opened={props.opened} onClose={props.onClose}>
      <ModalHeader>Add Place of Service Code</ModalHeader>

      <ModalBody spacing="md">
        <MultiSelect
          name="code"
          placeholder="Select place of service code"
          data={filteredOptions}
          value={selectedCode.map((x) => x.toString())}
          onChange={(value) => {
            const codes = value
              .map((x) => parseInt(x, 10))
              .filter((x) => !isNaN(x));

            setSelectedCode(codes);
          }}
        />
      </ModalBody>

      <ModalFooter>
        <Button
          type="button"
          color="red"
          variant="outline"
          onClick={props.onClose}
        >
          Cancel
        </Button>

        <Button leftIcon={<Save size="18" />} onClick={handleSubmit}>
          Add Place of Service Code
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const placeOfServiceCodeLabelMap: Record<string, string> = {
  1: "Pharmacy",
  2: "Telehealth Provided Other than in Patient's Home",
  3: "School",
  4: "Homeless Shelter",
  5: "Indian Health Service Free-standing Facility",
  6: "Indian Health Service Provider-based Facility",
  7: "Tribal 638 Free-standing Facility",
  8: "Tribal 638 Provider-based Facility",
  9: "Prison/ Correctional Facility",
  10: "Telehealth Provided in Patient's Home",
  11: "Office",
  12: "Home",
  13: "Assisted Living Facility",
  14: "Group Home",
  15: "Mobile Unit",
  16: "Temporary Lodging",
  17: "Walk-in Retail Health Clinic",
  18: "Place of Employment-Worksite",
  19: "Off Campus-Outpatient Hospital",
  20: "Urgent Care Facility",
  21: "Inpatient Hospital",
  22: "On Campus-Outpatient Hospital",
  23: "Emergency Room - Hospital",
  24: "Ambulatory Surgical Center",
  25: "Birthing Center",
  26: "Military Treatment Facility",
  27: "Outreach Site/ Street",
  31: "Skilled Nursing Facility",
  32: "Nursing Facility",
  33: "Custodial Care Facility",
  34: "Hospice",
  41: "Ambulance Land",
  42: "Ambulance - Air or Water",
  49: "Independent Clinic",
  50: "Federally Qualified Health Center",
  51: "Inpatient Psychiatric Facility",
  52: "Psychiatric Facility-Partial Hospitalization",
  53: "Community Mental Health Center",
  54: "Intermediate Care Facility/ Individuals with Intellectual Disabilities",
  55: "Residential Substance Abuse Treatment Facility",
  56: "Psychiatric Residential Treatment Center",
  57: "Non-residential Substance Abuse Treatment Facility",
  58: "Non-residential Opioid Treatment Facility",
  60: "Mass Immunization Center",
  61: "Comprehensive Inpatient Rehabilitation Facility",
  62: "Comprehensive Outpatient Rehabilitation Facility",
  65: "End-Stage Renal Disease Treatment Facility",
  71: "Public Health Clinic",
  72: "Rural Health Clinic",
  81: "Independent Laboratory",
  99: "Other Place of Service",
};

export const placeOfServiceCodeOptions: SelectOption<string>[] = Object.entries(
  placeOfServiceCodeLabelMap,
).map(([value, label]) => ({
  value,
  label: `${value.toString().padStart(2, "0")} - ${label}`,
}));
