import { useEffect } from "react";
import {
  Permission,
  useCreatedPhoneCallEntrySubscription,
  useUpdatedPhoneCallEntrySubscription,
} from "src/graphql";
import { useCreatedOrUpdatedTextMessage } from "src/graphql/TextMessage";
import { useCreatedOrUpdatedTextMessageThreadSubscription } from "src/graphql/TextMessageThreads";
import {
  useCreatedVoicemailSubscription,
  useDeletedVoicemailSubscription,
  useUpdatedVoicemailSubscription,
} from "src/graphql/Voicemail";
import { useAuthContext } from "src/hooks";
import log from "loglevel";
import { useNotificationReceivedSubscription } from "src/graphql/Notification";

/**
 * tiny test component to test simple GraphQL subscriptions
 */
export const SubscriptionContainer = () => {
  const { selectedOrganizationId, hasAnyPermission } = useAuthContext();

  const hasMemberReadAccess = hasAnyPermission(Permission.MemberReadAccess);

  useCreatedOrUpdatedTextMessageThreadSubscription(
    selectedOrganizationId,
    hasMemberReadAccess,
  );
  useUpdatedVoicemailSubscription(selectedOrganizationId, hasMemberReadAccess);
  useCreatedVoicemailSubscription(selectedOrganizationId, hasMemberReadAccess);
  useDeletedVoicemailSubscription(selectedOrganizationId, hasMemberReadAccess);
  useCreatedPhoneCallEntrySubscription(
    selectedOrganizationId,
    hasMemberReadAccess,
  );
  const { data, error } = useUpdatedPhoneCallEntrySubscription(
    selectedOrganizationId,
    hasMemberReadAccess,
  );
  useCreatedOrUpdatedTextMessage(selectedOrganizationId, hasMemberReadAccess);
  useNotificationReceivedSubscription(selectedOrganizationId);

  useEffect(() => {
    if (error) {
      log.error(error);
    }
  }, [error]);

  useEffect(() => data && log.log(data), [data]);

  return null;
};
