import { DateTimePicker } from "@mantine/dates";
import { useField } from "formik";
import { useEffect, useMemo } from "react";
import {
  extractWrapperProps,
  FormikInputBaseProps,
  FormikInputWrapper,
} from "./FormikInputWrapper";

type FormikDateTimePickerInputProps = FormikInputBaseProps<string> & {
  clearable?: boolean;
  format?: string;
  onChangeOverride?: (event: Date | null) => void;
};

export const FormikDateTimePickerInput = (
  props: FormikDateTimePickerInputProps,
) => {
  const {
    clearable = true,
    className,
    style,
    disabled,
    onChangeOverride,
    format = "MM/DD/YYYY h:mm A",
    defaultValue,
    ...rest
  } = props;

  const [field, , helpers] = useField(rest);

  const value = useMemo(
    () => (!!field.value ? new Date(field.value) : null),
    [field.value],
  );

  const onChange = onChangeOverride
    ? (date: Date | null) => onChangeOverride(date)
    : (date: Date | null) => helpers.setValue(date?.toISOString() || "");

  useEffect(() => {
    if (!value && typeof defaultValue === "string")
      onChange(new Date(defaultValue));

    // Only need to set the value on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormikInputWrapper {...extractWrapperProps(props)}>
      <DateTimePicker
        {...field}
        clearable={clearable}
        valueFormat={format}
        value={value}
        style={style}
        placeholder={rest.placeholder}
        disabled={disabled}
        className={className}
        onChange={onChange}
        popoverProps={{ withinPortal: true }}
      />
    </FormikInputWrapper>
  );
};
