import { ActionIcon, Box, Group, Stack, Text, Tooltip } from "@mantine/core";
import dayjs from "dayjs";
import { Notification, NotificationType, User } from "src/graphql";
import ReactMarkdown from "react-markdown";
import { IconEyeCheck, IconTrash } from "@tabler/icons-react";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { useAuthContext } from "src/hooks";
import toast from "src/libs/toast";
import { useMemo } from "react";

type NoticationItemProps = {
  notification: Notification;
  markNotificationAsRead: (notificationId: string) => void;
  deleteNotification: (notificationId: string) => void;
  usersMap: Record<string, User>;
};

const StyledActionIcon = styled(ActionIcon)`
  &:hover {
    color: #57ad85;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
  }
`;

const NotificationTypeToColor = (
  notificationType: NotificationType,
  isRead: boolean,
): string => {
  const NotificationTypeColor = {
    [NotificationType.Success]: [
      "var(--color-pear-green)",
      "var(--color-pear-green-muted)",
    ],
    [NotificationType.Error]: [
      "var(--color-pear-red)",
      "var(--color-pear-red-muted)",
    ],
    [NotificationType.Informative]: [
      "var(--color-clean-blue)",
      "var(--color-clean-blue-muted)",
    ],
    [NotificationType.Warning]: [
      "var(--color-clean-yellow)",
      "var(--color-clean-yellow-muted)",
    ],
  };

  return NotificationTypeColor[notificationType][isRead ? 1 : 0];
};

export const NoticationItem = ({
  notification,
  deleteNotification,
  markNotificationAsRead,
  usersMap,
}: NoticationItemProps) => {
  const {
    selectedOrganizationId,
    setSelectedOrganizationId,
    organizationOptions,
  } = useAuthContext();

  const history = useHistory();
  const initiatingUserId = notification.templateContext.initiatingUserId;

  const user = usersMap[initiatingUserId ?? ""];

  const onNotificationClicked = () => {
    if ((notification.templateContext as { memberId?: string }).memberId) {
      const context = notification.templateContext as { memberId?: string };

      const resourceOrganizationId = notification.resourceOrganizationId;

      // go to member page automatically because orgId of resource matches current selected org
      if (
        resourceOrganizationId &&
        resourceOrganizationId === selectedOrganizationId
      ) {
        history.push(`/members/${context.memberId}`);
      }
      // first change the selected org and then go to member page
      if (
        resourceOrganizationId &&
        resourceOrganizationId !== selectedOrganizationId
      ) {
        const canChangeOrg = setSelectedOrganizationId(resourceOrganizationId);
        if (canChangeOrg) {
          // let wait for some MS to give app chance to change the org
          setTimeout(() => {
            history.push(`/members/${context.memberId}`);
            toast.success(
              "Organization changed because clicked member was part of other organization",
              { duration: 3000 },
            );
          }, 200);
        }
      }
    }

    if (!notification.isRead) {
      markNotificationAsRead(notification._id);
    }
  };

  const org = useMemo(() => {
    return organizationOptions.find(
      (org) => org.value === notification.resourceOrganizationId,
    );
  }, [notification.resourceOrganizationId, organizationOptions]);

  return (
    <Stack
      spacing={0}
      pt="5px"
      mb="lg"
      bg={NotificationTypeToColor(notification.type, notification.isRead)}
      style={{
        borderRadius: "4px",
        boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.1)",
      }}
    >
      <Box
        onClick={onNotificationClicked}
        m="5px"
        mt={0}
        px="xs"
        py={1}
        bg="white"
        className={css` 
          border-radius: 4px;
          box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.1);
          cursor: pointer;
          &:hover {
            box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.2);
            transform: scale(1.01);
          },
        `}
      >
        <ReactMarkdown children={notification.renderedContent} />
      </Box>

      <Group
        bg="#f9f9fc"
        style={{
          border: "1px solid lightgrey",
          borderTop: "none",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        p="10px"
      >
        <Stack spacing={0}>
          {user && (
            <Group spacing={0} align="flex-start" w="270px" noWrap>
              <Text
                fz="14px"
                mr="5px"
                color="grey"
                style={{ whiteSpace: "nowrap" }}
              >
                Done by:
              </Text>

              <Text fz="14px" fw={600} style={{ whiteSpace: "nowrap" }}>
                {user?.name}
              </Text>
              <Tooltip label={user?.email}>
                <Text ml="xs" fz="12px" color="grey" truncate="end">
                  {user?.email}
                </Text>
              </Tooltip>
            </Group>
          )}

          <Text fz="14px" color="grey" mt="xs">
            {dayjs(notification.timestamp).format("LLL")}
          </Text>
          {org && (
            <Text fz="14px" color="grey" mt="xs">
              {org?.label}
            </Text>
          )}
        </Stack>

        <Stack spacing={0} style={{ alignSelf: "flex-end" }}>
          {!notification.isRead && (
            <Tooltip label="Mark as read">
              <StyledActionIcon
                variant="light"
                size="sm"
                mb="10px"
                onClick={() => markNotificationAsRead(notification._id)}
              >
                <IconEyeCheck />
              </StyledActionIcon>
            </Tooltip>
          )}

          <Tooltip label="Delete">
            <StyledActionIcon
              variant="light"
              size="sm"
              onClick={() => deleteNotification(notification._id)}
            >
              <IconTrash />
            </StyledActionIcon>
          </Tooltip>
        </Stack>
      </Group>
    </Stack>
  );
};
