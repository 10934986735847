import { gql } from "@apollo/client";
import { trainingFields } from "./types";

export const UPSERT_TRAINING = gql`
  mutation UpsertTraining($input: TrainingInput!) {
    upsertTraining(input: $input) {
      success
      message
      data {
        ${trainingFields}
      }
    }
  }
`;

export const REVOKE_TRAINING = gql`
  mutation RevokeTraining($trainingId: ID!, $userId: ID!, $organizationId: ID!) {
    revokeTraining(trainingId: $trainingId, userId: $userId, organizationId: $organizationId) {
      success
      message
      data {
        ${trainingFields}
      }
    }
  }
`;
