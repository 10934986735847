import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Stack, Text } from "@mantine/core";
import { LoaderComponent } from "../loader";
import { AuthStep } from "./AuthFlowContainer";
import { AuthSplash } from "./AuthSplash";

/**
 * This component catches cases where a user follows a link to
 * /signup or /reset routes but is already signed in with a different
 * account, and offers them a choice between signing out to continue
 * with those processes for the account attached to the link,
 * or to proceed to the app with currently signed in account,
 * before rendering any other application children, including the
 * standard AuthProvider.
 *
 * Figured AuthProvider was already complex enough without handling this little
 * edge case.
 */

const trappedRoutes = ["/signup", "/reset"];

type ResetOrSignupLinkTrapProps = {
  children: React.ReactNode;
};

export const ResetOrSignupLinkTrap = ({
  children,
}: ResetOrSignupLinkTrapProps) => {
  const { user, isLoading, logout } = useAuth0();
  const history = useHistory();
  const location = useLocation();

  const path = location.pathname;
  const isResetOrSignupRoute = trappedRoutes.includes(path);

  const handleSignout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  // if it's not a reset or signup route, skip
  if (!isResetOrSignupRoute) return <>{children}</>;

  // or if it is, but authState is loaded and there's no active session, skip
  if (!isLoading && !user) return <>{children}</>;

  // otherwise, since we're on a reset/signup route, but someone is logged in,
  // offer either sign out to continue with reset/signup, or route to app.
  const actionName = path === "/signup" ? "Signup" : "Password Reset";
  return (
    <>
      <AuthSplash>
        <AuthStep>
          {isLoading && <LoaderComponent />}

          {!isLoading && (
            <Stack spacing="lg">
              <Text align="center">
                It looks like you've followed a <strong>{actionName}</strong>{" "}
                link, but are already signed in with another account.
              </Text>

              <Text align="center">
                Would you like to <strong>sign out</strong> now to complete your{" "}
                <strong>{actionName}</strong>?
              </Text>

              <Stack spacing="sm" mt="sm">
                <Button onClick={handleSignout}>Yes, sign me out!</Button>

                <Button variant="white" onClick={() => history.push("/")}>
                  No, proceed to Dashboard
                </Button>
              </Stack>
            </Stack>
          )}
        </AuthStep>
      </AuthSplash>
    </>
  );
};
