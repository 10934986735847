import { uniqueId } from "lodash";
import { memo } from "react";
import { Plus } from "react-feather";
import { Position } from "react-flow-renderer";
import { AnswerType } from "src/graphql";
import styled from "styled-components";
import { useFlowBuilderContext } from "../template-editor/context";
import { AnswerNodeData } from "../util";
import { FlowActionItem } from "./FlowActionItem";
import {
  AddActionButton,
  MeasurableNodeProps,
  NodeOverlayContainer,
} from "./shared";
import { StyledEdgeHandle } from "./StyledEdgeHandle";

const AnswerContainer = styled.div<{ readOnly: boolean }>`
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  padding: 5px;
  position: relative;
  cursor: ${({ readOnly }) => (readOnly ? "default" : "inherit")};
`;

const AddAnswerActionButton = styled(AddActionButton)`
  ${AnswerContainer}:hover & {
    opacity: 1;
  }
`;

const getDisplayValue = (answerValue: string, answerType: AnswerType) => {
  switch (answerType) {
    case AnswerType.Text:
      return "User Text Input";
    case AnswerType.Date:
      return "User Date Input";
    case AnswerType.Number:
      return "User Number Input";
    case AnswerType.ReadOnlyText:
      return "Acknowledge";
    default:
      return answerValue;
  }
};

export const TemplateAnswerFlowNode = memo(
  ({ data, id, measuringProps }: MeasurableNodeProps<AnswerNodeData>) => {
    const { answerValue, answerType, isOutputNode, actions } = data;
    const {
      readOnly,
      nodesSupportActions,
      grabbedStage,
      onToggleActionModal,
      onDeleteAction,
    } = useFlowBuilderContext();

    return (
      <div ref={measuringProps?.innerRef}>
        {grabbedStage !== null && <NodeOverlayContainer />}
        {/* Answer */}
        <AnswerContainer readOnly={readOnly} style={measuringProps?.style}>
          {getDisplayValue(answerValue, answerType)}

          {!readOnly &&
            nodesSupportActions &&
            answerType !== AnswerType.ReadOnlyText && (
              <AddAnswerActionButton onClick={() => onToggleActionModal(id)}>
                <Plus size={12} />
                <small>&nbsp;Answer action</small>
              </AddAnswerActionButton>
            )}

          {isOutputNode && (
            <StyledEdgeHandle
              type={"source"}
              position={Position.Right}
              isConnectable={!readOnly}
            />
          )}
        </AnswerContainer>

        {/* Answer Actions */}
        {nodesSupportActions &&
          actions.map((actionInfo, i) => (
            <FlowActionItem
              actionInfo={actionInfo}
              key={uniqueId()}
              onDeleteAction={(info) => onDeleteAction(id, info)}
              isLastAction={i === actions.length - 1}
            />
          ))}
      </div>
    );
  },
);
