import { Button, Select, Stack, Tabs } from "@mantine/core";
import PhoneInput from "react-phone-number-input";

import { ExternalContact, User, useLazyQueryAvailableUsers } from "src/graphql";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../modal";
import { isValidPhoneNumber } from "react-phone-number-input";

import { StyledLabel } from "src/components/input";
import { useMemo, useState } from "react";
import { useAuthContext } from "src/hooks/useAuthContext";
import toast from "src/libs/toast";
import ExternalContactsTable from "src/components/external-contacts-table/ExternalContactsTable";

type AddParticipantModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (userId?: string, externalPhoneNumber?: string) => void;
};

enum UserTab {
  Internal = "Internal",
  External = "External",
  ExternalContact = "ExternalContact",
}

export const AddParticipantModal = ({
  open,
  onClose,
  onSubmit,
}: AddParticipantModalProps) => {
  const { selectedOrganizationId, currentUser } = useAuthContext();

  const [userToAdd, setUserToAdd] = useState<User | undefined>();
  const [externalPhoneToAdd, setExternalPhoneToAdd] = useState<
    string | undefined
  >();
  const [externalContactToAdd, setExternalContactToAdd] = useState<
    ExternalContact | undefined
  >(undefined);

  const [currentTab, setCurrentTab] = useState<UserTab | null>(
    UserTab.Internal,
  );

  const [queryAvailableUsers, availableUsersData] = useLazyQueryAvailableUsers(
    selectedOrganizationId,
  );

  useMemo(() => {
    if (open) {
      queryAvailableUsers();
    }
  }, [open, queryAvailableUsers]);

  const activeUsers = useMemo(() => {
    return (
      availableUsersData.data?.availableUsersByOrganizationId?.data?.filter(
        (r) => r._id !== currentUser._id,
      ) ?? []
    );
  }, [
    availableUsersData.data?.availableUsersByOrganizationId?.data,
    currentUser._id,
  ]);

  const activeUserOptions = useMemo(() => {
    return (
      (activeUsers ?? []).map((u) => {
        return { label: u.name, value: u._id };
      }) ?? []
    );
  }, [activeUsers]);

  return (
    <Modal opened={open} onClose={onClose}>
      <ModalHeader withSubHeader>Add Participants</ModalHeader>

      <ModalBody>
        <Tabs
          value={currentTab}
          onTabChange={(t) => {
            setCurrentTab(t as UserTab);
          }}
        >
          <Stack>
            <Tabs.List>
              <Tabs.Tab value={UserTab.Internal}>By Name</Tabs.Tab>
              <Tabs.Tab value={UserTab.External}>By Phone Number</Tabs.Tab>
              <Tabs.Tab value={UserTab.ExternalContact}>
                By External Contact
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value={UserTab.Internal}>
              <Stack spacing={0}>
                <StyledLabel>Select User</StyledLabel>

                <Select
                  placeholder="Select an user"
                  data={activeUserOptions}
                  onChange={(v) => {
                    setUserToAdd((activeUsers ?? []).find((u) => u._id === v));
                  }}
                />
              </Stack>
            </Tabs.Panel>

            <Tabs.Panel value={UserTab.External}>
              <Stack spacing={0}>
                <StyledLabel>Phone Number</StyledLabel>

                <PhoneInput
                  type="tel"
                  name="phone"
                  required
                  onChange={(value) => setExternalPhoneToAdd(value)}
                  value={externalPhoneToAdd}
                />
              </Stack>
            </Tabs.Panel>

            <Tabs.Panel value={UserTab.ExternalContact}>
              <ExternalContactsTable
                organizationId={selectedOrganizationId}
                onSelect={setExternalContactToAdd}
                selectedId={externalContactToAdd?._id}
              />
            </Tabs.Panel>
          </Stack>
        </Tabs>
      </ModalBody>

      <ModalFooter>
        <Button
          onClick={() => {
            onSubmit(
              currentTab === UserTab.Internal ? userToAdd?._id : undefined,
              currentTab === UserTab.External
                ? externalPhoneToAdd
                : currentTab === UserTab.ExternalContact
                  ? externalContactToAdd?.phone
                  : undefined,
            );

            setUserToAdd(undefined);
            setExternalPhoneToAdd(undefined);
            setExternalContactToAdd(undefined);

            toast.success("Calling Participant...");
            onClose();
          }}
          disabled={
            !(
              (!!userToAdd && currentTab === UserTab.Internal) ||
              (!!externalPhoneToAdd &&
                currentTab === UserTab.External &&
                isValidPhoneNumber(externalPhoneToAdd)) ||
              (externalContactToAdd &&
                currentTab === UserTab.ExternalContact &&
                isValidPhoneNumber(externalContactToAdd.phone ?? ""))
            )
          }
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};
