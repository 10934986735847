import { useState, useEffect, useMemo } from "react";
import { Group, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import { Redirect, useParams } from "react-router-dom";
import { LoaderComponent } from "src/components";
import { Assessment } from "src/components/assessment/Assessment";
import {
  useMutationCreateMember,
  useMutationCreateOnboardingActivity,
  useQueryOnboardingOrganization,
} from "src/graphql";
import {
  Activity,
  ActivityStatus,
  ActivityType,
  Member,
  OnboardingSurvey,
  Organization,
} from "src/graphql/schemaTypes";
import toast from "src/libs/toast";
import styled from "styled-components";
import { MemberValues } from "../members/MemberFormFormik";
import { OnboardingMemberFormFormik } from "../members/OnboardingMemberFormFormik";
import Flow from "./Flow";
import { isValidFlowType } from "../templates-flow/FlowTemplateBuilderView";

const StyledContainer = styled.div`
  background-color: #fafafa;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 30px;
  overflow: auto;
  height: 90%;
  margin-top: 2%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 10px;
    margin-top: 5%;
  }
`;

export const Survey = () => {
  const { onboardingToken } = useParams<{ onboardingToken: string }>();
  const [survey, setSurvey] = useState<OnboardingSurvey>();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const organizationQuery = useQueryOnboardingOrganization(onboardingToken);
  const organization = useMemo(
    () => organizationQuery.data?.organizationByOnboardingToken.data,
    [organizationQuery.data?.organizationByOnboardingToken.data]
  );

  const [mutationCreateMember, mutationCreateMemberResponse] =
    useMutationCreateMember(organization?._id ?? "", onboardingToken);
  const member = mutationCreateMemberResponse.data?.createMember.data?.member;

  const [mutationCreateActivity, mutationCreateActivityResponse] =
    useMutationCreateOnboardingActivity(onboardingToken);
  const activity = useMemo(
    () => mutationCreateActivityResponse.data?.createActivity.data,
    [mutationCreateActivityResponse.data?.createActivity.data]
  );

  useEffect(() => {
    if (organization) {
      const matchedSurvey = organization?.onboardingSurveys?.find((survey) =>
        survey.link?.includes(onboardingToken)
      );
      if (matchedSurvey) {
        setSurvey(matchedSurvey);
      }
    }
  }, [organization, onboardingToken]);

  if (!organization)
    return (
      <Group align="center" position="center" h="100%" w="100%">
        <LoaderComponent />

        {organizationQuery.data?.organizationByOnboardingToken.success ===
          false && <Redirect to="/" />}
      </Group>
    );

  const onFormSubmit = async ({ contactInfo, ...member }: MemberValues) => {
    try {
      if (!organization) throw new Error("Organization not provided");

      const memberResponse = await mutationCreateMember({
        variables: {
          input: {
            ...member,
            // TOOD: This is redundant, we should get rid of it
            organizationId: organization._id,
            contactInfo: {
              ...contactInfo,
              organization: organization._id,
            },
          },
        },
      }).then((response) => response.data?.createMember);

      if (!memberResponse?.success || !memberResponse?.data?.member?._id)
        throw new Error(memberResponse?.message);

      if (!survey) throw new Error("Survey not found");

      const activityResponse = await mutationCreateActivity({
        variables: {
          input: {
            activityTemplateId: survey.activityTemplate,
            organizationId: organization._id,
            memberIds: [memberResponse.data.member._id],
            status: ActivityStatus.InProgress,
            date: new Date().toISOString(),
            scheduledEndAt: dayjs().add(15, "minutes").toISOString(),
          },
        },
      });

      if (!activityResponse.data?.createActivity?.success)
        throw new Error(activityResponse.data?.createActivity?.message);

      toast.success("Member created successfully!");
    } catch (error) {
      const defaultErrorMessage =
        "Couldn't submit the form! Something went wrong.";

      toast.error(
        error instanceof Error
          ? (error.message ?? defaultErrorMessage)
          : defaultErrorMessage
      );
    }
  };

  return (
    <StyledContainer>
      <Title
        order={1}
        size={isMobile ? 24 : undefined}
        align="center"
        mt="xs"
        mb="md"
      >
        {survey?.title ?? "Member Intake Form"}
      </Title>

      <OnboardingMemberFormFormik
        member={member}
        onSubmit={onFormSubmit}
        organization={organization}
        loading={
          mutationCreateMemberResponse.loading ||
          mutationCreateActivityResponse.loading
        }
        renderFlowSurvey={({ onComplete }) => {
          if (!member || !activity) return <LoaderComponent />;
          if (!survey?.activityTemplate) return <LoaderComponent />;
          return (
            <OnboardingFlowDisplay
              onComplete={onComplete}
              organization={organization}
              onboardingToken={onboardingToken}
              member={member}
              activity={activity}
              survey={survey}
            />
          );
        }}
      />
    </StyledContainer>
  );
};

interface OnboardingFlowDisplayProps {
  organization: Organization;
  onboardingToken: string;
  member: Member;
  activity: Activity;
  onComplete: () => void;
  survey: OnboardingSurvey;
}

const OnboardingFlowDisplay = ({
  organization,
  onboardingToken,
  member,
  activity,
  onComplete,
}: Readonly<OnboardingFlowDisplayProps>) => {
  const task = activity.tasks.find((task) => isValidFlowType(task.type));

  if (!task?.formId) return <LoaderComponent />;

  return (
    <Group h="100%" position="center" align="center" w="100%">
      <StyledContainer>
        <Title align="center" order={3} mb="md">
          Please answer the following questions
        </Title>

        {task.type === ActivityType.Assessment ? (
          <Assessment
            onboardingToken={onboardingToken}
            memberId={member._id}
            activityId={activity._id}
            flowTemplateFamilyId={task.formId}
            onComplete={onComplete}
            organizationId={organization._id}
          />
        ) : (
          <Flow
            onboardingToken={onboardingToken}
            organizationId={organization._id}
            memberId={member._id}
            activityId={activity._id}
            activityTaskId={task.id}
            onComplete={onComplete}
          />
        )}
      </StyledContainer>
    </Group>
  );
};
