import { Button } from "@mantine/core";
import log from "loglevel";
import * as Yup from "yup";
import toast from "src/libs/toast";

import {
  FormikInput,
  FormikMultiSelect,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "src/components";
import {
  ConsentMethod,
  ConsentTemplate,
  DataIdWithAnswerType,
  useMutationCreateOrUpdateConsentTemplate,
} from "src/graphql";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import { getEnumStringValues, ySelectOptionSchema } from "src/utils";
import { ConsentMethodLabel, makeMethodOptions } from "src/utils/consent";

interface ConsentTemplateModalProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  consentTemplate?: ConsentTemplate;
  organizationId: string;
  dataIdsAndAnswerTypes: DataIdWithAnswerType[];
}

export const ConsentTemplateModal = ({
  modalIsOpen,
  closeModal,
  consentTemplate,
  organizationId,
  dataIdsAndAnswerTypes,
}: ConsentTemplateModalProps) => {
  const [mutationConsentTemplate] = useMutationCreateOrUpdateConsentTemplate();

  const unUsableDataIds = useMemo(
    () =>
      dataIdsAndAnswerTypes
        ?.filter((dataIdInfo) => dataIdInfo.answerType !== "BOOLEAN")
        .map((dataIdInfo) => dataIdInfo.dataId) || [],
    [dataIdsAndAnswerTypes],
  );

  const initialValues = useMemo(() => {
    if (consentTemplate) {
      return {
        name: consentTemplate.name,
        description: consentTemplate.description,
        dataId: consentTemplate.dataId,
        methods: consentTemplate?.consentMethods
          ? consentTemplate?.consentMethods.map((method) => ({
              value: method,
              label: ConsentMethodLabel[method],
            }))
          : [],
      };
    } else {
      return {
        name: "",
        description: "",
        methods: [],
      };
    }
  }, [consentTemplate]);

  const handleSubmit = async (values: ConsentTemplateFormType) => {
    try {
      const res = await mutationConsentTemplate({
        variables: {
          input: {
            _id: consentTemplate?._id,
            organizationId,
            name: values.name,
            description: values.description ?? "",
            dataId: values.dataId ?? "",
            consentMethods: (values.methods ?? []).map(
              (method) => method.value as ConsentMethod,
            ),
          },
        },
      });
      if (!res.data?.createOrUpdateConsentTemplate?.success) throw new Error();
      toast.success(
        res.data?.createOrUpdateConsentTemplate?.message ?? "Success!",
      );
      closeModal();
    } catch (error) {
      log.error({ error });
      toast.error("Failed! Something went wrong.");
    }
  };

  return (
    <Modal opened={modalIsOpen} onClose={closeModal}>
      <Formik
        initialValues={initialValues as ConsentTemplateFormType}
        validationSchema={ConsentTemplateFormSchema(unUsableDataIds)}
        onSubmit={handleSubmit}
      >
        {({ isValid, dirty }) => {
          return (
            <Form>
              <ModalHeader>
                {consentTemplate ? "Edit" : "Create"} Consent Template
              </ModalHeader>

              <ModalBody spacing="md">
                <FormikInput
                  type="text"
                  name="name"
                  label="Name"
                  placeholder="Name"
                  required
                />

                <FormikInput
                  type="text"
                  name="dataId"
                  label="Data ID"
                  placeholder="Data ID"
                  required
                />
                <FormikInput
                  type="text"
                  name="description"
                  label="Description"
                  placeholder="Description"
                />

                <FormikMultiSelect
                  name="methods"
                  label="Consent Methods"
                  placeholder="Select consent methods"
                  options={makeMethodOptions()}
                  disabled={Boolean(consentTemplate)}
                  required
                />
              </ModalBody>

              <ModalFooter>
                <Button
                  type="button"
                  color="red"
                  variant="outline"
                  onClick={closeModal}
                >
                  Cancel
                </Button>

                <Button type="submit" disabled={!dirty || !isValid}>
                  {consentTemplate ? "Update" : "Create"}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const ConsentTemplateFormSchema = (unUsableDataIds: string[]) =>
  Yup.object({
    name: Yup.string().required(),
    description: Yup.string(),
    dataId: Yup.string()
      .required()
      .test(
        "testDataIdIsNewOrBoolean",
        "Data ID already exists and is not a True/False type",
        (dataId) => !unUsableDataIds.includes(dataId ?? ""),
      ),
    methods: Yup.array(
      ySelectOptionSchema(
        Yup.string().oneOf(getEnumStringValues(ConsentMethod)),
      ).strict(true),
    ).min(1),
  });

type ConsentTemplateFormType = Yup.InferType<
  ReturnType<typeof ConsentTemplateFormSchema>
>;
