import { useMutation, useQuery } from "@apollo/client";

import { GET_NOTE_TEMPLATES, GET_NOTE_TEMPLATE } from "./queries";

import {
  CREATE_NOTE_TEMPLATE,
  UPDATE_NOTE_TEMPLATE,
  DELETE_NOTE_TEMPLATE,
} from "./mutations";
import {
  QueryNoteTemplatesArgs,
  MutationUpdateNoteTemplateArgs,
  MutationDeleteNoteTemplateArgs,
  MutationCreateNoteTemplateArgs,
  GetNoteTemplatesInput,
  GetNoteTemplatesResponse,
  GetNoteTemplateResponse,
  DefaultResponse,
  GetNoteTemplateInput,
} from "../schemaTypes";

export const useQueryNoteTemplates = (input: GetNoteTemplatesInput) =>
  useQuery<{ noteTemplates: GetNoteTemplatesResponse }, QueryNoteTemplatesArgs>(
    GET_NOTE_TEMPLATES,
    {
      variables: { input },
    },
  );

export const useQueryNoteTemplate = (input: GetNoteTemplateInput) =>
  useQuery<{ noteTemplate: GetNoteTemplateResponse }>(GET_NOTE_TEMPLATE, {
    variables: { input },
  });

export const useMutationCreateNoteTemplate = () =>
  useMutation<
    { createNoteTemplate: GetNoteTemplateResponse },
    MutationCreateNoteTemplateArgs
  >(CREATE_NOTE_TEMPLATE, {
    refetchQueries: [GET_NOTE_TEMPLATES],
  });

export const useMutationUpdateNoteTemplate = () =>
  useMutation<
    { updateNoteTemplate: GetNoteTemplateResponse },
    MutationUpdateNoteTemplateArgs
  >(UPDATE_NOTE_TEMPLATE, {
    refetchQueries: [GET_NOTE_TEMPLATES],
  });

export const useMutationDeleteNoteTemplate = () =>
  useMutation<
    { deleteNoteTemplate: DefaultResponse },
    MutationDeleteNoteTemplateArgs
  >(DELETE_NOTE_TEMPLATE, {
    refetchQueries: [GET_NOTE_TEMPLATES],
  });
