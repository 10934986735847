import { useState, useEffect } from "react";
import { Button, Stack, TextInput, Text } from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { LoaderComponent } from "../loader";
import toast from "src/libs/toast";
import log from "loglevel";

type LoginFormProps = {
  onUserAuthenticated: () => void;
};
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const LoginForm = ({ onUserAuthenticated }: LoginFormProps) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPasswordResetScreen, setShowPasswordResetScreen] = useState(false);
  const [isAutoLoginProcessing, setIsAutoLoginProcessing] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      onUserAuthenticated();
    }
  }, [isAuthenticated, onUserAuthenticated]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const resetComplete = params.get("resetComplete") === "true";

    const issuer = params.get("iss");
    if (resetComplete || issuer) {
      setIsAutoLoginProcessing(true);
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.origin,
        },
      });
    }
  }, [loginWithRedirect, email]);

  const fetchToken = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/auth0-api/auth0-token`);
      return response.data.token;
    } catch (error) {
      throw new Error("Unable to fetch token");
    }
  };

  const handleLogin = async () => {
    setIsSubmitting(true);

    try {
      const token = await fetchToken();
      const response = await axios.get(
        `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users-by-email`,
        {
          params: { email },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const user = response.data[0];
      const isFirstTimeLogin = user?.user_metadata?.first_time_login;

      if (isFirstTimeLogin) {
        setShowPasswordResetScreen(true);
        return;
      }

      // Regular login flow for non-first-time users
      await loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.origin,
          login_hint: email,
          prompt: "login",
        },
      });
    } catch (error) {
      log.error("Error checking user status:", error);
      toast.error("Error checking user status. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSendResetEmail = async () => {
    if (!email) {
      toast.error("Email is required.");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth0-api/send-password-reset-email`,
        { email },
      );

      if (response.data.success) {
        toast.success("Password reset email sent. Check your inbox.");
        setResetEmailSent(true);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      log.error("Error sending password reset email:", error);
      toast.error("Unable to send password reset email. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isAutoLoginProcessing) {
    return <LoaderComponent />;
  }

  if (showPasswordResetScreen) {
    return (
      <Stack spacing="md" align="center">
        <Text size="lg" weight={600}>
          Password Reset Required
        </Text>
        {resetEmailSent ? (
          <Text align="center" size="sm" color="green">
            Password reset email sent! Check your inbox.
          </Text>
        ) : (
          <>
            <Text align="center" size="sm" color="dimmed">
              A password reset is required to continue. Click the button below
              to request a password reset email. Follow the instructions in the
              email to set your password.
            </Text>
            <Button
              loading={isSubmitting}
              onClick={handleSendResetEmail}
              fullWidth
              radius="sm"
            >
              Send Password Reset Email
            </Button>
          </>
        )}
      </Stack>
    );
  }

  return (
    <Stack spacing="md">
      <Text size="lg" weight={600} align="center">
        Welcome Back
      </Text>
      <TextInput
        placeholder="Enter your email"
        type="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && handleLogin()}
        size="md"
        radius="sm"
      />
      <Button
        loading={isSubmitting}
        onClick={handleLogin}
        fullWidth
        size="md"
        radius="sm"
      >
        Sign in
      </Button>
    </Stack>
  );
};
