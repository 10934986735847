import { ActionIcon, Center, Group, Text, Textarea } from "@mantine/core";
import React from "react";
import { Send } from "react-feather";
import toast from "src/libs/toast";

import { Organization } from "src/graphql";
import {
  GET_TEXT_MESSAGES,
  useMutationSendTextMessage,
} from "src/graphql/TextMessage";

interface MessageInputBoxProps {
  participantPhone: string;
  organization: Organization;
}

export const MessageInputBox = ({
  participantPhone,
  organization,
}: MessageInputBoxProps) => {
  const [newMessage, setNewMessage] = React.useState("");
  const [inputDisabled, setInputDisabled] = React.useState(false);
  const [sendText, { loading }] = useMutationSendTextMessage();

  const handleSendMessage = async () => {
    if (newMessage.trim().length === 0) {
      toast.error("Text cannot be empty");
      return;
    }

    const message = newMessage;
    setInputDisabled(true);

    const sentTextMessage = await sendText({
      variables: {
        input: {
          participantPhone,
          body: message,
          organizationId: organization._id,
          organizationPhone: organization.callerId || "",
        },
      },
      refetchQueries: [GET_TEXT_MESSAGES],
    });
    setInputDisabled(false);

    if (sentTextMessage.data?.sendTextMessage.success) {
      setNewMessage("");
    } else {
      setNewMessage(message);
      const errorMessage =
        sentTextMessage.data?.sendTextMessage?.message ||
        "Failed to send text message";
      toast.error(errorMessage);
    }
  };

  const sendMessage = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    handleSendMessage();
  };

  return (
    <Group p="md">
      <Textarea
        disabled={inputDisabled}
        placeholder="Message"
        autosize
        maxRows={10}
        style={{ flexGrow: 1 }}
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyDown={(e) => !e.shiftKey && e.key === "Enter" && sendMessage(e)}
        maxLength={1600}
        rightSection={
          <Text size="sm" color="dimmed" mr={8}>
            {1600 - newMessage.length}
          </Text>
        }
      />

      <ActionIcon
        size="lg"
        radius="xl"
        pt={5}
        pb={3}
        pr={5}
        color="green"
        variant="filled"
        loading={loading}
        onClick={sendMessage}
        data-testid="send-message"
      >
        <Center>
          <Send />
        </Center>
      </ActionIcon>
    </Group>
  );
};
