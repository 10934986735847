import { FieldArray, useFormikContext } from "formik";
import { ActionIcon, Button, Group } from "@mantine/core";
import { Plus, Trash } from "react-feather";
import { ConditionOperator, Operator, Permission } from "src/graphql";
import { useAuthContext } from "src/hooks";
import styled from "styled-components";
import { RecommendationExtended } from "../utils";
import { Condition, StyledCondition, StyledConditions } from "./Condition";
import { WrappedConditionItem } from "./ConditionsGroups";
import { generateMongoObjectID } from "src/components/carepathways/utils";

const StyledConditionsWrapper = styled("div")`
  box-sizing: border-box;
  padding: 25px;
  padding-bottom: 30px;
  border: 1px dashed lightgrey;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 30px;
  position: relative;
`;

type ConditionsWrapperProps = {
  index: number;
  hasNextItem: boolean;
  removeConditionGroup?: () => void;
  readOnly?: boolean;
  hideConditionOperators?: boolean;
};

export const ConditionsWrapper = ({
  index,
  removeConditionGroup,
  hasNextItem,
  readOnly,
  hideConditionOperators,
}: ConditionsWrapperProps) => {
  const { values, setFieldValue } = useFormikContext<RecommendationExtended>();

  const { hasAnyPermission } = useAuthContext();

  const itemPath = `conditionsGroups.${index}`;

  const onConditionOperatorClicked = (conditionOperator: ConditionOperator) => {
    if (conditionsGroupItem.conditionOperator !== conditionOperator) {
      setFieldValue(`${itemPath}.conditionOperator`, conditionOperator);
    }
  };

  const conditionsGroupItem = values.conditionsGroups[index];
  return (
    <StyledConditionsWrapper>
      <FieldArray name={`${itemPath}.conditions`}>
        {({ push, remove }) => (
          <>
            {values.conditionsGroups[index]?.conditions?.map(
              (conditionItem, conditionIndex) => {
                return (
                  <Condition
                    readOnly={readOnly}
                    conditionItem={conditionItem}
                    key={`condition-${index}-'${conditionIndex}`}
                    itemPath={`conditionsGroups.${index}.conditions.${conditionIndex}`}
                    hasNextItem={
                      conditionsGroupItem.conditions.length - 2 >=
                      conditionIndex
                    }
                    removeItem={() => {
                      if (conditionsGroupItem.conditions.length === 1) {
                        remove(conditionIndex);
                        removeConditionGroup?.();
                        return;
                      }
                      remove(conditionIndex);
                    }}
                    hideConditionOperator={hideConditionOperators}
                  />
                );
              },
            )}

            {hasAnyPermission(Permission.RecommendationWriteAccess) && (
              <Group position="apart">
                <Button
                  disabled={readOnly}
                  size="xs"
                  variant="outline"
                  leftIcon={<Plus size="18" />}
                  onClick={() => push(makeBaseConditionItem())}
                >
                  Add New Condition
                </Button>

                {removeConditionGroup && (
                  <ActionIcon
                    disabled={readOnly}
                    mt={30}
                    variant="outline"
                    color="red"
                    radius="xl"
                    onClick={() => removeConditionGroup?.()}
                  >
                    <Trash size={12} />
                  </ActionIcon>
                )}
              </Group>
            )}
          </>
        )}
      </FieldArray>

      {hasNextItem && (
        <StyledConditions>
          <StyledCondition
            active={
              conditionsGroupItem.conditionOperator === ConditionOperator.And
            }
            onClick={() => {
              if (!hasAnyPermission(Permission.RecommendationWriteAccess))
                return;
              onConditionOperatorClicked(ConditionOperator.And);
            }}
          >
            AND
          </StyledCondition>
          <StyledCondition
            active={
              conditionsGroupItem.conditionOperator === ConditionOperator.Or
            }
            onClick={() => {
              if (!hasAnyPermission(Permission.RecommendationWriteAccess))
                return;
              onConditionOperatorClicked(ConditionOperator.Or);
            }}
          >
            OR
          </StyledCondition>
        </StyledConditions>
      )}
    </StyledConditionsWrapper>
  );
};

export const makeBaseConditionItem = (): WrappedConditionItem => ({
  _id: generateMongoObjectID(),
  operator: Operator.Equal,
  dataId: "",
  conditionOperator: ConditionOperator.And,
  value: [],
});
