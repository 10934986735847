import { useRef, useEffect } from "react";
import { Auth as FirebaseAuth, RecaptchaVerifier } from "firebase/auth";

const E2EUserEmails = process.env.REACT_APP_E2E_USER_EMAILS?.split(",") || [];

export const useRecaptchaVerifier = (
  auth: FirebaseAuth,
  userEmail?: string,
) => {
  const recaptchaContainerRef = useRef<HTMLDivElement>(null);
  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);

  auth.settings.appVerificationDisabledForTesting = E2EUserEmails.includes(
    userEmail as string,
  );

  useEffect(() => {
    if (!recaptchaContainerRef.current) return;

    recaptchaVerifierRef.current = new RecaptchaVerifier(
      auth,
      recaptchaContainerRef.current,
      {
        size: "invisible",
        // eslint-disable-next-line
        callback: (res: any) => {},
      },
    );

    return () => {
      recaptchaVerifierRef.current?.clear();
    };
  });

  return { recaptchaContainerRef, recaptchaVerifierRef };
};
