import {
  Button,
  Group,
  LoadingOverlay,
  Stack,
  Switch,
  Text,
} from "@mantine/core";
import { NoticationItem } from "./NotificationItem";
import {
  useMutationDeleteNotification,
  useMutationMarkAllNotificationsAsRead,
  useMutationMarkNotificationAsRead,
  useQueryPaginatedNotifications,
} from "src/graphql/Notification";
import { useEffect, useMemo, useState } from "react";
import { useAuthContext } from "src/hooks";
import { useQueryUsers } from "src/graphql";
import { arrayToKeyedObj } from "src/utils";
import { IconSettings } from "@tabler/icons-react";

type NotificationsContainerProps = {
  changeNotificationModalOpen: boolean;
  openSettingsDrawer: () => void;
};

export const NotificationsContainer = (props: NotificationsContainerProps) => {
  const [page, setPage] = useState(0);

  const { selectedOrganizationId } = useAuthContext();

  const {
    data: notificationsData,
    loading: notificationsDataLoading,
    refetch: notificationsRefetch,
  } = useQueryPaginatedNotifications(false, page, selectedOrganizationId);

  const [
    mutationMarkAllNotificationsAsRead,
    { loading: mutationMarkAllNotificationsAsReadLoading },
  ] = useMutationMarkAllNotificationsAsRead(selectedOrganizationId);

  const [
    mutationDeleteNotification,
    { loading: mutationDeleteNotificationLoading },
  ] = useMutationDeleteNotification("", selectedOrganizationId);

  const [
    mutationMarkNotificationAsRead,
    { loading: mutationMarkNotificationAsReadLoading },
  ] = useMutationMarkNotificationAsRead("", selectedOrganizationId);

  const { data } = useQueryUsers({ organizationId: selectedOrganizationId });

  const usersMap = useMemo(() => {
    return arrayToKeyedObj(data?.users?.data ?? [], "_id");
  }, [data]);

  useEffect(() => {
    if (props.changeNotificationModalOpen) {
      notificationsRefetch();
    }
  }, [notificationsRefetch, props.changeNotificationModalOpen]);

  const switchShowOnlyUnRead = (value: boolean) => {
    notificationsRefetch({
      showOnlyUnRead: value,
    }).catch(() => {});
  };

  const markAllNotificationsAsRead = () => {
    mutationMarkAllNotificationsAsRead({
      onCompleted: () => {
        setPage(0);
      },
    }).catch((e) => {});
  };

  const markNotificationAsRead = (notificationId: string) => {
    mutationMarkNotificationAsRead({
      variables: {
        notificationId,
      },
      onCompleted: () => {
        notificationsRefetch();
      },
    }).catch((e) => {});
  };

  const deleteNotification = (notificationId: string) => {
    mutationDeleteNotification({
      variables: {
        notificationId,
      },
      onCompleted: () => {
        notificationsRefetch();
      },
    }).catch((e) => {});
  };

  const isThereAnyNotification =
    (notificationsData?.paginatedNotifications?.data ?? []).length > 0;

  return (
    <Stack spacing={0}>
      <Group
        py="md"
        mb="lg"
        align="center"
        style={{
          justifyContent: "space-between",
          borderBottom: "1px solid lightgrey",
        }}
      >
        <Text fw={600} fz={"lg"}>
          Notifications
        </Text>
        <Switch
          labelPosition="left"
          label="Only show unread"
          color="green"
          onClick={(e) => switchShowOnlyUnRead(e.currentTarget.checked)}
        />
      </Group>

      <Group spacing={0} mb="lg">
        {isThereAnyNotification && (
          <Button
            variant="subtle"
            compact
            px={0}
            onClick={markAllNotificationsAsRead}
          >
            Mark all as read
          </Button>
        )}

        <Button
          leftIcon={<IconSettings size="14px" />}
          variant="subtle"
          compact
          px={0}
          ml="auto"
          onClick={props.openSettingsDrawer}
        >
          Settings
        </Button>
      </Group>
      {(notificationsData?.paginatedNotifications?.data ?? []).map(
        (notification) => (
          <NoticationItem
            key={notification._id}
            markNotificationAsRead={markNotificationAsRead}
            deleteNotification={deleteNotification}
            notification={notification}
            usersMap={usersMap}
          />
        ),
      )}
      {!isThereAnyNotification && (
        <Text ml="auto" mr="auto" fz="lg" variant="gradient">
          You dont have any notifications
        </Text>
      )}
      <LoadingOverlay
        visible={
          notificationsDataLoading ||
          mutationMarkAllNotificationsAsReadLoading ||
          mutationDeleteNotificationLoading ||
          mutationMarkNotificationAsReadLoading
        }
        overlayBlur={2}
      />
    </Stack>
  );
};
