import { useMutation, useQuery } from "@apollo/client";
import {
  CertificationResponse,
  CertificationTemplateResponse,
  CertificationTemplatesResponse,
  CertificationsResponse,
  MutationCreateOrUpdateCertificationArgs,
  MutationCreateOrUpdateCertificationTemplateArgs,
  QueryCertificationsByUserIdArgs,
  MutationDeleteCertificationTemplateArgs,
  QueryCertificationTemplatesByOrganizationIdArgs,
  MutationRevokeCertificationArgs,
} from "../schemaTypes";
import { GET_CERTIFICATIONS, GET_CERTIFICATION_TEMPLATES } from "./queries";
import {
  CREATE_OR_UPDATE_CERTIFICATION,
  CREATE_OR_UPDATE_CERTIFICATION_TEMPLATE,
  DELETE_CERTIFICATION_TEMPLATE,
  REVOKE_CERTIFICATION,
} from "./mutations";

export const useQueryCertificationTemplates = (
  organizationId: string,
  showDeleted?: boolean,
) =>
  useQuery<
    {
      certificationTemplatesByOrganizationId: CertificationTemplatesResponse;
    },
    QueryCertificationTemplatesByOrganizationIdArgs
  >(GET_CERTIFICATION_TEMPLATES, { variables: { organizationId } });

export const useMutationCreateOrUpdateCertificationTemplate = () =>
  useMutation<
    { createOrUpdateCertificationTemplate: CertificationTemplateResponse },
    MutationCreateOrUpdateCertificationTemplateArgs
  >(CREATE_OR_UPDATE_CERTIFICATION_TEMPLATE, {
    refetchQueries: [GET_CERTIFICATION_TEMPLATES],
  });

export const useQueryCertificationsByUser = (
  organizationId: string,
  userId: string,
  showDeleted?: boolean,
) =>
  useQuery<
    {
      certificationsByUserId: CertificationsResponse;
    },
    QueryCertificationsByUserIdArgs
  >(GET_CERTIFICATIONS, {
    variables: { organizationId, userId },
    skip: !userId,
  });

export const useMutationCreateOrUpdateCertification = () =>
  useMutation<
    { createOrUpdateCertification: CertificationResponse },
    MutationCreateOrUpdateCertificationArgs
  >(CREATE_OR_UPDATE_CERTIFICATION, {
    refetchQueries: [GET_CERTIFICATIONS],
  });

export const useMutationDeleteCertificationTemplate = () =>
  useMutation<
    { deleteCertificationTemplate: CertificationTemplateResponse },
    MutationDeleteCertificationTemplateArgs
  >(DELETE_CERTIFICATION_TEMPLATE, {
    refetchQueries: [GET_CERTIFICATION_TEMPLATES],
  });

export const useMutationRevokeCertification = () =>
  useMutation<
    { revokeCertification: CertificationResponse },
    MutationRevokeCertificationArgs
  >(REVOKE_CERTIFICATION, {
    refetchQueries: [GET_CERTIFICATIONS],
  });
