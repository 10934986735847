import { useLazyQuery, useQuery } from "@apollo/client";
import {
  ActivitiesStatsResponse,
  AllowedCustomMetricsResponse,
  AnalyticsFiltersMetadataResponse,
  AnalyticsInput,
  CallCenterStatsResponse,
  CarePathwaysStatsResponse,
  DataPointStatsResponse,
  DemographicsStatsResponse,
  ExternalResourcesStatsResponse,
  GoalsStatsResponse,
  QueryActivitiesStatsArgs,
  QueryAdditionalFiltersOptionsArgs,
  QueryAllowedCustomMetricsArgs,
  QueryCallCenterStatsArgs,
  QueryCarePathwaysStatsArgs,
  QueryDataPointStatsArgs,
  QueryDemographicsStatsArgs,
  QueryExternalResourcesStatsArgs,
  QueryGoalsStatsArgs,
  QuerySummaryStatsArgs,
  SummaryStatsResponse,
} from "src/graphql";
import {
  GET_ACTIVITIES_STATS,
  GET_ADDITIONAL_FILTERS,
  GET_ALLOWED_CUSTOM_METRICS,
  GET_CALL_CENTER_STATS,
  GET_CARE_PATHWAYS_STATS,
  GET_DATAPOINT_STATS,
  GET_DEMOGRAPHICS_STATS,
  GET_EXTERNAL_RESOURCES_STATS,
  GET_GOALS_STATS,
  GET_SUMMARY_STATS,
} from "./queries";

export const useLazyQueryActivitiesStats = (input: AnalyticsInput) =>
  useLazyQuery<
    { activitiesStats: ActivitiesStatsResponse },
    QueryActivitiesStatsArgs
  >(GET_ACTIVITIES_STATS, {
    variables: { input },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

export const useLazyQueryGoalsStats = (input: AnalyticsInput) =>
  useLazyQuery<{ goalsStats: GoalsStatsResponse }, QueryGoalsStatsArgs>(
    GET_GOALS_STATS,
    {
      variables: { input },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    },
  );

export const useCarePathwaysStats = (input: AnalyticsInput) =>
  useLazyQuery<
    { carePathwaysStats: CarePathwaysStatsResponse },
    QueryCarePathwaysStatsArgs
  >(GET_CARE_PATHWAYS_STATS, {
    variables: { input },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

export const useExternalResourcesStats = (input: AnalyticsInput) =>
  useLazyQuery<
    { externalResourcesStats: ExternalResourcesStatsResponse },
    QueryExternalResourcesStatsArgs
  >(GET_EXTERNAL_RESOURCES_STATS, {
    variables: { input },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

export const useLazyQueryDemographicsStats = (input: AnalyticsInput) =>
  useLazyQuery<
    { demographicsStats: DemographicsStatsResponse },
    QueryDemographicsStatsArgs
  >(GET_DEMOGRAPHICS_STATS, {
    variables: { input },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

export const useLazyQueryDataPointStats = (input: AnalyticsInput) =>
  useLazyQuery<
    { dataPointStats: DataPointStatsResponse },
    QueryDataPointStatsArgs
  >(GET_DATAPOINT_STATS, {
    variables: { input },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

export const useLazyQuerySummaryStatsStats = (input: AnalyticsInput) =>
  useLazyQuery<{ summaryStats: SummaryStatsResponse }, QuerySummaryStatsArgs>(
    GET_SUMMARY_STATS,
    {
      variables: { input },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    },
  );

export const useLazyQueryCallCenterStatsStats = (input: AnalyticsInput) =>
  useLazyQuery<
    { callCenterStats: CallCenterStatsResponse },
    QueryCallCenterStatsArgs
  >(GET_CALL_CENTER_STATS, {
    variables: { input },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

export const useLazyQueryAdditionalFiltersOptions = (input: AnalyticsInput) =>
  useLazyQuery<
    { additionalFiltersOptions: AnalyticsFiltersMetadataResponse },
    QueryAdditionalFiltersOptionsArgs
  >(GET_ADDITIONAL_FILTERS, {
    variables: { input },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

export const useQueryAllowedCustomMetrics = (organizationId: string) =>
  useQuery<
    { allowedCustomMetrics: AllowedCustomMetricsResponse },
    QueryAllowedCustomMetricsArgs
  >(GET_ALLOWED_CUSTOM_METRICS, { variables: { organizationId } });
