import { LockingReferenceFields } from "../Common/types";
import { DataPointTemplateFields } from "../DataPointTemplate/types";
import { taggedEntityFields } from "../Tag/types";

export const questionFields = `
  _id
  dataId
  organizationId
  questionType
  questionTitle
  questionText
  answerType
  answerOptions
  accessType
  tags {
    ${taggedEntityFields}
  }
  lockingReferences {
    ${LockingReferenceFields}
  }
  dataPointTemplateId
  dataPointTemplate {
    ${DataPointTemplateFields}
  }
  createdAt
  updatedAt
`;

export const getPaginatedQuestionFields = `
  _id
  dataId
  organizationId
  questionType
  questionTitle
  questionText
  answerType
  answerOptions
  tags {
    ${taggedEntityFields}
  }
  lockingReferences {
    ${LockingReferenceFields}
  }
  dataPointTemplateId
  dataPointTemplate {
    dataId
  }
  createdAt
  updatedAt
`;
