import { WatchQueryFetchPolicy, useMutation, useQuery } from "@apollo/client";
import {
  GET_DATA_BY_ACTIVITY,
  GET_DATA_BY_MEMBER,
  GET_DATA_IDS_WITH_ANSWER_TYPE_BY_ORGANIZATION_ID,
} from "./queries";
import {
  BulkDataResponse,
  DataIdWithAnswerTypeResponse,
  DataResponse,
  MutationCreateDataArgs,
  MutationUpdateDataArgs,
} from "../schemaTypes";
import { CREATE_DATA } from "./mutations";

export const useQueryGetDataByMember = (
  organizationId: string,
  memberId: string,
) =>
  useQuery<{
    dataByMember: BulkDataResponse;
  }>(GET_DATA_BY_MEMBER, { variables: { memberId, organizationId } });

export const useQueryGetDataByActivity = (
  activityId: string | undefined,
  organizationId: string,
  memberId?: string,
) =>
  useQuery<{
    dataByActivity: BulkDataResponse;
  }>(GET_DATA_BY_ACTIVITY, {
    variables: { activityId, memberId, organizationId },
    skip: !activityId,
  });

export const useQueryDataIdsWithAnswerTypesByOrganizationId = (
  organizationId: string,
  includeDemographicOptions = false,
  fetchPolicy: WatchQueryFetchPolicy = "cache-and-network",
) =>
  useQuery<{
    dataIdsWithAnswerTypesByOrganizationId: DataIdWithAnswerTypeResponse;
  }>(GET_DATA_IDS_WITH_ANSWER_TYPE_BY_ORGANIZATION_ID, {
    variables: { organizationId, includeDemographicOptions },
    fetchPolicy,
  });

export const useMutationCreateData = () =>
  useMutation<
    {
      createData: DataResponse;
    },
    MutationCreateDataArgs
  >(CREATE_DATA, {
    refetchQueries: [
      GET_DATA_BY_MEMBER,
      GET_DATA_BY_ACTIVITY,
      GET_DATA_IDS_WITH_ANSWER_TYPE_BY_ORGANIZATION_ID,
    ],
  });

export const useMutationUpdateData = () =>
  useMutation<
    {
      updateData: DataResponse;
    },
    MutationUpdateDataArgs
  >(CREATE_DATA, {
    refetchQueries: [
      GET_DATA_BY_MEMBER,
      GET_DATA_BY_ACTIVITY,
      GET_DATA_IDS_WITH_ANSWER_TYPE_BY_ORGANIZATION_ID,
    ],
  });
