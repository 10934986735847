import { gql } from "@apollo/client";
import { voicemailFields } from "./types";

export const UPDATE_VOICEMAIL = gql`
  mutation UpdateVoicemail($input: UpdateVoicemailInput!) {
    updateVoicemail(input: $input) {
      success
      message
      data {
        ${voicemailFields}
      }
    }
  }
`;

export const UPDATE_VOICEMAIL_BY_CALL = gql`
  mutation UpdateVoicemailByCall($input: UpdateVoicemailByCallInput!) {
    updateVoicemailByCall(input: $input) {
      success
      message
      data {
        _id
        createdAt
        listenedTo
        organizationId
        recordingSid
        transcription
      }
    }
  }
`;

export const DELETE_VOICEMAIL = gql`
  mutation DeleteVoicemail($voicemailId: ID!, $organizationId: ID!) {
    deleteVoicemail(
      voicemailId: $voicemailId
      organizationId: $organizationId
    ) {
      success
      message
    }
  }
`;
