import { Stack } from "@mantine/core";
import { Formik, FormikProps } from "formik";
import log from "loglevel";
import { useRef } from "react";
import toast from "src/libs/toast";
import * as Yup from "yup";

import { FormikInput, FormikSelect } from "src/components";
import {
  ActionType,
  DataIdWithAnswerType,
  MemberField,
  UpdateMemberData,
} from "src/graphql";
import { SelectOption } from "src/types";
import { ySelectOptionSchema } from "src/utils";
import { ActionInfo } from "../../../util";
import { SubFormCommonProps } from "../AddActionModal";

type UpdateMemberActionFormProps = SubFormCommonProps & {
  memberFieldOptions: SelectOption<MemberField>[];
  dataIdOptions: SelectOption<string>[];
  dataIdsById: Record<string, DataIdWithAnswerType>;
};

export const UpdateMemberActionForm = ({
  memberFieldOptions,
  dataIdOptions,
  innerRef,
  node,
  onCreateAction,
  onDirtyStateChange,
  onValidStateChange,
}: UpdateMemberActionFormProps) => {
  const dirtyStateRef = useRef(false);
  const validStateRef = useRef(false);

  const initialValues: WrappedFormValues = {
    field: null,
    isConstant: {
      label: "Constant",
      value: "true",
    },
    constantValue: null,
    dynamicValue: null,
  };

  return (
    <Formik
      innerRef={innerRef as React.RefObject<FormikProps<WrappedFormValues>>}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        if (!node) return; // nothing renders without a node selected
        try {
          setSubmitting(true);
          const actionInfo = parseFormValues(formValues);
          onCreateAction(node, actionInfo);
          // eslint-disable-next-line
        } catch (err: any) {
          log.error(err.message);
          setSubmitting(false);
          toast.error("Failed to add action; please try again");
        }
      }}
    >
      {({ values, dirty, isValid }) => {
        if (onDirtyStateChange && dirty !== dirtyStateRef.current) {
          dirtyStateRef.current = dirty;
          requestAnimationFrame(() => onDirtyStateChange(dirty));
        }
        if (onValidStateChange && isValid !== validStateRef.current) {
          validStateRef.current = isValid;
          requestAnimationFrame(() => onValidStateChange(isValid));
        }

        return (
          <Stack mt="0.75em" spacing="0.75em">
            <FormikSelect
              name="field"
              label="Fields"
              options={memberFieldOptions}
            />

            <FormikSelect
              name="isConstant"
              label="Data Input Type"
              options={[
                { value: "true", label: "Constant" },
                { value: "false", label: "Dynamic" },
              ]}
            />

            {values.isConstant?.value === "true" ? (
              <FormikInput
                label="Constant"
                type="text"
                name="constantValue"
                autoFocus
              />
            ) : (
              <FormikSelect
                name="dynamicValue"
                label="DataId of values to update member field with"
                options={dataIdOptions}
              />
            )}
          </Stack>
        );
      }}
    </Formik>
  );
};

type WrappedFormValues = Omit<
  UpdateMemberData,
  "field" | "isConstant" | "value"
> & {
  field: SelectOption<MemberField> | null;
  isConstant: SelectOption<"true" | "false"> | null;
  constantValue: string | null;
  dynamicValue: SelectOption<string> | null;
};

const parseFormValues = (formValues: WrappedFormValues): ActionInfo => {
  if (!formValues.field?.value)
    throw new Error("Missing Member Field - check schema validator");

  if (!formValues.isConstant?.value)
    throw new Error("Missing Update Type - check schema validator");

  const isConstant = formValues.isConstant.value === "true";

  const value = isConstant
    ? formValues.constantValue
    : formValues.dynamicValue?.value;

  if (!value) throw new Error("Missing Target Value - check schema validator");

  return {
    actionType: ActionType.UpdateMemberData,
    actionData: {
      field: formValues.field.value,
      value: { constant: isConstant, input: value },
    },
  };
};

const ValidationSchema = Yup.object({
  field: ySelectOptionSchema(
    Yup.mixed<MemberField>().oneOf(Object.values(MemberField)).required(),
  ).required(),
  isConstant: ySelectOptionSchema(Yup.boolean().required()).required(),
});
