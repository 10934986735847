import { useRef } from "react";
import { useDrop, XYCoord } from "react-dnd";
import { DragType } from "./draggable";

export type Dimensions = {
  height: number;
  width: number;
};

export type DropHandler<T> = (dropResult: {
  item: T;
  clientOffset: XYCoord;
  relativeOffset: XYCoord;
  containerDimensions: Dimensions;
}) => void;

type DroppableProps<T> = {
  accept: DragType;
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDrop: DropHandler<T>;
};

export const Droppable = <T,>({
  accept,
  children,
  onDrop,
}: DroppableProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      drop: (item: any, monitor) => {
        // offset of container element from window
        const container = containerRef.current;
        const rect = container?.getBoundingClientRect();

        const containerLeft = rect?.left ?? 0;
        const containerTop = rect?.top ?? 0;

        // offset of drop event from window
        const clientOffset = monitor.getClientOffset() ?? { x: 0, y: 0 };

        // offset of drop event from container
        const relativeOffset: XYCoord = {
          x: clientOffset.x - containerLeft,
          y: clientOffset.y - containerTop,
        };

        onDrop({
          item,
          clientOffset,
          relativeOffset,
          containerDimensions: {
            height: container?.offsetHeight ?? 0,
            width: container?.offsetWidth ?? 0,
          },
        });
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [onDrop],
  );

  return (
    <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
      <div
        ref={dropRef}
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: isOver ? "rgba(0,0,0,0.1)" : "white",
        }}
      >
        {children}
      </div>
    </div>
  );
};
