import { Group, Stack, Title } from "@mantine/core";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { LoaderComponent, TableContainer } from "src/components";
import { useQueryRecommendations } from "src/graphql/Recommendations";
import { useAuthContext } from "src/hooks";
import { BASE_RECOMMENDATION_ID } from ".";
import { RecommendationsTable } from "./RecommendationsTable";
import { RecommendationsTableHeader } from "./RecommendationsTableHeader";
import { AddTemplateButton } from "src/components/ui/AddTemplateButton";
import { Permission } from "src/graphql";

export const RecommendationsListView = () => {
  const { selectedOrganizationId } = useAuthContext();
  const history = useHistory();
  const { data: recommendationsData, loading: recommendationsDataLoading } =
    useQueryRecommendations(selectedOrganizationId);

  const [filterText, setFilterText] = useState("");

  const filteredRecommendation = Object.values(
    recommendationsData?.recommendationsByOrganizationId.data ?? {},
  ).filter(
    (recommendation) =>
      recommendation._id !== BASE_RECOMMENDATION_ID &&
      recommendation.name.toLowerCase().includes(filterText.toLowerCase()),
  );

  const handleSelectRecommendation = (recommendationId: string) =>
    history.push(`recommendations/${recommendationId}`);

  if (recommendationsDataLoading) return <LoaderComponent />;

  return (
    <Stack>
      <Group position="apart">
        <Title>Recommendations</Title>
        <AddTemplateButton
          key="add-recommendation-button"
          disabled={recommendationsDataLoading}
          text="Add Recommendation"
          onClick={() => handleSelectRecommendation(BASE_RECOMMENDATION_ID)}
          permissions={[Permission.RecommendationWriteAccess]}
        />
      </Group>

      <TableContainer>
        <RecommendationsTableHeader setFilterText={setFilterText} />

        <RecommendationsTable
          recommendations={filteredRecommendation}
          onSelectRecommendation={handleSelectRecommendation}
        />
      </TableContainer>
    </Stack>
  );
};
