import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "../modal";
import toast from "src/libs/toast";

export const OfflineModal = () => {
  const [showModal, setShowModal] = useState(!window.navigator.onLine);
  useEffect(() => {
    window.addEventListener("online", () => {
      setShowModal(false);
      toast.success(
        "You are now online. If issues persist, please reload this page.",
        {
          duration: 5_000,
        },
      );
    });
    window.addEventListener("offline", () => setShowModal(true));
  }, []);
  return (
    <Modal
      opened={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      zIndex={10000}
      closeOnClickOutside={true}
      closeOnEscape={true}
    >
      <ModalHeader>Whoops!</ModalHeader>
      <ModalBody pb="md">
        You are currently offline. Please check your internet connection.
      </ModalBody>
    </Modal>
  );
};
