import { Text, TextProps } from "@mantine/core";

type StyledLabelProps = {
  required?: boolean;
  strong?: boolean;
  /**
   * Useful if the label is the top element of it's parent; avoids unwanted padding above
   * the label due to line height stacking with box model padding.
   */
  pullTop?: boolean;
} & TextProps;

export const StyledLabel = ({
  required,
  children,
  strong,
  pullTop,
  ...props
}: StyledLabelProps) => {
  const defaultLineHeight = "1.55rem";
  const pullTopLineHeight = ".75rem";

  const defaultMb = "5px";
  const pullTopMb = `calc(${defaultMb} + ((${defaultLineHeight} - ${pullTopLineHeight}) / 2))`;

  return (
    <Text
      component="label"
      weight={strong ? 600 : 400}
      size={12}
      mb={pullTop ? pullTopMb : defaultMb}
      style={{ lineHeight: pullTop ? pullTopLineHeight : defaultLineHeight }}
      {...props}
    >
      {children}{" "}
      {required && <span style={{ color: "red", marginLeft: -1.5 }}>*</span>}
    </Text>
  );
};
