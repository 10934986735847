import { LoadingOverlay } from "@mantine/core";
import { useMemo } from "react";
import { ReactFlowProvider } from "react-flow-renderer";
import { useHistory, useParams } from "react-router-dom";
import {
  createBaseFlowTemplate,
  FlowTemplateBuilderContainer,
  useFlowBuilderData,
} from "src/components";
import { AccessType, FlowType, Permission } from "src/graphql";
import { useAuthContext } from "src/hooks";
import styled from "styled-components";
import NotFound from "../not-found";
import { BASE_CREATE_TEMPLATE_ID } from "../templates";

export const StyledContainer = styled.div`
  margin: -20px;
  height: calc(100% + 40px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

type FlowTemplateRouteParams = {
  templateId: string;
};

export default function FlowTemplateBuilderView() {
  const { hasAnyPermission, selectedOrganizationId } = useAuthContext();

  const history = useHistory();

  const unparsedFlowType = new URLSearchParams(history.location.search).get(
    "type",
  );

  const flowType = isValidFlowType(unparsedFlowType)
    ? unparsedFlowType
    : FlowType.Journey;

  const { isLoading: loading, parsedData: builderData } = useFlowBuilderData();

  const { templateId } = useParams<FlowTemplateRouteParams>();

  const baseFlowTemplate = useMemo(
    () => createBaseFlowTemplate(selectedOrganizationId, flowType),
    [selectedOrganizationId, flowType],
  );

  const selectedTemplate =
    templateId === BASE_CREATE_TEMPLATE_ID
      ? { flowTemplate: baseFlowTemplate, accessType: AccessType.Write }
      : builderData?.flowTemplatesById[templateId];

  if (loading) return <LoadingOverlay visible />;

  if (!builderData || !selectedTemplate) return <NotFound />;

  const isCurrentVersion =
    selectedTemplate.flowTemplate._id === BASE_CREATE_TEMPLATE_ID ||
    selectedTemplate.flowTemplate._id ===
      builderData.flowTemplateFamiliesById[
        selectedTemplate.flowTemplate.familyId ?? ""
      ].flowTemplateFamily.currentVersion._id;

  return (
    <ReactFlowProvider>
      <StyledContainer>
        <FlowTemplateBuilderContainer
          selectedTemplate={selectedTemplate.flowTemplate}
          builderData={builderData}
          readOnly={
            !hasAnyPermission(Permission.BuilderToolsWriteAccess) ||
            !isCurrentVersion ||
            selectedTemplate.accessType === AccessType.Read
          }
        />
      </StyledContainer>
    </ReactFlowProvider>
  );
}

export const isValidFlowType = (type: string | null): type is FlowType =>
  Object.values(FlowType).includes(type as FlowType);
