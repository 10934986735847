import { gql } from "@apollo/client";
import { GoalFields } from "./types";

export const GET_GOALS = gql`
  query GetGoals($memberId: ID!, $episodeOfCareId: String, $organizationId: ID!) {
    goals(memberId: $memberId, episodeOfCareId: $episodeOfCareId, organizationId:$organizationId ) {
      success
      message
      data {
        ${GoalFields}
      }
    }
  }
`;

export const GET_USER_GOALS = gql`
  query GetUserGoals($input: UserGoalInput!) {
    userGoals(input: $input) {
      success
      message
      data {
        ${GoalFields}
      }
    }
  }
`;
