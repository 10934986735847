import { MemberInfo } from "src/types";
import { Member, PhoneCall } from "../graphql";
import { getFullName } from "./members";

export const getPhoneCallMembersNames = (call: PhoneCall): string => {
  if (!call.members) return "";
  return call.members.map((member) => getFullName(member)).join(", ");
};

export const buildMemberInfo = (
  member: Member,
  organizationId: string,
): MemberInfo => {
  return {
    _id: member._id,
    organizationId: organizationId,
    firstName: member.firstName,
    lastName: member.lastName,
    nickName: member.nickName,
    phone: member.contactInfo.phone,
  };
};
