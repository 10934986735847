import { ActionIcon, Button } from "@mantine/core";
import { useFormikContext } from "formik";
import { useMemo } from "react";
import { Minus } from "react-feather";

import {
  FormikInput,
  FormikMultiSelect,
  FormikSelect,
  StyledErrorMessage,
  StyledLabel,
} from "src/components";
import { FormikDateTimePickerInput } from "src/components/input/FormikDateTimePickerInput";
import { DataIDOption } from "src/components/ui/DataIDOption";
import {
  AnswerType,
  ConditionItem,
  ConditionOperator,
  Operator,
  Permission,
} from "src/graphql";
import { SelectOption } from "src/types";
import styled from "styled-components";
import { AnswerTypeOperators, RecommendationExtended } from "../utils";
import { useDataPointIdsWithAnswerTypes } from "src/hooks/useDataPointIdsWithAnswerTypes";
import { useAuthContext } from "src/hooks";
import { wrapSelectOptionsArray } from "src/utils";
import { setNewNestedValues } from "src/utils/formik";

export const StyledConditions = styled("div")`
  display: flex;
  position: absolute;
  bottom: -15px;
`;

export const StyledCondition = styled("div")<{
  active?: boolean;
  disabled?: boolean;
}>`
  margin-right: 5px;
  margin-left: 5px;
  box-sizing: border-box;
  padding: 5px;
  min-width: 50px;
  text-align: center;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  background-color: ${(p) => (p.active ? "var(--GREEN)" : "lightgrey")};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

const StyledItemsWrapper = styled("div")`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px dashed lightgrey;
  padding-bottom: 30px;
  margin-bottom: 30px;
  position: relative;
`;

const ItemWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
  margin-left: 20px;
`;

type ConditionProps = {
  conditionItem: ConditionItem;
  itemPath: string;
  hasNextItem: boolean;
  removeItem: () => void;
  readOnly?: boolean;
  hideConditionOperator?: boolean;
};

const answerTypeToInputTypeMap: Record<
  typeof AnswerType.Number | typeof AnswerType.Text,
  "text" | "number"
> = {
  [AnswerType.Text]: "text",
  [AnswerType.Number]: "number",
};

export const Condition = ({
  conditionItem: item,
  itemPath,
  hasNextItem,
  removeItem,
  readOnly,
  hideConditionOperator,
}: ConditionProps) => {
  const { setFieldValue, validateField, setValues } =
    useFormikContext<RecommendationExtended>();

  const { selectedOrganizationId, hasAnyPermission, selectedOrganization } =
    useAuthContext();
  const {
    questionTitlesByDataId,
    answerTypesByDataId,
    answerOptionsByDataId,
    dataPointTemplateIdsByDataId,
  } = useDataPointIdsWithAnswerTypes(selectedOrganizationId, true);

  const answerType = answerTypesByDataId[item.dataId];

  const dataIdSelectOptions: SelectOption<string>[] = useMemo(() => {
    return Object.keys(answerTypesByDataId).map((dataId) => ({
      value: dataId,
      label: `${dataId} - ${questionTitlesByDataId[dataId]}`,
      answerType: answerTypesByDataId[dataId],
      dataPointTemplateId: dataPointTemplateIdsByDataId[dataId],
    }));
  }, [
    questionTitlesByDataId,
    answerTypesByDataId,
    dataPointTemplateIdsByDataId,
  ]);

  const dataIdAnswerOptions = useMemo(() => {
    const map: Record<string, SelectOption<string>[]> = {};

    Object.keys(answerOptionsByDataId).forEach((dataId) => {
      const answerOptions = answerOptionsByDataId[dataId];

      if (!map[dataId]) {
        map[dataId] = [];
      }
      answerOptions.forEach((answerOption) => {
        map[dataId].push({ label: answerOption, value: answerOption });
      });
    });

    if (selectedOrganization.groups) {
      map["D79"] = selectedOrganization.groups.map((group) => ({
        label: group.title,
        value: group.title,
      }));
    }

    if (selectedOrganization.billingInsuranceConfigurations) {
      map["D31"] = selectedOrganization.billingInsuranceConfigurations.map(
        (c) => ({
          label: c.insurance,
          value: c.insurance,
        }),
      );
    }

    return map;
  }, [answerOptionsByDataId, selectedOrganization]);

  const operatorOptions: SelectOption<string>[] = useMemo(() => {
    return wrapSelectOptionsArray(AnswerTypeOperators[answerType] || []);
  }, [answerType]);

  const resetFieldsValues = {
    [`${itemPath}.valueTextTemp`]: "",
    [`${itemPath}.value`]: [],
    [`${itemPath}.valueMultiTemp`]: null,
    [`${itemPath}.valueMultiChoiceTemp`]: null,
    [`${itemPath}.operator`]: Operator.Equal,
    [`${itemPath}.operatorTemp`]: {
      label: Operator.Equal,
      values: Operator.Equal,
    },
  };

  const handleConditionOperatorClicked = (
    conditionOperator: ConditionOperator,
  ) => {
    if (item.conditionOperator !== conditionOperator) {
      setFieldValue(`${itemPath}.conditionOperator`, conditionOperator);
    }
  };

  const onDeleteClicked = () => {
    removeItem();
  };

  const renderValueComponent = () => {
    if (item.dataId === "D31") {
      return (
        <FormikSelect
          disabled={readOnly}
          name={`${itemPath}.valueMultiTemp`}
          placeholder="Select insurance"
          options={dataIdAnswerOptions[item.dataId] || []}
          onChangeOverride={(nextValue) => {
            setNewNestedValues(setValues, {
              [`${itemPath}.valueMultiTemp`]: nextValue,
              [`${itemPath}.valueTextTemp`]: nextValue?.value,
              [`${itemPath}.value`]: nextValue ? [nextValue.value] : null,
            });
          }}
        />
      );
    }
    switch (answerType) {
      case AnswerType.Multi:
        return (
          <FormikSelect
            disabled={readOnly}
            name={`${itemPath}.valueMultiTemp`}
            placeholder="Select one value from the list"
            options={dataIdAnswerOptions[item.dataId] || []}
            onChangeOverride={(nextValue) =>
              setNewNestedValues(setValues, {
                [`${itemPath}.valueMultiTemp`]: nextValue,
                [`${itemPath}.value`]: nextValue ? [nextValue.value] : null,
              })
            }
          />
        );

      case AnswerType.MultiChoice:
        return (
          <FormikMultiSelect
            name={`${itemPath}.valueMultiChoiceTemp`}
            placeholder="Select one or more values from the list"
            options={dataIdAnswerOptions[item.dataId] || []}
            disabled={
              !hasAnyPermission(Permission.RecommendationWriteAccess) ||
              readOnly
            }
            onChangeOverride={(nextValue) =>
              setNewNestedValues(setValues, {
                [`${itemPath}.valueMultiChoiceTemp`]: nextValue,
                [`${itemPath}.value`]: (nextValue || []).map(
                  ({ value }: { value: unknown }) => value,
                ),
              })
            }
          />
        );

      case AnswerType.Boolean:
        return (
          <Button.Group>
            <Button
              variant={item.value[0] === "true" ? "filled" : "default"}
              disabled={
                !hasAnyPermission(Permission.RecommendationWriteAccess) ||
                readOnly
              }
              onClick={() => {
                if (!hasAnyPermission(Permission.RecommendationWriteAccess))
                  return;
                setFieldValue(`${itemPath}.value`, ["true"]);
                validateField(`${itemPath}.value`);
              }}
            >
              True
            </Button>

            <Button
              variant={item.value[0] === "false" ? "filled" : "default"}
              disabled={
                !hasAnyPermission(Permission.RecommendationWriteAccess) ||
                readOnly
              }
              onClick={() => {
                if (!hasAnyPermission(Permission.RecommendationWriteAccess))
                  return;
                setFieldValue(`${itemPath}.value`, ["false"]);
                validateField(`${itemPath}.value`);
              }}
            >
              False
            </Button>
          </Button.Group>
        );
      case AnswerType.Date:
        return (
          <FormikDateTimePickerInput
            name={`${itemPath}.valueTextTemp`}
            placeholder="Enter the date you want to compare user input to"
            disabled={
              !hasAnyPermission(Permission.RecommendationWriteAccess) ||
              readOnly
            }
            onChangeOverride={(d) =>
              setNewNestedValues(setValues, {
                [`${itemPath}.valueTextTemp`]: d?.toISOString(),
                [`${itemPath}.value`]: d ? [d.toISOString()] : [],
              })
            }
          />
        );
      case AnswerType.Number:
      case AnswerType.Text:
        return (
          <FormikInput
            type={answerTypeToInputTypeMap[answerType]}
            name={`${itemPath}.valueTextTemp`}
            placeholder="Enter value you want to compare user input to"
            disabled={
              !hasAnyPermission(Permission.RecommendationWriteAccess) ||
              readOnly
            }
            onChangeOverride={(e) =>
              setNewNestedValues(setValues, {
                [`${itemPath}.valueTextTemp`]: e.target.value,
                [`${itemPath}.value`]: e.target.value ? [e.target.value] : [],
              })
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <StyledItemsWrapper>
      {hasAnyPermission(Permission.RecommendationWriteAccess) && (
        <ActionIcon
          mt={30}
          onClick={onDeleteClicked}
          variant="outline"
          color="red"
          radius="xl"
          disabled={readOnly}
        >
          <Minus size={12} />
        </ActionIcon>
      )}
      <ItemWrapper>
        <FormikSelect
          required
          name={`${itemPath}.dataIdTemp`}
          label="Data ID"
          options={dataIdSelectOptions}
          itemComponent={DataIDOption}
          disabled={
            !hasAnyPermission(Permission.RecommendationWriteAccess) || readOnly
          }
          placeholder="Select Data ID"
          onChangeOverride={(nextValue) =>
            setNewNestedValues(setValues, {
              [`${itemPath}.dataIdTemp`]: nextValue,
              [`${itemPath}.dataId`]: nextValue?.value,
              [`${itemPath}.dataPointTemplateId`]:
                dataPointTemplateIdsByDataId[nextValue?.value ?? ""],
              ...resetFieldsValues,
            })
          }
        />
        <StyledErrorMessage name={`${itemPath}.dataId`} />
      </ItemWrapper>
      <ItemWrapper>
        <FormikSelect
          required
          name={`${itemPath}.operatorTemp`}
          label="Operator"
          disabled={
            !hasAnyPermission(Permission.RecommendationWriteAccess) || readOnly
          }
          onChangeOverride={(nextValue) =>
            setNewNestedValues(setValues, {
              [`${itemPath}.operatorTemp`]: nextValue,
              [`${itemPath}.operator`]: nextValue?.value,
            })
          }
          placeholder="Please select DataID to see operators"
          options={operatorOptions}
        />
        <StyledErrorMessage name={`${itemPath}.operator`} />
      </ItemWrapper>

      <ItemWrapper>
        <div style={{ marginBottom: "0.25em" }} />
        <StyledLabel required>Value</StyledLabel>
        {renderValueComponent()}
        <StyledLabel>
          {!answerType ? "Please select DataID to enter a value" : ""}
        </StyledLabel>
        <StyledErrorMessage name={`${itemPath}.value`} />
      </ItemWrapper>

      {hasNextItem && !hideConditionOperator && (
        <StyledConditions>
          <StyledCondition
            active={item.conditionOperator === ConditionOperator.And}
            disabled={readOnly}
            onClick={() => {
              if (!hasAnyPermission(Permission.RecommendationWriteAccess))
                return;
              handleConditionOperatorClicked(ConditionOperator.And);
            }}
          >
            AND
          </StyledCondition>
          <StyledCondition
            active={item.conditionOperator === ConditionOperator.Or}
            disabled={readOnly}
            onClick={() => {
              if (!hasAnyPermission(Permission.RecommendationWriteAccess))
                return;
              handleConditionOperatorClicked(ConditionOperator.Or);
            }}
          >
            OR
          </StyledCondition>
        </StyledConditions>
      )}
    </StyledItemsWrapper>
  );
};
