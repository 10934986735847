import * as Yup from "yup";
import { ySelectOptionSchema } from "src/utils";

export const UserFormSchema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().required(),
  isSuperAdmin: Yup.bool().required(),
  spokenLanguages: Yup.array(ySelectOptionSchema(Yup.string().required())),
  location: Yup.string(),
  userOrgRoles: Yup.array(
    Yup.object({
      organizationId: Yup.string().required(),
      roleId: Yup.string().required(),
    }).required(),
  ).when("isSuperAdmin", {
    is: false,
    then: (schema) => schema.min(1, "Organization access is required"),
  }),
});
