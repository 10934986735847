import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { MemberInfo } from "src/graphql";
import * as Yup from "yup";

export const useURLQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const MemberInfoSchema = Yup.object({
  memberId: Yup.string().required(),
  memberDisplayName: Yup.string().required(),
});

export const useURLMemberInfo = (): MemberInfo | undefined => {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);

    const memberInfo = {
      memberId: params.get("memberId"),
      memberDisplayName: params.get("memberDisplayName"),
    };

    return MemberInfoSchema.isValidSync(memberInfo) ? memberInfo : undefined;
  }, [search]);
};
