import { useMutation, useQuery } from "@apollo/client";
import {
  ConsentResponse,
  ConsentTemplateResponse,
  ConsentTemplatesResponse,
  ConsentsResponse,
  MutationCreateOrUpdateConsentArgs,
  MutationCreateOrUpdateConsentTemplateArgs,
  MutationDeleteConsentTemplateArgs,
  MutationRevokeConsentArgs,
  QueryConsentTemplatesByOrganizationIdArgs,
  QueryConsentsByMemberIdArgs,
} from "../schemaTypes";
import { GET_CONSENTS, GET_CONSENT_TEMPLATES } from "./queries";
import {
  CREATE_OR_UPDATE_CONSENT,
  CREATE_OR_UPDATE_CONSENT_TEMPLATE,
  DELETE_CONSENT_TEMPLATE,
  REVOKE_CONSENT,
} from "./mutations";

export const useQueryConsentTemplates = (
  organizationId: string,
  showDeleted?: boolean,
) =>
  useQuery<
    {
      consentTemplatesByOrganizationId: ConsentTemplatesResponse;
    },
    QueryConsentTemplatesByOrganizationIdArgs
  >(GET_CONSENT_TEMPLATES, { variables: { organizationId } });

export const useMutationCreateOrUpdateConsentTemplate = () =>
  useMutation<
    { createOrUpdateConsentTemplate: ConsentTemplateResponse },
    MutationCreateOrUpdateConsentTemplateArgs
  >(CREATE_OR_UPDATE_CONSENT_TEMPLATE, {
    refetchQueries: [GET_CONSENT_TEMPLATES],
  });

export const useMutationDeleteConsentTemplate = () =>
  useMutation<
    { deleteConsentTemplate: ConsentTemplateResponse },
    MutationDeleteConsentTemplateArgs
  >(DELETE_CONSENT_TEMPLATE, {
    refetchQueries: [GET_CONSENT_TEMPLATES],
  });

export const useQueryConsentsByMember = (
  organizationId: string,
  memberId: string,
  showDeleted?: boolean,
  episodeOfCareId?: string,
) =>
  useQuery<
    {
      consentsByMemberId: ConsentsResponse;
    },
    QueryConsentsByMemberIdArgs
  >(GET_CONSENTS, { variables: { organizationId, memberId, episodeOfCareId } });

export const useMutationCreateOrUpdateConsent = () =>
  useMutation<
    { createOrUpdateConsent: ConsentResponse },
    MutationCreateOrUpdateConsentArgs
  >(CREATE_OR_UPDATE_CONSENT, {
    refetchQueries: [GET_CONSENTS],
  });

export const useMutationRevokeConsent = () =>
  useMutation<{ revokeConsent: ConsentResponse }, MutationRevokeConsentArgs>(
    REVOKE_CONSENT,
    {
      refetchQueries: [GET_CONSENTS],
    },
  );
