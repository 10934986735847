import { Grid, Stack, Text, Box } from "@mantine/core";

export const NoteDisplay = ({
  content,
  noteTemplateId,
}: {
  content: string;
  noteTemplateId?: string;
}) => {
  const parseContent = (content: string) => {
    return content.split("\n").reduce(
      (acc, line) => {
        const [label, ...rest] = line.split(":");

        // If it contains a label and answer, process as key-value pair
        if (label && rest.length > 0) {
          acc.push({ label: label.trim(), answer: rest.join(":").trim() });
        } else if (acc.length > 0) {
          // If no key-value structure, append to the previous answer (multiline case)
          acc[acc.length - 1].answer += `\n${line.trim()}`;
        } else {
          // If it's just content without key-value structure, treat it as normal content
          acc.push({ label: "", answer: line.trim() });
        }

        return acc;
      },
      [] as { label: string; answer: string }[],
    );
  };

  const parsedContent = noteTemplateId ? parseContent(content) : [];

  // If no key-value pairs were parsed, fall back to displaying raw content
  if (
    !noteTemplateId ||
    (parsedContent.length === 1 && !parsedContent[0].label)
  ) {
    return (
      <Box
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[6]
              : theme.colors.gray[0],
          padding: theme.spacing.sm,
          borderRadius: theme.radius.sm,
          border: `1px solid ${theme.colors.gray[3]}`,
        })}
      >
        <Text sx={{ whiteSpace: "pre-wrap" }}>{content}</Text>
      </Box>
    );
  }

  // If parsed content is detected, display key-value pairs
  return (
    <Stack spacing="md">
      {parsedContent.map((item, index) => (
        <Grid key={index} align="center" mb="0.1rem">
          <Grid.Col span={4}>
            <Text weight={700}>{item.label}</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <Box
              sx={(theme) => ({
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[6]
                    : theme.colors.gray[0],
                padding: `${theme.spacing.md} ${theme.spacing.sm}`,
                borderRadius: theme.radius.md,
                border: `1px solid ${theme.colors.gray[3]}`,
              })}
            >
              <Text sx={{ whiteSpace: "pre-wrap" }}>{item.answer}</Text>
            </Box>
          </Grid.Col>
        </Grid>
      ))}
    </Stack>
  );
};
