import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  GetUserIntegrationsResponse,
  MutationRevokeUserIntegrationArgs,
  QueryGetUserIntegrationsArgs,
  QueryStartGoogleConnectFlowArgs,
  QueryStartMicrosoftConnectFlowArgs,
  RevokeUserIntegrationResponse,
  StartGoogleConnectFlowResponse,
  StartMicrosoftConnectFlowResponse,
} from "../schemaTypes";
import {
  GET_USER_INTEGRATIONS,
  START_GOOGLE_CONNECT_FLOW,
  START_MICROSOFT_CONNECT_FLOW,
} from "./queries";
import { REVOKE_USER_INTEGRATION } from "./mutations";

export const useQueryUserIntegrations = (
  userId: string,
  organizationId: string,
) =>
  useQuery<
    { getUserIntegrations: GetUserIntegrationsResponse },
    QueryGetUserIntegrationsArgs
  >(GET_USER_INTEGRATIONS, {
    variables: {
      input: {
        userId,
        organizationId,
      },
    },
  });

export const useGoogleConnectFlow = (userId: string, organizationId: string) =>
  useLazyQuery<
    { startGoogleConnectFlow: StartGoogleConnectFlowResponse },
    QueryStartGoogleConnectFlowArgs
  >(START_GOOGLE_CONNECT_FLOW, {
    variables: {
      input: {
        userId,
        organizationId,
      },
    },
  });

export const useMicrosoftConnectFlow = (
  userId: string,
  organizationId: string,
) =>
  useLazyQuery<
    {
      startMicrosoftConnectFlow: StartMicrosoftConnectFlowResponse;
    },
    QueryStartMicrosoftConnectFlowArgs
  >(START_MICROSOFT_CONNECT_FLOW, {
    variables: {
      input: {
        userId,
        organizationId,
      },
    },
  });

export const useRevokeUserIntegration = () =>
  useMutation<
    { revokeUserIntegration: RevokeUserIntegrationResponse },
    MutationRevokeUserIntegrationArgs
  >(REVOKE_USER_INTEGRATION);
