import { gql } from "@apollo/client";
import { PhoneCallFields } from "./types";

export const GET_PHONE_CALLS = gql`
  query getPhoneCalls($input: GetPhoneCallsInput!, $pagination: PaginationInput!) {
    response: phoneCalls(input: $input, pagination: $pagination) {
      success
      message
      data {
        page
        total
        data {
          ${PhoneCallFields}
        }
      }
    }
  }
  `;

export const GET_PHONE_CALL = gql`
  query getPhoneCall($input: GetPhoneCallInput!) {
    phoneCall(input: $input) {
      success
      message
      data {
        ${PhoneCallFields}
      }
    }
  }
`;

export const GET_SIGNED_PHONE_CALL_RECORDING_URL = gql`
  query signedPhoneCallRecordingUrl($phoneCallId: ID!, $organizationId: ID!) {
    signedPhoneCallRecordingUrl(
      phoneCallId: $phoneCallId
      organizationId: $organizationId
    ) {
      success
      message
      data
    }
  }
`;

export const GET_PHONE_CALLS_MERGED_WITH_VOICEMAIL_BY_MEMBER_ID = gql`
  query GetPhoneCallsMergedWithVoicemailByMemberId($memberId: ID!) {
    phoneCallsMergedWithVoicemailByMemberId(memberId: $memberId) {
      success
      message
      data {
        ${PhoneCallFields}
        audioUrl
        listenedTo
        transcription
      }
    }
  }

`;

export const TRANSFER_CALL = gql`
  mutation transferCall($input: TransferCallInput!) {
    transferCall(input: $input) {
      success
    }
  }
`;
