import { useMutation, useQuery } from "@apollo/client";
import { GET_GOAL_TEMPLATES } from "./queries";
import {
  CREATE_GOAL_TEMPLATE,
  DELETE_GOAL_TEMPLATE,
  UPDATE_GOAL_TEMPLATE,
} from "./mutations";
import {
  AccessType,
  GetGoalTemplateRes,
  GetGoalTemplatesWithAccessRes,
  MutationCreateGoalTemplateArgs,
  MutationDeleteGoalTemplateArgs,
  MutationUpdateGoalTemplateArgs,
  QueryGoalTemplatesArgs,
} from "../schemaTypes";
import log from "loglevel";
import { GET_ACTIVITY_TEMPLATES } from "../ActivityTemplate";

export const useQueryGoalTemplates = (organizationId: string, skip?: boolean) =>
  useQuery<
    { goalTemplates: GetGoalTemplatesWithAccessRes },
    QueryGoalTemplatesArgs
  >(GET_GOAL_TEMPLATES, {
    skip,
    variables: {
      organizationId,
    },
  });

export const useMutationCreateGoalTemplate = () =>
  useMutation<
    {
      createGoalTemplate: GetGoalTemplateRes;
    },
    MutationCreateGoalTemplateArgs
  >(CREATE_GOAL_TEMPLATE, {
    refetchQueries: [GET_ACTIVITY_TEMPLATES],
    update: (cache, result) => {
      try {
        const newTemplate = result.data?.createGoalTemplate.data;
        if (!newTemplate) return;

        cache.modify({
          fields: {
            goalTemplates: (cached: GetGoalTemplatesWithAccessRes) => ({
              ...cached,
              data: [
                ...(cached.data ?? []),
                { goalTemplate: newTemplate, accessType: AccessType.Write },
              ],
            }),
          },
        });
      } catch (e) {
        log.error(e);
      }
    },
  });

export const useMutationUpdateGoalTemplate = () =>
  useMutation<
    {
      updateGoalTemplate: GetGoalTemplateRes;
    },
    MutationUpdateGoalTemplateArgs
  >(UPDATE_GOAL_TEMPLATE, {
    refetchQueries: [GET_ACTIVITY_TEMPLATES],
  });

export const useMutationDeleteGoalTemplate = (organizationId: string) =>
  useMutation<
    { deleteGoalTemplate: GetGoalTemplateRes },
    MutationDeleteGoalTemplateArgs
  >(DELETE_GOAL_TEMPLATE, {
    update: (cache, result) => {
      cache.updateQuery<{ goalTemplates: GetGoalTemplatesWithAccessRes }>(
        {
          query: GET_GOAL_TEMPLATES,
          variables: { organizationId },
        },
        (data) => {
          if (!data) return;
          const cached = data.goalTemplates;
          return {
            goalTemplates: {
              ...cached,
              data: cached.data?.filter(
                (template) =>
                  template.goalTemplate._id !==
                  result.data?.deleteGoalTemplate?.data?._id,
              ),
            },
          };
        },
      );
    },
  });
